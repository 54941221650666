interface ILoadingScreen {
    //What happens when loading starts
    displayLoadingUI: () => void;
    //What happens when loading stops
    hideLoadingUI: () => void;
    //default loader support. Optional!
    loadingUIBackgroundColor: string;
    loadingUIText: string;
}
/**
 * @returns {Object}
 */
function defaultFadeConfig() {
    return {
        easing: 'linear',
        iterations: 1,
        direction: 'normal',
        fill: 'forwards',
        delay: 0,
        endDelay: 0
    }
}

/**
 * @param {HTMLElement} el
 * @param {number} durationInMs
 * @param {Object} config
 * @returns {Promise}
 */
async function fadeOut(el, durationInMs, config = defaultFadeConfig()) {
    return new Promise((resolve:any, reject) => {
        const animation = el.animate([
            { opacity: '1' },
            { opacity: '0', offset: 0.5 },
            { opacity: '0', offset: 1 }
        ], {duration: durationInMs, ...config});
        animation.onfinish = () => resolve();
    })
}

/**
 * @param {HTMLElement} el
 * @param {number} durationInMs
 * @param {Object} config
 * @returns {Promise}
 */
async function fadeIn(el, durationInMs, config = defaultFadeConfig()) {
    return new Promise((resolve:any) => {
        const animation = el.animate([
            { opacity: '0' },
            { opacity: '0.5', offset: 0.5 },
            { opacity: '1', offset: 1 }
        ], {duration: durationInMs, ...config});
        animation.onfinish = () => resolve();
    });
}

export class LoadingScreen implements ILoadingScreen {
    //optional, but needed due to interface definitions

    public loadingUIBackgroundColor: string

    constructor(public loadingUIText: string) {}

    public displayLoadingUI() {
        let loadingScreenDiv = document.createElement('div');
            loadingScreenDiv.innerHTML = "loading...";
            loadingScreenDiv.classList.add('viewer-loading-screen');
        let canvasWrapper = document.querySelector('#OSBViewer2');
        canvasWrapper.append(loadingScreenDiv);
    }

    public hideLoadingUI() {
        setTimeout(function() {
            let loadingScreenDiv:HTMLDivElement = document.querySelector('.viewer-loading-screen');
            fadeOut(loadingScreenDiv, 2500);
            setTimeout(function() {
                loadingScreenDiv.style.display = 'none';
            },2500)
            window.Application.viewer.trigger('scene.ready');
        },1500);
    }
}