import * as BABYLON from "babylonjs";

import {Scene, MeshBuilder, AssetsManager, AssetContainer, AxesViewer} from "babylonjs";
import {BaseManager} from "./BaseManager";
import {IManager} from "./IManager";

interface AssetContainerDictionary {
    [index: string]: AssetContainer;
}

export class SceneManager extends BaseManager implements IManager {

    scene:Scene|null = null;

    defaultSceneName:string = 'DefaultScene';

    containers:AssetContainerDictionary = {};

    async init() {

        let sceneName = this.defaultSceneName;
        let s = this;

        let scene = await this.loadSceneByName(sceneName).then(function() {
            //s.viewer.InputHandler.listen();
            s.viewer.engine.hideLoadingUI();

        });


        console.log('CameraManager init done!');



        return scene;
    }

    addCameraToScene(camera: BABYLON.Nullable<BABYLON.Camera>) {
        this.scene.activeCamera = camera;
        console.log('addCameraToScene');
        console.debug(camera);
        this.scene.activeCamera.attachControl(this.viewer.canvas, true);
        this.addWorldAxisViewer();

    }

    addWorldAxisViewer() {
        const axis = new AxesViewer(this.scene,1);
        let camera = this.scene.activeCamera;

        camera.onViewMatrixChangedObservable.add(() => {
            let p = camera.position.clone();
            p.addInPlace(camera.getDirection(new BABYLON.Vector3(0, 0, 15)));
            p.addInPlace(camera.getDirection(new BABYLON.Vector3(0, -6, 0)));
            p.addInPlace(camera.getDirection(new BABYLON.Vector3(-6.5, 0, 0)));
            axis.xAxis.position = p.clone();
            axis.yAxis.position = p.clone();
            axis.zAxis.position = p.clone();
        });
    }

    createNewScene() {
        return new Scene(this.viewer.engine);
    }

    async loadSceneByName(name: string) {
        let Scene = this.scene = this.createNewScene();

        /**
         * @see https://doc.babylonjs.com/features/featuresDeepDive/importers/assetManager
         */
        this.viewer.AssetsManager = new AssetsManager(Scene);

        /**
        let gravityVector = new BABYLON.Vector3(0,-9.81, 0);
        Scene.enablePhysics(gravityVector);
        */
        //Scene.enablePhysics(null, new BABYLON.CannonJSPlugin());
        /*
        Scene.debugLayer.show({
            embedMode: true,
        });
        */
        //const gravityVector = new BABYLON.Vector3(0,-9.81, 0);
        //const physicsPlugin = new BABYLON.CannonJSPlugin();
        //this.scene.enablePhysics(gravityVector, new BABYLON.OimoJSPlugin());



        await this.importByName(name, 'Scenes');
    }

    createContainer(name: string)
    {
        /**
         * @see https://doc.babylonjs.com/features/featuresDeepDive/importers/assetContainers
         */
        this.containers[name] = new AssetContainer(this.scene);
        return this.containers[name];
    }
}