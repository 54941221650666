import {Vector3} from "babylonjs";
import {ArcRotateCamera} from "babylonjs";

import {BaseManager} from "./BaseManager";
import {IManager} from "./IManager";

import {DefaultCamera} from "../Cameras/DefaultCamera";


export class CameraManager extends BaseManager implements IManager {

    camera;

    defaultCameraName:string = "DefaultCamera";

    async init() {
        if (this.defaultCameraName == "DefaultCamera")
            this.camera = new DefaultCamera(this.viewer.SceneManager.scene);
    }
}