import * as LVP from "../../SDK/ViewSDK";

import TplMyOrders from "bundle-text:./MyOrders.html";
import TplOrderCard from "bundle-text:./MyOrders.Order.card.html";
import TplOrderItemCard from "bundle-text:./MyOrders.Order.Item.card.html";
import * as moment from "moment";
import {CacheHandler} from "../../SDK/ViewSDK";


export class MyOrders extends LVP.View {

    session: LVP.SessionHandler;

    connector: LVP.LordfoxConnector;

    cache: CacheHandler;

    items:any = [];

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.engine.add('lvp.app.layout', LVP.AppLayoutTemplate);
        this.engine.add('Account.MyOrders.Index', TplMyOrders);
        this.engine.add('Account.MyOrders.Order.Card', TplOrderCard);
        this.engine.add('Account.MyOrders.Order.Item.Card', TplOrderItemCard);

        moment.locale('de');

        this.engine.addHelper('unixTimeFormatTime', (unixTimeStamp) => {
            return moment.unix(unixTimeStamp).format('LLLL');
        });

        this.engine.addHelper('currencyFormatDisplay', (number, currencyCode) => {
            if (currencyCode == 'EUR') {
                return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
            } else if (currencyCode == 'LVP') {}
        });

        this.engine.addHelper('orderItemUnitDisplay', (orderItem) => {
            let units = {
                1: 'Stk.',
                2: 'Pak.',
                3: 'LVP',
            }
            return units[orderItem.amount_unit];
        });

        this.cache = new CacheHandler();

        const that = this;
        this.getMyOrders({
            success(response) {
                that.items = response.orders;
                that.update();
            }
        });

    }

    getMyOrders(o) {
        const that = this;

        if (this.session.isUserLoggedIn()) {
            that.connector.request("/Order/GetMyOrders/", {
                "userToken": that.session.getUserToken()
            },'POST').then(response => {
                if (response.Ack == 'success') {
                    if (typeof o.success) {
                        o.success(response);
                    }
                } else {
                    if (typeof o.fail) {
                        o.fail(response);
                    }
                }
            });
        }
    }

    render() {
        let vars:any = {
            view: this,
            items: this.items
        };

        vars.app = {
            title: "Meine Bestellungen",
            subtext: ""
        };
        this.renderEl('Account.MyOrders.Index',vars);

    }

}
