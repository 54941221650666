
import {getCookie} from './Cookie'

export class RestConnector {

    endpoint:string = 'https://www.connect.lordfox.de/rest/v1';

    findBy(entityName, criteria, pageBreak, page) {
        const response = this.request('/'+entityName+'/findBy/'+pageBreak+'/'+page+'/', {
            "attr": criteria
        }, 'POST');
        return response;
    }

    create(entityName, entity) {
        return this.request('/'+entityName+'/new/', entity, 'POST');
    }

    update(entityName, entity, id) {
        return this.request('/'+entityName+'/'+id+'/', entity, 'PUT');
    }

    delete(entityName, id) {
        return this.request('/'+entityName+'/'+id+'/', {}, 'DELETE');
    }

    get(entityName, id) {
        return this.getId(entityName, id);
    }

    getId(entityName, id) {
        return this.request('/'+entityName+'/'+id+'/', {}, 'GET');
    }

    request( path, params = {}, method = 'GET' ) {

        let url = this.endpoint+''+path;
        console.debug(this);

        // this.headers = this.apiDefaultCredentials;

        const headers = new Headers();
        //headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');
        if (method === 'PUT' || method === 'POST')
            headers.append('content-type', 'application/json');

        const options = {
            method: method,
            //credentials: 'same-origin'
            headers: headers,
            body: null
        };

        if (getCookie('LVP-userToken')) {
            if (typeof options.headers === 'undefined') {
                //options.headers = {};
            }
            //options.withCredentials =  true;
            //options.credentials = 'include';
            //options.headers['Authorization'] = "Bearer "+__.getCookie('LVP-userToken');
        }

        if ( 'GET' === method ) {
            url += '?' + ( new URLSearchParams( params ) ).toString();
        } else {
            options.body = JSON.stringify( params );
        }

        return fetch( url, options ).then( response => response.json() );
    }

}