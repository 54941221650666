import {setCookie, getCookie, expireAllCookies, expireActiveCookies} from './Cookie'
import {GenKey} from './Generator'


export class SessionHandler {

    ID?:string = null;

    start() {
        this.ID = GenKey();
    }

    restart() {
        this.clear();
        console.log('clear');
        this.start();
    }
    

    clear() {
        document.cookie = 'LVP-userToken' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        expireActiveCookies('LVP-userToken');
        console.debug(document.cookie);
        if (!this.isUserLoggedIn()) {
            window.localStorage.removeItem('lvp-user');
        }
        this.ID = null;
    }

    isUserLoggedIn() {
        let token = this.getUserToken();
        return (token.length > 0);
    }

    getUserName() {
        if (this.isUserLoggedIn()) {
            let user = this.getUser() || false;
            if (user) {
                return user.userName;
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    getUserId() {
        if (this.isUserLoggedIn()) {
            let user = this.getUser();
            if (user) {
                return user.userId;    
            } else {
                return "";
            }
            
        } else {
            return "";
        }
    }

    setUserToken(userToken) {
        setCookie('LVP-userToken', userToken, 1);
    }

    getUserToken() {
        return getCookie('LVP-userToken');
    }

    getUser() {
        if (this.isUserLoggedIn()) {
            return JSON.parse(window.localStorage.getItem('lvp-user'));
        } else {
            return {};
        }
    }

    setUser(user) {
        if (this.isUserLoggedIn()) {
            window.localStorage.setItem('lvp-user', JSON.stringify(user))
        }
    }

}