import * as LVP from "../../SDK/ViewSDK";

import {serializeForm} from "../../../Framework/Forms";

import TplEntityForm from "bundle-text:./entity.form.html";
import TplEntityDisplay from "bundle-text:./entity.display.html";
import FormTemplate from "bundle-text:./entity.editable.html"
import {IEntity} from "../../SDK/ViewSDK";
import {getEntityClass} from "../../../Domain/Entities";

import EditorJS from '@editorjs/editorjs';

import List from '@editorjs/list';
//import Heading from '@editorjs/header';
import Paragraph from 'editorjs-paragraph-with-alignment';
import Quote from '@editorjs/quote';
import CodeTool from '@editorjs/code';
import AnyButton from 'editorjs-button';
import ImageTool from '@editorjs/image';
import Underline from '@editorjs/underline';
import Embed from '@editorjs/embed';
import NestedList from '@editorjs/nested-list';
import SimpleImage from '@editorjs/simple-image';
/**
 * @see https://github.com/editor-js/attaches
 */
import AttachesTool from '@editorjs/attaches';

/**
 * @see https://github.com/kommitters/editorjs-inline-image
 */
//import InlineImage from 'editorjs-inline-image';

import DragDrop from 'editorjs-drag-drop';
import Undo from 'editorjs-undo';

import Header from 'editorjs-header-with-alignment'

import ToggleBlock from 'editorjs-toggle-block';
import Table from '@editorjs/table';

import RawTool from '@editorjs/raw';


export {IEntity};

type EditorJSMap = { [k: string]: EditorJS};

export enum EditorMode {
    Display = 'display',
    Edit = 'edit',
    Create = 'create'
}

export class EntityEditor extends LVP.View {

    entity: LVP.IEntity;

    connector: LVP.LordfoxConnector;

    mode:EditorMode = EditorMode.Display;

    vars:any;

    defaultVars: any;

    editors: EditorJSMap = {};

    events: LVP.EventTable = {

        'click .apply'() {
            this.one('collect.success', ()=> {
                this.destroyEditors();
                this.persist();
                this.display();
            });
            this.collect();
        },

        'click .collect'() {
            this.collect();
        },

        'click .save'() {
            this.one('collect.success', ()=> {
                this.persist();
                this.display();
            });
            this.collect();
        },

        'click .edit'(e) {
            if (("id" in e.target.dataset)) {
                let id = e.target.dataset.id;
                if (id)
                    this.getById(id);
                this.edit({app: {title: ""}});
            }
        },

        'click .cancel'(e) {
            this.destroyEditors();
            this.display({app: {title: ""}});
        }
    }

    constructor(entity?: LVP.IEntity, fields:string[] = []) {
        super();
        this.entity = entity;
        this.connector = window.Application.connector;

        this.engine.add('lvp.app.layout', LVP.AppLayoutTemplate);
        this.engine.add('Entity.Form', TplEntityForm);
        this.engine.add('Entity.Display', TplEntityDisplay);
        this.engine.add('Entity.Editable.Form', FormTemplate);

        const that = this;

        this.engine.addHelper('editable', function(entity:IEntity, fields:[] = []) {
            return that.engine.render('Entity.Editable.Form', {entity:entity, fields:fields});
        });

        this.defaultVars = {
            entity: this.entity,
            view: this,
            app: {
                title: this.entity.getEntityName(),
                subtext: ""
            },
            fields: fields
        }

        this.vars = Object.assign({}, this.defaultVars);

    }

    getById(id:any, cb?) {
        const that = this;
        this.connector.rest.getId(this.entity.getEntityName(), id).then(response => {
            that.entity.merge(response);
            if (cb) cb.call(that, response);
        });
    }

    onPersistSuccess(view, data, type, id) {
        const that = this;
        this.trigger('persist.success', {
            editor: view,
            entity: that.entity,
            type: type,
            id: id
        });
    }

    render() {

        const that = this;
        if (this.mode === EditorMode.Edit || this.mode === EditorMode.Create) {
            this.renderEl('Entity.Form', this.vars);
        } else if (this.mode === EditorMode.Display) {
            this.renderEl('Entity.Display', this.vars);
        }

        for (const [key, def] of Object.entries(this.entity.editable)) {

            if (def.type == 'blocks') {

                let idEl = 'editorjs-'+key;

                if (this.el.querySelector("#"+idEl)) {

                    const editor = new EditorJS({
                        onReady: () => {
                            new Undo({ editor });
                            new DragDrop(editor);
                        },
                        holder: idEl,
                        tools: {
                            header: Header,
                            table: Table,
                            raw: RawTool,
                            attaches: {
                                class: AttachesTool,
                                config: {
                                    endpoint: 'http://localhost:8008/uploadFile'
                                }
                            },
                            /*
                            image3: {
                                class: InlineImage,
                                inlineToolbar: true,
                                config: {
                                    embed: {
                                        display: true,
                                    },
                                    unsplash: {
                                        appName: 'Lordfox',
                                        clientId: 'BXO21Y3zFNI0jBFMVHO7Z0nXAkuF1ZPW8n9JZVe5Sxw'
                                    }
                                }
                            },

                             */
                            image2: SimpleImage,
                            list: {
                                class: NestedList,
                                inlineToolbar: true,
                                config: {
                                    defaultStyle: 'unordered'
                                },
                            },
                            embed: {
                                class: Embed,
                                inlineToolbar: true,
                                config: {
                                    services: {
                                        youtube: true,
                                        coub: true,
                                        codepen: {
                                            regex: /https?:\/\/codepen.io\/([^\/\?\&]*)\/pen\/([^\/\?\&]*)/,
                                            embedUrl: 'https://codepen.io/<%= remote_id %>?height=300&theme-id=0&default-tab=css,result&embed-version=2',
                                            html: "<iframe height='300' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%;'></iframe>",
                                            height: 300,
                                            width: 600,
                                            id: (groups) => groups.join('/embed/')
                                        },
                                        twitch: true
                                    }
                                }
                            },
                            underline: {
                                class: Underline,
                                inlineToolbar: true
                            },
                            paragraph: {
                                class: Paragraph,
                                inlineToolbar: true,
                            },
                            quote: {
                                class: Quote,
                                inlineToolbar: true,
                                shortcut: 'CMD+SHIFT+O',
                                config: {
                                    quotePlaceholder: 'Enter a quote',
                                    captionPlaceholder: 'Quote\'s author',
                                },
                            },

                            code: CodeTool,
/*
                            AnyButton: {
                                class: AnyButton,
                                inlineToolbar: false,
                                config:{
                                    css:{
                                        "btnColor": "btn--gray",
                                    }
                                }
                            },

 */
                            image4: {
                                class: ImageTool,
                                config: {
                                    endpoints: {
                                        byFile: 'http://localhost:8008/uploadFile', // Your backend file uploader endpoint
                                        byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
                                    }
                                }
                            }

                        },
                        i18n: {
                            messages: {
                                tools: {
                                    "AnyButton": {
                                        'Button Text': 'Button Text',
                                        'Link Url': 'URL',
                                        'Set': "Setzen",
                                        'Default Button': "Default Button",
                                    }
                                }
                            },
                        },

                        data: that.entity.get(key)
                    });

                    this.editors[key] = editor;
                }
            }

        }

    }

    destroyEditors() {
        if (Object.keys(this.editors).length) {
            for (const [key, editor] of Object.entries(this.editors)) {

                editor.destroy();
                delete this.editors[key];
            }
        }
    }

    create(varsOverride:any) {
        this.entity.set(this.entity.getPrimaryIdField(), null);
        this.mode = EditorMode.Create;
        this.vars = Object.assign({}, this.defaultVars, varsOverride);
        this.update();
    }

    display(varsOverride:any) {
        this.mode = EditorMode.Display;
        this.vars = Object.assign({}, this.defaultVars, varsOverride);
        this.update();
    }

    edit(varsOverride:any) {
        this.mode = EditorMode.Edit;
        this.vars = Object.assign({}, this.defaultVars, varsOverride);
        this.update();
    }

    persist() {
        const that = this;
        if (that.entity.get(that.entity.getPrimaryIdField()) !== "") {
            that.connector.rest.update(that.entity.getEntityName(), that.entity.toJSON(), that.entity.get(that.entity.getPrimaryIdField()))
                .then(response => {
                    if (response.ack === "success") {
                        that.entity.merge(response.data);
                        that.onPersistSuccess(that, response.data, 'update', response.data[that.entity.getPrimaryIdField()]);
                    }
                });
        } else {
            that.connector.rest.create(that.entity.getEntityName(), that.entity.toJSON())
                .then(response => {
                    that.entity.merge(response.data);
                    that.onPersistSuccess(that, response.data, 'create', response.data[that.entity.getPrimaryIdField()]);
                });
        }
    }

    collect() {
        const that = this;
        this.entity.merge(serializeForm(new FormData(this.el.querySelector('form#entity-form'))));

        if (Object.keys(this.editors).length) {
            let ix=0;
            for (const [key, editor] of Object.entries(this.editors)) {
                ix++;
                if (editor) {
                    editor.save().then(function(data) {
                        console.log(key);
                        console.debug(data);
                        that.entity.set(key, data);
                    });
                    //editor.destroy();
                }

                //delete this.editors[key];

                if (Object.keys(this.editors).length == ix) {
                    console.log('with editors collected');
                    this.trigger('collect.success', {
                        editor: this,
                        entity: this.entity,
                        type: 'collect',
                    });
                }
            }
        } else {
            this.trigger('collect.success', {
                editor: this,
                entity: this.entity,
                type: 'collect',
            });
        }

    }

}

export class DynamicEntityEditor extends EntityEditor {

    static getEntityClass = getEntityClass;

    constructor(entityName: string) {

        super(new (getEntityClass(entityName))(), []);

        this.on('collect.success', (ident, context) => {
            console.log('entity collected');
            console.debug(context);
        });

        this.on('persist.success', (ident, context) => {
            console.log('entity persist');
        });
    }

}

