import {Scene, Scalar} from "babylonjs";
import {ActionManager} from "./Manager/ActionManager";


export class PlayerInput {

    actionManager: ActionManager;

    scene: Scene;

    inputMap: any;

    vertical: number;
    verticalAxis: number;
    horizontal: number;
    horizontalAxis: number;

    // space
    jumpKeyDown: boolean = false;

    // shift
    dashing: boolean;

    // wasd
    moving: boolean = false;

    // ctrl
    holdControl: boolean = false;

    // alt
    holdAlt: boolean = false;

    /**
     * @see https://playground.babylonjs.com/#Z1SZWA#7 nice demo for player input and door opening
     * @param scene
     * @param actionManager
     */

    constructor(scene: Scene, actionManager: ActionManager) {
        this.scene = scene;
        this.actionManager = actionManager;
        this.inputMap = this.actionManager.InputMap;

        //add to the scene an observable that calls updateFromKeyboard before rendering
        scene.onBeforeRenderObservable.add(() => {
            this.updateFromKeyboard();
        });

    }

    updateFromKeyboard() {

        let inputMap = this.actionManager.InputMap;

        //Jump Checks (SPACE)
        this.jumpKeyDown = !!inputMap[" "];

        //dash - faster
        this.dashing = !!this.inputMap["shift"];

        this.holdControl = !!this.inputMap["control"];

        this.holdAlt = !!this.inputMap["alt"];

        this.moving = !!(this.inputMap["arrowright"] || this.inputMap["d"] || this.inputMap["arrowleft"] || this.inputMap["a"] || this.inputMap["arrowdown"] || this.inputMap["s"] || this.inputMap["arrowup"] || this.inputMap["w"]);

        if (this.inputMap["arrowup"] || this.inputMap["w"]) {
            this.vertical = Scalar.Lerp(this.vertical, 1, 0.12);
            this.verticalAxis = -1;
        } else if (this.inputMap["arrowdown"] || this.inputMap["s"]) {
            this.vertical = Scalar.Lerp(this.vertical, -1, 0.12);
            this.verticalAxis = 1;
        } else {
            this.vertical = 0;
            this.verticalAxis = 0;
        }

        if (this.inputMap["arrowleft"] || this.inputMap["a"]) {
            if (this.dashing && false) {
                this.horizontal = Scalar.Lerp(this.horizontal, 1, 0.12);
                this.horizontalAxis = 1;
            } else {
                this.horizontal = Scalar.Lerp(this.horizontal, -1, 0.12);
                this.horizontalAxis = 1;
            }
        } else if (this.inputMap["arrowright"] || this.inputMap["d"]) {
            if (this.dashing && false) {
                this.horizontal = Scalar.Lerp(this.horizontal, -1, 0.12);
                this.horizontalAxis = 1;
            } else {
                this.horizontal = Scalar.Lerp(this.horizontal, 1, 0.12);
                this.horizontalAxis = -1;
            }
        } else {
            this.horizontal = 0;
            this.horizontalAxis = 0;
        }





    }

}