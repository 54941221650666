import { collectAsyncIterableAsArray, filterAsyncIterable } from "./libs/iterator-tools"
import { IdbStorage } from "./libs/idb-storage";
import { getId3 } from "./libs/mp3-util";
import { readAsArrayBuffer } from "./libs/file-util";
import {AudioPlayer, TrackData} from "./AudioPlayer";

export class LocalTrackDataProvider {

    handle:any;

    isReady:boolean = false;

    storage:IdbStorage;

    fileLinks: WeakMap<any, any>;

    files:[] = [];

    loaded:any;

    setLoaded:any;

    filesWithMeta:any[] = [];

    player:AudioPlayer;

    constructor(player: AudioPlayer) {
        this.player = player;
        this.fileLinks = new WeakMap();
        this.loaded = new Promise((res,rej) => this.setLoaded = res);
        const that = this;
        this.player.events['click .open-local-directory'] = async (e) => {
            await that.open();
        }
    }

    async initialize() {
        this.storage = new IdbStorage({ siloName: "file-handles" });
        this.handle = await this.storage.get("handle");
        this.attachEvents();
        this.setLoaded();
    }

    attachEvents() {
        if(this.handle) {
            this.player.el.addEventListener('click', this.requestPermission);
        }
    }

    async requestPermission(){
        //this.shadowRoot.removeChild(this.dom.instructions);
        try{
            await this.handle.requestPermission({ mode: "read" });
            this.player.el.removeEventListener('click', this.requestPermission);
            this.isReady = true;
            await this.getFilesFromHandle();
        } catch(e){};
    }

    async open(){
        // @ts-ignore
        this.handle = await window.showDirectoryPicker();
        await this.storage.set("handle", this.handle);
        this.isReady = true;
        await this.getFilesFromHandle();
    }


    async getFilesFromHandle(){
        await this.addFilesMeta(await collectAsyncIterableAsArray(filterAsyncIterable(this.handle.values(), f =>
            f.kind === "file" && (f.name.endsWith(".mp3") || f.name.endsWith(".m4a")
            ))));
    }

    async addFilesMeta(files, shouldPlay = false) {
        this.isReady = true;
        this.filesWithMeta = [];
        for(let file of files){
            // @ts-ignore
            let fwm = { file: file, id3: getId3(await file.getFile().then(f => readAsArrayBuffer(f))) };
            // @ts-ignore
            this.filesWithMeta.push(fwm);
        }
        await this.convertToTrackList();
        this.player.updateTracks();
    }



    async convertToTrackList() {

        for (let f of this.filesWithMeta) {
            const file:FileSystemFileHandle = f.file;
            const fileData = await file.getFile();
            const fileArrayBuffer = await readAsArrayBuffer(fileData);

            let id3 = f.id3;

            let trackData:TrackData = {
                title: "",
                artist: "",
                url: "",
                thumb: "",
                durationTime: "",
                local: f
            };

            this.player.setTrackDurationFromContext(fileArrayBuffer, trackData);


            if(id3["APIC"]){
                trackData.thumb = URL.createObjectURL(new Blob([id3["APIC"][0].data]));
                //URL.revokeObjectURL(url);
            } else {
                trackData.thumb = new URL('default-thumb-player.png', import.meta.url).href;
            }
            trackData.title = id3["TIT2"] ?? file.name;
            trackData.artist = id3["TPE1"] ?? "";
            trackData.url = URL.createObjectURL(fileData);
            this.player.addTrack(trackData);

        }

    }

}