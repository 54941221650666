import {EventTable, View} from '../../../Framework/View';

import TplRegForm from "bundle-text:./RegistrationForm.html";
import TplRegVerify from "bundle-text:./RegistrationForm.verify.html";
import TplRegFinish from "bundle-text:./RegistrationForm.finish.html";

import AppLayoutTemplate from "bundle-text:../../templates/lvp.app.layout.html";
import {SessionHandler} from '../../Connector/SessionHandler';
import {LordfoxConnector} from '../../Connector/LordfoxConnector';
import {serializeForm} from '../../../Framework/Forms';
import {DomHelper} from '../../../Framework/DomHelper';

export class Registration extends View {

    user: any;

    connector: LordfoxConnector;

    session: SessionHandler;

    events: EventTable = {
        'submit form#lvp-user-mail-verify-code'(e) {
            e.preventDefault();
        },

        'paste input.input-v-mail-code'(e) {
            let s=this;

            setTimeout(function () {
                let code = s.el.querySelector('.input-v-mail-code').value.trim();
                s.el.querySelector('.input-v-mail-code').value = code;

                let msg = s.el.querySelector('.v-code-check-msg');

                if (code.length == 8) {
                    s.checkMailVerifyCode(code, function(state, response) {
                        if (state) {
                            msg.innerHTML='<i class="fa fa-check"></i> Vielen Dank!';
                            msg.classList.add('alert-success');
                            msg.classList.remove('alert-danger');
                            s.user.mailConfirmed = 1;
                            s.update();
                        } else {
                            msg.innerHTML='<i class="fa fa-cross"></i> Oops, dieser Code ist leider nicht korrekt!';
                            msg.classList.add('alert-success');
                            msg.classList.remove('alert-danger');
                            s.user.mailConfirmed = 0;
                        }
                    });
                } else {
                    console.log(code.length);
                }
            }, 100);
        },

        'keyup .input-v-mail-code'(e) {
            let s = this;
            let code = s.el.querySelector('.input-v-mail-code').value.trim();
            let msg = s.el.querySelector('.v-code-check-msg');

            if (code.length == 8) {
                this.checkMailVerifyCode(code, function(state, response) {
                    if (state) {
                        msg.innerHTML='<i class="fa fa-check"></i> Vielen Dank!';
                        msg.classList.add('alert-success');
                        msg.classList.remove('alert-danger');
                        s.user.mailConfirmed = 1;
                        s.update();
                    } else {
                        msg.innerHTML='<i class="fa fa-cross"></i> Oops, dieser Code ist leider nicht korrekt!';
                        msg.classList.add('alert-success');
                        msg.classList.remove('alert-danger');
                        s.user.mailConfirmed = 0;
                    }
                });
            }
        },

        'click button.mail-code-resend'(e) {
            let s=this;
            let user = this.user;
            let msg = s.el.querySelector('.v-code-check-msg');

            // Ein Delay muss hier rein als spam protector
            this.sendMailVerifyCode(user, function(state, response) {
                if (state) {
                    msg.innerHTML='<i class="fa fa-check"></i> Schaue bitte erneut in Deinen Posteingang';
                    msg.classList.add('alert-success');
                    msg.classList.remove('alert-danger');
                    //s.$el.find('.input-v-mail-code').focus();
                } else {
                    msg.innerHTML='<i class="fa fa-cross"></i> Oops, etwas ist schief gelaufen...';
                    msg.classList.add('alert-success');
                    msg.classList.remove('alert-danger');
                }
            });
        },

        'submit form#lvp-register-user-form'(e) {
            e.preventDefault();

            let formData = new FormData(e.target);

            var s = this;
            let msg = s.el.querySelector('.v-reg-form-msg');
            this.register(serializeForm(formData), function(state, response) {
                if (state) {
                    //s.template = _.template(TplRegVerify);
                    s.update();
                    //s.$el.find('.input-v-mail-code').focus();
                } else {
                    msg.innerHTML='Hoopla.., nicht so schnell, beachte bitte die roten Hinweise an den Eingabefeldern und korregiere deine Eingabe entsprechend der Vorgaben.';
                    msg.classList.add('alert-danger');

                    s.el.querySelector('.v-mail').dispatchEvent(new Event('change'));
                    s.el.querySelector('.v-pass').dispatchEvent(new Event('change'));
                    s.el.querySelector('.v-name').dispatchEvent(new Event('change'));
                }
            });
        },

        "click button.pass-vis"(e) {
            e.preventDefault();
            const inputEl = this.el.querySelector('.v-pass');
            if (inputEl.getAttribute("type") == "password") {
                inputEl.setAttribute("type", "text");
            } else {
                inputEl.setAttribute("type", "password");
                //inputEl.attributes.type = "password";
            }
            let iconEl;
            if (e.target.nodeName == "I") {
                iconEl = e.target;
            } else {
                iconEl = e.target.querySelector('i');
            }

            iconEl.classList.toggle('fa-eye');
            iconEl.classList.toggle('fa-eye-slash');
        },

        'keyup input.v-name'(e) {
            const s=this;
            const el = e.target;
            let l = el.value.length;
            this.el.querySelector('.v-name-count').text = el.value.length;

            //DomHelper.find(this.el, '.v-name-count').text = el.value.length;

            //this.$el.find('.v-name-count').text(el.val().length);

            //var elMsg = s.$el.find('.v-name-check-msg');
            //let elMsg = DomHelper.find(this.el, '.v-name-check-msg');
            let elMsg = this.el.querySelector('.v-name-check-msg');

            elMsg.innerHTML="";

            var f=false;
            if (!this.isAlphaNumeric(el.value)) {
                elMsg.append('<i class="fa fa-triangle-exclamation"></i> Nur Buchstaben (A-Z) und Zahlen erlaubt, keine Leer- oder Sonderzeichen.');
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
                f=true;
            }

            if (l < 5) {
                elMsg.append('<i class="fa fa-triangle-exclamation"></i> Mindestens 5 Zeichen.');
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
                f=true;
            }

            if(!f) {
                Array.from(this.el.querySelectorAll('input.v-name')).forEach( (ele:Element) => ele.dispatchEvent(new Event('change')));
                //this.$el.find('input.v-name').trigger('change');
            }
        },

        'keyup input.v-mail'(e) {
            const el = e.target;
            let mail = el.value;
            //const elMsg = DomHelper.find(this.el, '.v-mail-check-msg');
            const elMsg = this.el.querySelector('.v-mail-check-msg');
            //this.$el.find('.v-mail-check-msg');
            if (!this.validateEmail(mail)) {
                elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> E-Mail Adresse ungültig.';
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else {
                //el.trigger('change');
                el.dispatchEvent(new Event('change'));
            }
        },

        'keyup input.v-pass'(e) {
            const el = e.target;
            let l = el.value.length;

            //DomHelper.find(this.el, '.v-pass-count').text = el.value.length;
            this.el.querySelector('.v-pass-count').text = el.value.length;
            //this.$el.find('.v-pass-count').text(el.val().length)

            const s=this;
            if (l < 8 || l > 20) {
                //let elMsg = s.$el.find('.v-pass-check-msg');
                //let elMsg = DomHelper.find(this.el, '.v-pass-check-msg');
                let elMsg = this.el.querySelector('.v-pass-check-msg');
                elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Mindestens 8, maximal 20 Zeichen.';
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else {
                //DomHelper.find(this.el,'input.v-pass').dispatchEvent(new Event('change'));
                Array.from(this.el.querySelectorAll('input.v-pass')).forEach( (ele:Element) => ele.dispatchEvent(new Event('change')));
            }
        },

        'change input.v-pass'(e) {
            let s = this;

            const el = e.target;
            let pass = el.value;
            let elMsg = s.el.querySelector('.v-pass-check-msg');


            if (pass==="") {
                elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Bitte geben Sie ein sicheres Passwort ein.';
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else if (pass.length < 8 || pass.length > 20) {

                elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Mindestens 8, maximal 20 Zeichen.';
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else {
                this.checkUserPass(pass, function(valid) {
                    if (!valid) {
                        elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Passwort ist zu schwach...';
                        elMsg.classList.add('alert-danger');
                        elMsg.classList.remove('alert-success');
                        elMsg.classList.remove('alert-warning');
                    } else {
                        elMsg.innerHTML='<i class="fa fa-check"></i> Sicheres Passwort';
                        elMsg.classList.add('alert-success');
                        elMsg.classList.remove('alert-warning');
                        elMsg.classList.remove('alert-danger');
                    }
                });
            }
        },

        'change input.v-mail'(e) {
            let s = this;
            let mail = e.target.value;
            //let elMsg = s.$el.find('.v-mail-check-msg');
            //let elMsg = DomHelper.find(s.el,'.v-mail-check-msg');
            let elMsg = s.el.querySelector('.v-mail-check-msg');
            if (mail=="") {
                elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Bitte geben Sie ihre gültige E-Mail-Adresse ein.';
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else if (!this.validateEmail(mail)) {
                elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> E-Mail Adresse ungültig';
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else {
                this.checkUserMail(mail, function(available) {
                    if (!available) {
                        elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> E-Mail Adresse wird bereits verwendet';
                        elMsg.classList.add('alert-danger');
                        elMsg.classList.remove('alert-success');
                        elMsg.classList.remove('alert-warning');
                    } else {
                        elMsg.innerHTML='<i class="fa fa-check"></i> Bitte verifiziere Deine E-Mail-Adresse nach Abschluss der Registrierung';
                        elMsg.classList.add('alert-success');
                        elMsg.classList.remove('alert-warning');
                        elMsg.classList.remove('alert-danger');
                    }
                });
            }
        },

        'change input.v-name'(e) {
            const s = this;
            let name = e.target.value;
            //let elMsg = s.$el.find('.v-name-check-msg');
            //let elMsg = DomHelper.find(s.el,'.v-name-check-msg');
            let elMsg = s.el.querySelector('.v-name-check-msg');
            if (name=="") {
                elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Bitte geben Sie ihren gewünschten Benutzernamen ein.';
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else if (name.length<5 || !this.isAlphaNumeric(name)) {
                elMsg.innerHTML="";
                if (!this.isAlphaNumeric(name)) {
                    elMsg.append('<i class="fa fa-triangle-exclamation"></i> Nur Buchstaben (A-Z) und Zahlen erlaubt, keine Leer- oder Sonderzeichen.');
                    elMsg.classList.add('alert-danger');
                    elMsg.classList.remove('alert-success');
                    elMsg.classList.remove('alert-warning');
                }
                if (name.length < 5) {
                    elMsg.append('<i class="fa fa-triangle-exclamation"></i> Mindestens 5 Zeichen.');
                    elMsg.classList.add('alert-danger');
                    elMsg.classList.remove('alert-success');
                    elMsg.classList.remove('alert-warning');
                }
            } else {
                this.checkUserName(name, function(available) {
                    if (!available) {
                        elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Benutzername bereits vergeben';
                        elMsg.classList.add('alert-danger');
                        elMsg.classList.remove('alert-success');
                        elMsg.classList.remove('alert-warning');
                    } else {
                        elMsg.innerHTML='<i class="fa fa-check"></i> Benutzername ist verfügbar';
                        elMsg.classList.add('alert-success');
                        elMsg.classList.remove('alert-warning');
                        elMsg.classList.remove('alert-danger');
                    }
                });
            }
        }
    }

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.engine.add('lvp.app.layout', AppLayoutTemplate);
        this.engine.add('Account.Registration.Form', TplRegForm);
        this.engine.add('Account.Registration.Verify', TplRegVerify);
        this.engine.add('Account.Registration.Success', TplRegFinish);
    }

    render() {
        let vars:any = {app: {}, data: {}, user: this.user};
        vars.app = {
            title: "Konto erstellen",
            subtext: ""
        }
        if (this.user) {
            if (this.user.mailConfirmed == 0) {
                this.renderEl('Account.Registration.Verify', vars);
            } else {
                this.renderEl('Account.Registration.Success', vars);
            }
        } else {
            this.renderEl('Account.Registration.Form', vars);
        }
    }

    sendMailVerifyCode(user, cb) {
        this.connector.request('/User/SendMailConfirmationCode/', {
            "userId": user.userId
        },'POST').then(response => {
            if (response.Ack == 'success') {
                cb(true, response);
            } else {
                cb(false, response);
            }
        })
    }

    checkMailVerifyCode(code:string , cb) {
        this.connector.request('/User/VerifyMailConfirmationCode/', {
            "userId": this.user.userId,
            "code": code
        },'POST').then(response => {
            if (response.Ack == 'success') {
                if (response.state == 1) {
                    cb(true);
                } else {
                    cb(false);
                }
            } else {
                cb(false);
            }
        });
    }

    checkUserMail(userMail:string , cb) {
        this.connector.request('/User/CheckUserMailAvailable/', {
            "userMail": userMail,
            "mandatorId": 1
        },'POST').then(response => {
            if (response.Ack == 'success') {
                if (response.status == 1) {
                    cb(true);
                } else {
                    cb(false);
                }
            } else {
                cb(false);
            }
        });
    }

    checkUserName(userName:string, cb) {
        this.connector.request('/User/CheckUserNameAvailable/', {
            "userName": userName,
            "mandatorId": 1
        },'POST').then(response => {
            if (response.Ack == 'success') {
                if (response.status == 1) {
                    cb(true);
                } else {
                    cb(false);
                }
            } else {
                cb(false);
            }
        });
    }

    checkUserPass(userPass:string, cb) {
        this.connector.request('/User/CheckPasswordFormat/', {
            "userPassword": userPass
        },'POST').then(response => {
            if (response.Ack == 'success') {
                if (response.status == 1) {
                    cb(true);
                } else {
                    cb(false);
                }
            } else {
                cb(false);
            }
        });
    }

    isAlphaNumeric(str: string) {
        let code, i, len;
        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (!(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)) { // lower alpha (a-z)
                return false;
            }
        }
        return true;
    }

    register(formData, cb) {
        const s=this;
        this.connector.request('/User/Register/', formData,'POST').then(response => {
            if (response.Ack == 'success') {
                s.user = response.User;
                s.sendMailVerifyCode(s.user, function(state, res) {
                    if (res.Ack=='success') {
                        cb(true, response);
                    } else {
                        cb(false, response)
                    }
                });
            } else {
                s.user=null;
                cb(false, response);
            }
        });
    }

    validateEmail(email:string) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    validateForm(formData, cb) {

    }

}