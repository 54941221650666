import {EventTable, View} from '../../../Framework/View';
import FormTemplate from "bundle-text:./LoginForm.html";
import SuccessTemplate from "bundle-text:./LoginSuccess.html";
import AppLayoutTemplate from "bundle-text:../../templates/lvp.app.layout.html";
import {SessionHandler} from '../../Connector/SessionHandler';
import {LordfoxConnector} from '../../Connector/LordfoxConnector';
import {getCookie} from '../../Connector/Cookie';
import {serializeForm} from '../../../Framework/Forms';

export class Login extends View {

    session:SessionHandler;

    connector:LordfoxConnector;

    events:EventTable = {

        "submit form#lvp-login-user-form"(e) {
            e.preventDefault();
            let s=this;
            let formData = new FormData(e.target);
            //let formData = $(e.delegateTarget).serializeObject();
            this.validateFormData(serializeForm(formData), function(isValid, data) {
                if (isValid) {
                    s.login(data, function() {

                    });
                } else {
                    //
                }
            });
        },

        "click button.pass-vis"(e) {
            e.preventDefault();
            const inputEl = this.el.querySelector('.v-pass');
            if (inputEl.getAttribute("type") == "password") {
                inputEl.setAttribute("type", "text");
            } else {
                inputEl.setAttribute("type", "password");
                //inputEl.attributes.type = "password";
            }
            let iconEl;
            if (e.target.nodeName == "I") {
                iconEl = e.target;
            } else {
                iconEl = e.target.querySelector('i');
            }

            iconEl.classList.toggle('fa-eye');
            iconEl.classList.toggle('fa-eye-slash');
        },

        "click .btn-lvp-logout"(e) {
            this.logout();
        },

        "click .btn-lvp-session-test"(e) {
            this.testSession();
        }
    }

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.engine.add('lvp.app.layout', AppLayoutTemplate);
        this.engine.add('Account.Login.Form', FormTemplate);
        this.engine.add('Account.Login.Success', SuccessTemplate);
        const that = this;
        window.Application.on('lvp.user.logout', ()=> {
             that.logout();
        });

    }

    /**
     * @todo: debug method - not for production
     */
    testSession() {
        this.connector.request("/Auth/Test/", {
            "userToken": getCookie('LVP-userToken')
        }, 'POST').then(response => {
            if (response.Ack == 'success') {
                console.log(response);
            }
        });
    }

    validateFormData(data, cb) {
        if (data.userName != "" && data.userPassword!="") {
            cb(true, data);
        } else {
            cb(false, data);
        }
    }

    success(response, data) {
        this.session.restart();
        this.session.setUserToken(response.User.userToken);
        this.session.setUser(response.User);
        window.Application.trigger('lvp.user.login.success', {
            response: response,
            data: data
        });
        this.update();
    }

    fail(response, data) {
        let msg = this.el.querySelector('.v-form-msg');
        msg.innerHTML = 'Benutzername und Passwort stimmen nicht überein.';
        msg.classList.add('alert-danger');
        msg.classList.remove('alert-success');
    }

    login(data, cb) {

        const that = this;
        this.connector.request("/Auth/Authorize/", {
            "userName": data.userName,
            "userPassword": data.userPassword,
            "mandatorId": data.mandatorId
        },'POST').then(response => {
            if (response.Ack == 'success') {
                that.success(response, data);
                cb(true, response, data);
            } else {
                that.fail(response, data);
                cb(false, response, data);
            }
        });
    }

    logout() {
        const that = this;
        this.connector.request("/Auth/Logout/", {
            "userToken": getCookie('LVP-userToken')
        },'POST').then(response => {
            if (response.Ack == 'success') {
                that.session.restart();
                that.update();
                window.Application.trigger('lvp.user.logout.success', {
                    response: response
                });

            } else {
                // handle failing logout
            }
        });
    }

    render() {
        let vars:any = {};
        if (this.session.isUserLoggedIn()) {
            vars.user = this.session.getUser();
            vars.app = {
                title: "Benutzer-Konto",
                subtext: ""
            };
            this.renderEl('Account.Login.Success', vars);
        } else {
            vars.app = {
                title: "Benutzer-Anmeldung",
                subtext: ""
            };
            this.renderEl('Account.Login.Form', vars);
            //this.el.querySelector('.v-name').focus();
        }
    }
}