import {EventTable, View} from '../../../Framework/View';
import Template from "bundle-text:./TopNavigation.html";
import {CurrencyUtil} from "../../Utils/CurrencyUtil";
import {DomHelper} from "../../../Framework/DomHelper";

export class TopNavigation extends View {

    tagName:string = "nav";

    constructor() {
        super();
        this.engine.add('nav', Template);

        this.el = document.createElement(this.tagName);
        this.el.classList.add('navbar');
        this.el.classList.add('navbar-expand-lg');
        this.el.classList.add('navbar-dark');
        this.el.classList.add('bg-dark');

        const that = this;


        /*
        window.Application.on('navigate',(type, context) => {
            //that.state.url = context.url;
            //that.state.context = context;
            that.update();
        });
        */
    }

    state: {
        url: '';
        context?:""
    }

    events: EventTable = {
        'click .nav-link'(e) {
            let nav = e.target.closest('.navbar-nav');
            let l = DomHelper.find(nav, '.nav-link');
            l.forEach((el)=> {
                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                }
            });

            if (!e.target.classList.contains('active')) {
                e.target.classList.add('active');
            }
        },

        'click a.toggle'(e) {
            let sel = e.target.dataset.toggle;
            document.querySelector(sel).classList.toggle('d-none');
            window.Application.viewer.resize();
            e.preventDefault();
        }
    }



    render() {
        this.el.innerHTML = this.engine.render('nav', {
            state: this.state,
            wallet: {},
            view: this
        });
    }

}
