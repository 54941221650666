import {Events, IEvents} from "./Events";
import * as lodash from "lodash"
import {IModel} from "./Model";
//import {IModelCollection} from "./ModelCollection";
import {TemplateEngine} from './TemplateEngine'
import {DomHelper} from './DomHelper';

export interface IView extends IEvents {

    tagName:string;

    el:HTMLElement;

    viewProperties:ViewProperties;

    _init():void;

    template(vars?:Object):string;

    render():void;

    update():void;

    bindEvents():void;

}

export type EventTable = {

}

export type ViewProperties = {
    auto?:boolean;
    template?:string;
    model?:IModel
   // collection?:IModelCollection;
    events?:EventTable;
    tagName?:string;
}

export class View extends Events implements IView {

    tagName:string = "div";

    /**
     * the wrapping base element
     */
    el:HTMLElement;

    $el:any = {
        find(selector) {
            return DomHelper.find(this.parent.el, selector);
        },
        html(html: any) {
            this.parent.el.innerHTML = html;
        }
    }

    viewProperties:ViewProperties;

    events?:EventTable = {};

    eventHandlers?:any = {};

    engine: TemplateEngine;

    _handlersDone:boolean = false;

    constructor(viewProperties?:ViewProperties) {
        super();
        this.viewProperties = viewProperties;
        this.el = document.createElement(this.tagName);
        this._init();
    }

    _init() {

        this.engine = new TemplateEngine();
        this.engine.view = this;

        if (typeof this.viewProperties === 'undefined') {
            this.viewProperties = {
                auto: false
            }
        }
        if (this.viewProperties.auto) {
            this.update();
        }
    }

    template(vars?:Object) {
        let engine;
        if (this.viewProperties.template) {
            engine = lodash.template(this.viewProperties.template);
        } else {
            engine = lodash.template();
        }
        if (!vars) {
            vars = {};
        }
        return engine(vars);
    }

    bindEvents(eventTable?:EventTable) {
        let s = this;
        this.trigger("bind.events", {
            view: s
        });
        if (eventTable) {
            let v=this;
            for (const [estring, callback] of Object.entries(eventTable)) {

                if (!v.eventHandlers[estring]) {
                    if (callback instanceof Function) {
                        v.eventHandlers[estring] = (event: Event) => {
                            callback.call(v, event);
                        }
                    }
                }

                let p = estring.split(" ");
                let eventType = p[0];
                let elements;
                switch (eventType.charAt(0)) {
                    case "*":
                        p.shift();
                        elements = document.querySelectorAll(p.join(" "));
                        eventType = eventType.substring(1);
                        break;
                    case "#":
                        elements=[v.el];
                        eventType = eventType.substring(1);
                        break;
                    case "~":
                        elements=[window];
                        eventType = eventType.substring(1);
                        break;
                    case "$":
                        elements=[window.document];
                        eventType = eventType.substring(1);
                        break;
                    default:
                        p.shift();
                        elements = v.el.querySelectorAll(p.join(" "));
                        //eventType = eventType.substring(1);
                }

                if (v.eventHandlers[estring] instanceof Function) {
                    for (let i = 0; i < elements.length; i++) {
                        elements[i].removeEventListener(eventType, v.eventHandlers[estring], {capture:true});
                        elements[i].addEventListener(eventType, v.eventHandlers[estring], {capture: true});
                    }
                }
                if (false) {
                if (callback instanceof Function) {
                    let ecb=[];
                    for (let i = 0; i < elements.length; i++) {
                        //ecb[i] = function (event:Event) {callback.call(v,event);}
                        //elements[i].removeEventListener(eventType, ecb[i], {capture:true});
                        //elements[i].addEventListener(eventType, ecb[i], {capture: true});
                    }
                }
                }
            }
        }
        this.trigger("bind.events.done", {
            view: s
        });
    }

    render() {
        if (this.viewProperties.model) {
            let model = this.viewProperties.model;
            this.el.innerHTML = this.template(model.toJSON());
        }
    }

    renderEl(tplName: string, vars: any) {
        this.el.innerHTML = this.engine.render(tplName, vars);
    }

    update() {
        let s = this;

        this.trigger("update.start", {
            view: s
        });

        this.render();

        this.trigger("update.rendered", {
            view: s
        });

        this.bindEvents(this.viewProperties.events);
        if (this.events)
            this.bindEvents(this.events);

        this.trigger("update.done", {
            view: s
        });
    }
}