import {EventTable, View} from '../../../Framework/View';

import AppLayoutTemplate from "bundle-text:../../templates/lvp.app.layout.html";
import TplLostPass from "bundle-text:./LostPassword.html";
import TplLostPassVerify from "bundle-text:./LostPassword.verify.html";
import TplLostPassPassword from "bundle-text:./LostPassword.password.html";
import TplLostPassFinish from "bundle-text:./LostPassword.finish.html";

import {SessionHandler} from '../../Connector/SessionHandler';
import {LordfoxConnector} from '../../Connector/LordfoxConnector';

import {serializeForm} from '../../../Framework/Forms';

interface LostPasswordInputData {

    userIdent: any,
    mandatorId: any,
    maskedMail: string,
    hasMatch: boolean,
    userToken?: string;

}

export class LostPassword extends View {

    session: SessionHandler;

    connector: LordfoxConnector;

    events: EventTable = {
        'submit form#lvp-lostpass-user-form'(e) {
            e.preventDefault();
            let s=this;
            let formData = new FormData(e.target);
            //let formData = $(e.delegateTarget).serializeObject();
            let msg = this.el.querySelector('.v-form-msg');

            //let msg = this.$el.find('.v-form-msg');
            this.validateFormData(serializeForm(formData), function(isValid, data) {
                if (isValid) {
                    s.sendMailCode(data, function (isSend, response) {

                        if (isSend) {
                            s.inputData.userIdent = data.userIdent;
                            s.inputData.mandatorId = data.mandatorId;
                            s.inputData.maskedMail = response.email;
                            s.inputData.hasMatch = isSend;
                            s.update();
                        } else {
                            s.inputData.userIdent = data.userIdent;
                            s.inputData.mandatorId = data.mandatorId;
                            //s.inputData.maskedMail = response.email;
                            s.inputData.hasMatch = isSend;

                            msg.innerHTML= "Leider konnten wir kein passendes Konto finden";
                            msg.classList.add('alert-danger');
                        }

                    });
                } else {

                }
            });
        },

        'paste input.input-v-mail-code'(e) {
            let s=this;

            setTimeout(function () {
                //let code = s.$el.find('.input-v-mail-code').val().trim();
                //s.$el.find('.input-v-mail-code').val(code);
                let code = s.el.querySelector('.input-v-mail-code').value.trim();
                s.el.querySelector('.input-v-mail-code').value = code;


                //let msg = s.$el.find('.v-code-check-msg');
                let msg = s.el.querySelector('.v-code-check-msg');


                if (code.length == 8) {
                    s.checkMailVerifyCode(code, function(state, response) {
                        if (state) {
                            s.update();
                        } else {




                            msg.innerHTML=`<i class="fa fa-cross"></i> Oops, dieser Code ist leider nicht korrekt!`;
                            msg.classList.add('alert-danger');
                            msg.classList.remove('alert-success');
                        }
                    });
                }
            }, 100);
        },

        'keyup .input-v-mail-code'(e) {
            let s = this;
            let code = s.el.querySelector('.input-v-mail-code').value.trim();
            let msg = s.el.querySelector('.v-code-check-msg');

            if (code.length == 8) {
                this.checkMailVerifyCode(code, function(state, response) {
                    if (state) {
                        s.update();
                    } else {
                        msg.innerHTML= `<i class="fa fa-cross"></i> Oops, dieser Code ist leider nicht korrekt!`;
                        msg.classList.add('alert-danger');
                        msg.classList.remove('alert-success');
                    }
                });
            }
        },

        'click .mail-code-resend'(e) {
            var s = this;
            this.sendMailCode(this.inputData, function (isSend, response, data) {
                if (isSend) {
                    s.inputData.userIdent = data.userIdent;
                    s.inputData.mandatorId = data.mandatorId;
                    s.inputData.maskedMail = response.email;
                    s.inputData.hasMatch = isSend;
                    s.update();
                } else {
                    s.inputData.userIdent = data.userIdent;
                    s.inputData.mandatorId = data.mandatorId;
                    //s.inputData.maskedMail = response.email;
                    s.inputData.hasMatch = isSend;
                    s.update();
                }

            });
        },

        'submit #lvp-lostpass-pass-user-form'(e) {
            e.preventDefault();
            let s=this;

            //let formData = $(e.delegateTarget).serializeObject();
            let formData = new FormData(e.target);
            let msg = this.el.querySelector('.v-form-msg');
            //let msg = this.$el.find('.v-form-msg');

            let data:any = serializeForm(formData);

            console.log('debug form data');
            console.debug(data);

            this.checkUserPass(data.userPassword, function(isValid) {
                if (isValid) {
                    s.sendNewPassword(data, function(isSuccess) {
                        if (isSuccess) {
                            s.isSuccess = isSuccess;
                            s.update();
                        } else {
                            msg.innerHTML = `Ooops, es ist ein interner Fehler aufgetreten`;
                            msg.classList.add('alert-danger')
                        }
                    });
                } else {
                    msg.innerHTML = `Ooops, es ist ein interner Fehler aufgetreten`;
                    msg.classList.add('alert-danger')
                }

            });
        },

        "click button.pass-vis"(e) {
            e.preventDefault();
            const inputEl = this.el.querySelector('.v-pass');
            if (inputEl.getAttribute("type") == "password") {
                inputEl.setAttribute("type", "text");
            } else {
                inputEl.setAttribute("type", "password");
                //inputEl.attributes.type = "password";
            }
            let iconEl;
            if (e.target.nodeName == "I") {
                iconEl = e.target;
            } else {
                iconEl = e.target.querySelector('i');
            }

            iconEl.classList.toggle('fa-eye');
            iconEl.classList.toggle('fa-eye-slash');
        },

        'keyup input.v-pass'(e) {
            var el = e.target;
            var l = el.value.length;
            this.el.querySelector('.v-pass-count').innerHTML= el.value.length;

            var s=this;
            if (l < 8 || l > 20) {
                var elMsg = s.el.querySelector('.v-pass-check-msg');
                elMsg.innerHTML=`<i class="fa fa-triangle-exclamation"></i> Mindestens 8, maximal 20 Zeichen.`;
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else {
                this.el.querySelector('input.v-pass').dispatchEvent(new Event('change'));
            }
        },

        'change input.v-pass'(e) {
            let s = this;

            var el = e.target;
            let pass = el.value;
            var elMsg = s.el.querySelector('.v-pass-check-msg');

            if (pass=="") {
                elMsg.innerHTML=`<i class="fa fa-triangle-exclamation"></i> Bitte geben Sie ein sicheres Passwort ein.`;
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else if (pass.length < 8 || pass.length > 20) {

                elMsg.innerHTML=`<i class="fa fa-triangle-exclamation"></i> Mindestens 8, maximal 20 Zeichen.`;
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else {
                this.checkUserPass(pass, function(valid) {
                    if (!valid) {
                        elMsg.innerHTML=`<i class="fa fa-triangle-exclamation"></i> Passwort ist zu schwach...`;
                        elMsg.classList.add('alert-danger');
                        elMsg.classList.remove('alert-success');
                        elMsg.classList.remove('alert-warning');
                    } else {
                        elMsg.innerHTML=`<i class="fa fa-check"></i> Sicheres Passwort`;
                        elMsg.classList.add('alert-success');
                        elMsg.classList.remove('alert-warning');
                        elMsg.classList.remove('alert-danger');
                    }
                });
            }
        }
    }

    inputData: LostPasswordInputData;

    isSuccess:boolean = false;

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.inputData = {
            userIdent: "",
            mandatorId: "",
            maskedMail: "",
            hasMatch: false,
            userToken: ""
        }
        this.engine.add('lvp.app.layout', AppLayoutTemplate);
        this.engine.add('Account.LostPassword.Index', TplLostPass);
        this.engine.add('Account.LostPassword.Verify', TplLostPassVerify);
        this.engine.add('Account.LostPassword.Form', TplLostPassPassword);
        this.engine.add('Account.LostPassword.Success', TplLostPassFinish);
    }

    render() {
        let vars:any = {app: {}, data: {}};

        if (this.isSuccess) {
            vars.data = this.inputData;
            vars.app = {
                title: "Passwort vergessen",
                subtext:""
            };
            this.el.innerHTML = this.engine.render('Account.LostPassword.Success', vars);
        } else if (this.inputData.userToken.length > 0) {
            vars.data = this.inputData;
            vars.app = {
                title: "Passwort vergessen",
                subtext:""
            };
            this.el.innerHTML = this.engine.render('Account.LostPassword.Form', vars);

        } else if (this.inputData.hasMatch) {
            vars.data = this.inputData;
            vars.app = {
                title: "Passwort vergessen",
                subtext:""
            };
            this.el.innerHTML = this.engine.render('Account.LostPassword.Verify', vars);
            //this.$el.find('.v-mail-code').focus();
        } else {
            vars.app = {
                title: "Passwort vergessen",
                subtext:""
            };
            this.el.innerHTML = this.engine.render('Account.LostPassword.Index', vars);
            //this.$el.find('.v-ident').focus();
        }
    }

    sendNewPassword(data, cb) {
        const that = this;
        if (that.inputData.userToken) {
            that.connector.request('/User/ChangePassword/', {
                "userPassword": data.userPassword,
                "userToken": that.inputData.userToken
            }, 'POST').then(response => {
                cb((response.Ack == 'success'));
            });
        }
    }

    checkUserPass(userPass, cb)
    {
        this.connector.request('/User/CheckPasswordFormat/', {
            "userPassword": userPass
        },'POST').then(response => {
            if (response.Ack == 'success') {
                if (response.status == 1) {
                    cb(true);
                } else {
                    cb(false);
                }
            } else {
                cb(false);
            }
        });
    }

    checkMailVerifyCode(token, cb)
    {
        var that=this;
        this.connector.request('/User/VerifyMailToken/', {
            "userIdent": that.inputData.userIdent,
            'mandatorId': that.inputData.mandatorId,
            "userMailToken": token
        },'POST').then(response => {
            if (response.Ack == 'success') {
                if (response.state == 1) {
                    that.inputData.userToken=response.userToken;
                    cb(true);
                } else {
                    cb(false);
                }
            } else {
                cb(false);
            }
        });
    }

    sendMailCode(data, cb)
    {
        this.connector.request('/User/ResetPassword/', {
            "userIdent": data.userIdent,
            "mandatorId": data.mandatorId
        },'POST').then(response => {
            if (response.Ack === 'success') {
                cb(true, response, data);
            } else {
                cb(false, response, data);
            }
        });
    }

    validateFormData(data, cb)
    {
        if (data.userIdent != "" && data.mandatorId !="") {
            data.userIdent.trim();
            data.mandatorId.trim();
            cb(true, data);
        } else {
            cb(false, data);
        }
    }

}
