import {Color3, SceneLoader, StandardMaterial, MeshBuilder, Quaternion, AxesViewer, Scalar} from "babylonjs";
import {Vector3} from "babylonjs";
import "babylonjs-loaders";
import {PlayerInput} from "../PlayerInput";


import {Character} from "../Character";
import {Viewer} from "../Viewer";


export class LordfoxCharacter extends Character {

    constructor(viewer: Viewer, type:string = 'DIRECT_PLAYER', rezStartPosition?: Vector3) {
        super(viewer, type, rezStartPosition);
        this.loadAssets().then(()=> {
            this.isLoaded=true;
        });
    }

    async loadAssets() {
        const res = await SceneLoader.ImportMeshAsync(null, "", new URL('Lordfox_Character2.glb', import.meta.url).href, this.scene);
        this._root = res.meshes[0];
        this._root.scaling.scaleInPlace(3);
        this.meshes = this._root.getChildMeshes();
        this.animationSet = res.animationGroups;
        this.setUpAnimationMap();
        this.particles = res.particleSystems;
        this.skeletons = res.skeletons;

    }

    setUpAnimationMap() {
        this.animationSet[0].stop();
        this.animationMap['dance1'] = this.animationSet[2];
        this.animationMap['dance2'] = this.animationSet[3];
        this.animationMap['special1'] = this.animationSet[4];
        this.animationMap['fly'] = this.animationSet[5];
        this.animationMap['idle'] = this.animationSet[6];
        this.animationMap['jump'] = this.animationSet[8];
        this.animationMap['stand'] = this.animationSet[10];
        this.animationMap['run'] = this.animationSet[11];
        this.animationMap['walkBack'] = this.animationSet[13];
        this.animationMap['walk'] = this.animationSet[14];
        this.animationMap['walkLeft'] = this.animationSet[15];
        this.animationMap['walkRight'] = this.animationSet[16];
    }
}