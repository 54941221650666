import {Vector3, Scene, MeshBuilder, Color3, StandardMaterial, Texture, Quaternion, TransformNode, Mesh} from "babylonjs"


export class CubePlatform {

    coordinate: Vector3;

    size: Vector3;

    scene: Scene;

    constructor(scene: Scene, coordinate: Vector3, size: Vector3) {

        this.coordinate = coordinate;
        this.scene = scene;
        this.size = size;

    }



}

export class CubeFactory {

    cubeGridSize: Vector3;

    platformSize: Vector3;

    platforms: Mesh[] = [];

    scene: Scene;

    _material: StandardMaterial;

    _center: Vector3;

    _spaceBetween:Vector3;

    _totalX: number;

    _totalY: number;

    _totalZ: number;

    _root: TransformNode;

    _lc: number;

    _rc: number;

    _uc: number;

    _dc: number;

    _fc: number;

    _bc: number;

    constructor(scene: Scene, cubeGridSize: Vector3, platformSize: Vector3, centerPosition: Vector3, spaceBetween: Vector3) {

        this.scene = scene;
        this.cubeGridSize = cubeGridSize;
        this.platformSize = platformSize;
        this._center = centerPosition;
        this._material = this._materialFactory();
        this._spaceBetween = spaceBetween;

        /**
         * total size
         */
        this._totalX = ((Number(cubeGridSize.x)-1) * spaceBetween.x) + (Number(cubeGridSize.x) * Number(platformSize.x));
        this._totalZ = ((Number(cubeGridSize.z)-1) * spaceBetween.z) + (Number(cubeGridSize.z) * Number(platformSize.z));
        this._totalY = ((Number(cubeGridSize.y)-1) * spaceBetween.y) + (Number(cubeGridSize.y) * Number(platformSize.y));

        this._root = new TransformNode('root');
        this._root.position = centerPosition;

        this.build();

    }

    calculateBase(cubeGridSize, platformSize, spaceSize, center) {

        let left;
        let right;
        let pos;

        if (cubeGridSize % 2) {  // wenn layer im center
            pos = center;
            if (cubeGridSize > 1) {
                left =  (cubeGridSize - 1) / 2;
                right = (cubeGridSize -1) / 2;
            } else {
                left=0;
                right=0;
            }
        } else {
            pos = center - ((spaceSize / 2) + (spaceSize / 2));
            if (cubeGridSize > 2) {
                left = cubeGridSize / 2;
                right = left - 1;
            } else {
                left = 1;
                right = 0;
            }
        }

        return [
            pos, left, right
        ]

    }

    calculatePosition(type: number, base: number, offset: number) {

        // base
        if (type == 0) {
            return base;
        // add
        } else if (type == 1) {
            return base + offset;
        // sub
        } else if (type == 2) {
            return base - offset;
        }
    }

    /**
     * High-Layer
     */
    build()
    {
        // position of first platform
        let pos = Vector3.Zero();

        // position offset from center to center in all directions
        let offset = new Vector3(this._spaceBetween.x + this.platformSize.x, this._spaceBetween.y + this.platformSize.y, this._spaceBetween.z + this.platformSize.z);

        let y = this.calculateBase(this.cubeGridSize.y, this.platformSize.y, this._spaceBetween.y, this._center.y);
        pos.y = y[0];
        this._uc = y[1];
        this._dc = y[2];

        let x = this.calculateBase(this.cubeGridSize.x, this.platformSize.x, this._spaceBetween.x, this._center.x);
        pos.x = x[0];
        this._rc = x[1];
        this._lc = x[2];

        let z = this.calculateBase(this.cubeGridSize.z, this.platformSize.z, this._spaceBetween.z, this._center.z);
        pos.z = z[0];
        this._bc = z[1];
        this._fc = z[2];

        let totalPlatforms = this.cubeGridSize.y * this.cubeGridSize.x * this.cubeGridSize.z;
        console.log('Total-Platforms:', totalPlatforms);
        let num = this._buildY(pos, offset, 1);
        console.log('platforms', this.platforms);
    }

    _buildY(yPos: Vector3, posOffset, num)
    {
        // Center Y-Line
        num = this._buildZ(yPos.clone(), posOffset, num);
        let uc=1;
        let nuPos = yPos.clone();
        while(this._uc >= uc) {
            num++;
            nuPos.y = nuPos.y+posOffset.y;
            num = this._buildZ(nuPos.clone(), posOffset, num);
            uc++;
        }
        let dc=1;
        let ndPos = yPos.clone();
        while(this._dc >= dc) {
            num++;
            ndPos.y = ndPos.y - posOffset.y;
            num = this._buildZ(ndPos.clone(), posOffset, num);
            dc++;
        }
        return num;
    }

    _buildZ(yxPos: Vector3, posOffset, num)
    {
        // Center X-Line
        num = this._buildX(yxPos, posOffset, num);
        let bc = 1;
        let nbPos = yxPos.clone();
        while(this._bc >= bc) {
            num++;
            nbPos.z = nbPos.z+posOffset.z;
            num = this._buildX(nbPos.clone(), posOffset, num);
            bc++;
        }
        let fc = 1;
        let nfPos = yxPos.clone();
        while(this._fc >= fc) {
            num++;
            nfPos.z = nfPos.z-posOffset.z;
            num = this._buildX(nfPos.clone(), posOffset, num);
            fc++;
        }
        return num;
    }

    _buildX(nPos: Vector3, posOffset, num)
    {
        // center platform von y/z
        this.platforms.push(this._buildPlatform("pl-"+num, nPos.clone()));
        // breite X nach rechts
        let rc = 1;
        let nrPos = nPos.clone(); // y/z halter
        while(this._rc >= rc) {
            num++;
            nrPos.x = nrPos.x+posOffset.x;
            this.platforms.push(this._buildPlatform("pl-"+num, nrPos.clone()));
            rc++;
        }
        let lc = 1;
        let nlPos = nPos.clone();
        while(this._lc >= lc) {
            num++;
            nlPos.x = nlPos.x-posOffset.x;
            this.platforms.push(this._buildPlatform("pl-"+num, nlPos.clone()));
            lc++;
        }
        return num;
    }

    _buildPlatform(name: string, position: Vector3)
    {
        let material = this._materialFactory();
        let pl = MeshBuilder.CreateBox(name+" platform", {
            width: Number(this.platformSize.z),
            height: Number(this.platformSize.y),
            depth: Number(this.platformSize.x)
        }, this.scene);

        pl.material = material;
        pl.checkCollisions = true;
        pl.receiveShadows = true;
        pl.isPickable = true;
        pl.showBoundingBox = false;
        pl.renderOutline = true;
        pl.outlineWidth = 0.05;
        pl.outlineColor = Color3.Red();
        //pl.forceSharedVertices();
        pl.parent = this._root;
        pl.position = position;
        return pl;
    }

    _materialFactory()
    {
        let groundMaterial = new StandardMaterial("groundMaterial", this.scene);
        let texture = new Texture(new URL('Lordfox_Logo_Wallpaper_1920x1080_sign_only.png', import.meta.url).href, this.scene) ;
        texture.uScale = 1;
        texture.vScale = 1;
        texture.wRotationCenter = 0;
        texture.wAng = Math.PI;
        groundMaterial.diffuseTexture = texture
        groundMaterial.specularColor = new Color3(.1, .1, .1);
        groundMaterial.alpha = 0.5;
        return groundMaterial;
    }


}