import {EventTable, View} from '../../../Framework/View';
import {Model} from '../../../Framework/Model';
import SideBarLayoutTemplate from "bundle-text:./Sidebar.layout.html";
import SideBarTemplate from "bundle-text:./Sidebar.html";
import SideBarSubTemplate from "bundle-text:./Sidebar.sub.html";

import {DomHelper} from '../../../Framework/DomHelper'

export class SideBarBase extends View {

    tplTag:string;

    user:Model;

    constructor() {
        super();
        this.engine.add('lvp.com.sidebar.layout', SideBarLayoutTemplate);
        this.engine.add('lvp.com.sidebar.content', SideBarTemplate);
        this.engine.add('lvp.com.sidebar.sub.content', SideBarSubTemplate);
    }

    events:EventTable = {

        'click .nav-link'(e) {
            let wasActive = e.target.classList.contains('active');
            let isPlayerToggle = e.target.classList.contains('toggle-player');
            if (wasActive) {
                e.target.classList.remove('active');
                if (isPlayerToggle) {
                    window.Application.el.querySelector('#overlay-content').classList.add('d-none');
                } else {
                    window.Application.el.querySelector('#overlay-content').classList.remove('d-none');
                }
            } else {
                if (!isPlayerToggle) {
                    window.Application.el.querySelector('#overlay-content').classList.add('d-none');
                } else {
                    window.Application.el.querySelector('#overlay-content').classList.remove('d-none');
                }
                let nav = e.target.closest('.nav');
                let l = DomHelper.find(nav, '.nav-link');
                l.forEach((el)=> {
                    if (el.classList.contains('active')) {
                        el.classList.remove('active');
                    }
                });
                if (!e.target.classList.contains('active')) {
                    //if (!wasActive) {
                    e.target.classList.add('active');
                    // }
                }
            }
            window.Application.resize();
        },

        'click .toggle-player'(e) {
            //window.Application.el.querySelector('#overlay-content').classList.toggle('d-none');
            //window.Application.el.querySelector('#content').classList.toggle('d-none');
        },

        'click .sub-expand'(e) {
            document.querySelector('.sub-sidebar').classList.toggle('d-none');
            e.target.classList.toggle('active');
        }
    }

    isUserLoggedIn() {
        return window.Application.connector.Session.isUserLoggedIn();
    }

    render() {

        if (this.isUserLoggedIn()) {
            this.user = new Model(window.Application.connector.Session.getUser());
        } else {
            this.user = new Model({});
        }
        let vars = {
            view: this,
            user: this.user
        };
        this.el.innerHTML = this.engine.render(this.tplTag, vars);
    }

}

export class SideBar extends SideBarBase {

    constructor() {
        super();
        this.init();
    }

    init() {
        this.el.classList.add('lvp-sidebar');
        this.el.classList.add('d-flex');
        this.el.classList.add('flex-column');
        this.el.classList.add('flex-shrink-0');
        this.el.classList.add('bg-dark');
        this.el.classList.add('border-right');
        //this.el.classList.add('d-none');
        this.el.style.cssText +="width: 2.5rem;";
        this.tplTag = "lvp.com.sidebar.content";

        const that = this;

        this.one('bind.events', function() {

            window.Hammer.Pan({
                threshold: 20
            });

            const Hammer = window.Hammer;
            const selectors = ['.cx-container', '#overlay-content', '.track-item' , '#track-list'];

            selectors.forEach(function (selector) {
                let elem = document.querySelector(selector);

                if (elem) {
                    let Swipe = new Hammer.Swipe();
                    let manager = new Hammer.Manager(elem, {
                        touchAction: 'pan-y'
                    });
                    manager.add(Swipe);
                    /*
                    manager.on("hammer.input", function(ev) {
                        console.log(ev);
                    });

                     */
                    manager.on('swipe', function(e) {
                        let direction = e.offsetDirection;
                        if (direction == 4) {
                            //that.el.classList.remove('d-none');
                            window.Application.el.querySelector('#overlay-content').classList.remove('d-none');
                            window.Application.resize();
                        } else if (direction == 2) {
                            //that.el.classList.add('d-none');
                            window.Application.el.querySelector('#overlay-content').classList.add('d-none');
                            window.Application.resize();
                        }
                        e.preventDefault();
                    });
                }


            });


/*

            let mc = new window.Hammer();

            mc.stop();
            mc.on("panleft panright", function(ev) {
                console.debug(ev);
                console.debug(ev.type);
                console.debug(ev.eventType);

                //window.Application.el.querySelector('#content').innerHTML = ev.eventType;
                if (ev.isFinal) {    // only input end!

                    if (ev.target.nodeName !== 'INPUT') { // if not on input field


                        //window.Application.el.querySelector('#content').innerHTML = ev.angle;
                        if (ev.type == 'panright') {
                           // if (ev.angle <= 10 && ev.angle >= -10) {
                                that.el.classList.remove('d-none');
                                window.Application.el.querySelector('#overlay-content').classList.remove('d-none');
                           // }
                        } else if (ev.type == 'panleft') {
                           // if (Math.abs(ev.angle) >  170 && Math.abs(ev.angle) < 180) {
                                that.el.classList.add('d-none');
                                window.Application.el.querySelector('#overlay-content').classList.add('d-none');
                           // }
                        }
                    }
                }

            });

 */

        });


    }

}

export class SubSideBar extends SideBarBase {

    events:EventTable = {

    }

    constructor() {
        super();
        this.init();
    }

    init() {
        this.el.classList.add('sub-sidebar');
        this.el.classList.add('d-flex');
        this.el.classList.add('flex-column');
        this.el.classList.add('flex-shrink-0');
        this.el.classList.add('bg-dark');
        this.el.classList.add('p3');
        this.el.classList.add('d-none');
        //this.el.style.cssText +="width: 300px;";
        this.tplTag = 'lvp.com.sidebar.sub.content';
    }

}