import {Application} from "./Application/Application";
import * as BABYLON from "babylonjs"
import Hammer from "hammerjs";

declare global {
    interface Window {
        Application: any,
        LVPX: any,
        OSB: any,
        createViewer: any,
        OSBFactory: any,
        OSBViewer,
        Hammer: Hammer
    }
}

if (!window.LVPX) {
    window.LVPX = window.LVPX ||  {
        "Cache": { "Instances":{}}
    };
} else {
    if (!window.LVPX.Cache) {
        window.LVPX.Cache = {"Instances":{}};
    }
}

window.OSB = {};
window.OSB.BABYLON = BABYLON;
window.Hammer = Hammer;

if (true) {

    const broadcastChannel = new BroadcastChannel("sw");
    broadcastChannel.addEventListener("message", e => {
        switch(e.data.type){
            case "clear-cache-done":
                console.log("SW Cache cleared!");
        }
    });

    /**
     * @see https://pwadvent.dev/day/9/
     */
    const showNotification = () =>
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                let iconUrl = new URL('./cdn/img/lordfox-icons/rounded/icon-64x64.png', import.meta.url).href;
                let badgeUrl = new URL('./cdn/img/lordfox-icons/rounded/icon-64x64.png', import.meta.url).href;
                new Notification('Hi there!', {
                    body: 'I am the body text!',
                    icon: iconUrl,
                    badge: badgeUrl,
                });
            }
        });


    if ('launchQueue' in window) {
        // @ts-ignore
        window.launchQueue.setConsumer(async launchParams => {
            if (launchParams.files.length) {
                const fileHandles = launchParams.files;
                fileHandles.map(handle => console.log(handle.name));
                /**
                 * fileHandles is now an array of SystemFileHandles
                 * https://wicg.github.io/file-system-access/#api-filesystemfilehandle
                 */
            }
        });
    }

    let App = new Application();
    window.Application = window.Application || App;

    App.initConnector();

    App.initBootLoader(() => {

        document.querySelector('body').replaceWith(App.el);
        App.update();
        App.initViews();
        App.initRoutes();
        App.initViewer();
        App.initServiceWorker();
        //App.bindEvents(App.events);

        /**
         * @see https://pwadvent.dev/day/23/
         * const unreadMessagesCount = 42;
         *
         * // Setting the badge
         *
         * // Performing feature detection
         * if (navigator.setAppBadge) {
         *     navigator.setAppBadge(unreadMessagesCount ).catch((error) => {
         *         // Logging an error, providing some UI fallback
         *     });
         * }
         *
         * // Clear the badge
         * if (navigator.clearAppBadge) {
         *     navigator.clearAppBadge().catch((error) => {
         *         // Logging an error
         *     });
         * }
         *
         *
         */


        /**
         * @see https://pwadvent.dev/day/21/
         * fileHandler
         * @todo: handle incoming files
         */
        if ('launchQueue' in window) {
            // @ts-ignore
            window.launchQueue.setConsumer(async launchParams => {
                if (launchParams.files.length) {
                    const fileHandles = launchParams.files;
                    fileHandles.map(handle => console.log(handle.name));
                    /**
                     * fileHandles is now an array of SystemFileHandles
                     * https://wicg.github.io/file-system-access/#api-filesystemfilehandle
                     */
                }
            });
        }

    });




}




