import {Viewer} from "./Viewer";
import {Color3, Color4, Vector3} from "babylonjs";
import {Events} from "../../../Framework/Events"
declare var LVPX: any;


export class InputHandler extends Events {

    holdsCtrl:boolean = false;
    holdsAlt:boolean = false;

    startingPoint:any = false;
    viewer:Viewer;

    constructor(viewer:Viewer) {
        super();
        this.viewer = viewer;
    }

    listen() {
        let s = this;
        let v = this.viewer;
        window.onkeydown=function(e) {
            s.onKeyDown(e);
        };
        window.onkeyup=function(e) {
            s.onKeyUp(e);
        };
        v.canvas.addEventListener("pointerdown", function(e) {
            s.onPointerDown(e);
        }, false);
        v.canvas.addEventListener("pointerup", function(e) {
            s.onPointerUp(e);
        }, false);
    }

    getGroundPosition() {
        let v = this.viewer;
        let scene = v.SceneManager.scene;
        // Use a predicate to get position on the ground
        let pickInfo = scene.pick(scene.pointerX, scene.pointerY, function (mesh) { return mesh == v.GroundManager.ground; });
        if (pickInfo.hit) {
            return pickInfo.pickedPoint;
        }
        return null;
    }

    onPointerUp(e) {
       // console.log('pointer up');
        let s = this;
        let v = this.viewer;
        if (s.holdsCtrl) {
            if (s.startingPoint) {
                v.CameraManager.camera.attachControl(v.canvas, true);
                s.startingPoint = null;
                return;
            }
        } else {
           // console.log('ctrl is unpressed on up!');

        }
    }

    onPointerDown(e) {
       // console.log('pointer down');
        let v = this.viewer;
        let s = this;
        let scene = v.SceneManager.scene;
        let pm;

        if (v.PickInfo) {
            if (v.PickInfo.pickedMesh !== null) {
                let pm = v.PickInfo.pickedMesh;
                pm.renderOutline = false;
                pm.outlineWidth = 0.02;
                pm.outlineColor = new Color3( 255, 200, 0);

            }
        }


        // on picking a mesh
        v.PickInfo = scene.pick(scene.pointerX, scene.pointerY, function (mesh) {
            v.PickMesh = mesh;
            return true;
        });

        if (v.PickInfo) {
            if (v.PickInfo.pickedMesh !== null) {
                pm = v.PickInfo.pickedMesh;
                pm.renderOutline = true;
                pm.outlineWidth = 0.02;
                pm.outlineColor = new Color4( 255, 200, 0, 1);
            }
        }
        console.debug(v.PickInfo);
/*
        LVPX.ACP.trigger({
            "type": "pickMesh",
            "response": {
                "info": v.PickInfo,
                "mesh": v.PickMesh
            }
        });

        LVPX.ACP.one('pickMesh', function(e) {
            console.debug(e);
        });
*/
        if (s.holdsCtrl && s.holdsAlt) {
            //console.log('ctrl is down!');
            let scene = v.SceneManager.scene;
            //console.log("pointer down");
            // check if we are under a mesh
            let pickInfo = scene.pick(scene.pointerX, scene.pointerY, function (mesh) {
                return true;
                //return mesh !== v.GroundManager.ground;
            });
            if (pickInfo.hit) {
                let currentMesh = pickInfo.pickedMesh;

                v.PickInfo = pickInfo;

                s.startingPoint = s.getGroundPosition();
                if (s.startingPoint) { // we need to disconnect camera from canvas
                    setTimeout(function () {
                        v.CameraManager.camera.detachControl(v.canvas);
                    }, 0);
                }
                //v.CameraManager.camera.setTarget(s.startingPoint);
                //let targetEndPos = new Vector3(0, 0, 0);
                let targetEndPos = v.CameraManager.camera.position;
                //v.CameraManager.camera.spinTo('position', targetEndPos, 240);
                //v.CameraManager.camera.spinTo('target', pickInfo.pickedPoint, 240);
                v.CameraManager.camera.setTarget(pickInfo.pickedPoint);
            } else {
                //console.log('pickInfo NO hit');
            }
        } else {
            //console.log('ctrl is unpressed on down!');
        }
    }

    onKeyUp(e) {
        if (e.key=="Control") {
            this.holdsCtrl=false;
        }
        if (e.key== "Alt") {
            this.holdsAlt=false;
        }
    }

    onKeyDown(e) {
        const Character = this.viewer.CharacterManager.character;
        const speedForward:string = "0.5";
        const speedBackward:string = "0.3";
        const speedRotation:string = "0.1";

        if (e.key=="Control") {
            this.holdsCtrl=true;
        }
        if (e.key== "Alt") {
            this.holdsAlt=true;
        }
        if (e.key=="w") {
            //Character.moveWithCollisions(Character.forward.scaleInPlace(parseFloat(speedForward)));
            Character.moveWithCollisions(Character.forward.scaleInPlace(parseFloat(speedForward)));
        }

        if (e.key=="s") {
            //Character.moveWithCollisions(Character.forward.scaleInPlace(-parseFloat(speedBackward)));
            Character.moveWithCollisions(Character.forward.scaleInPlace(-parseFloat(speedBackward)));
        }

        if (e.key=="a") {
            Character.rotate(Vector3.Up(), -parseFloat(speedRotation));
        }

        if (e.key=="d") {
            Character.rotate(Vector3.Up(), parseFloat(speedRotation));
        }

        console.log(e.key);
    }

}