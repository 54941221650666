import * as SDK from "../../../Application/SDK/ViewSDK";
import Template from "bundle-text:./PasteBoard.Layout.html";
import {tSNullKeyword} from "@babel/types";

export class PasteBoard extends SDK.View {

    session: SDK.SessionHandler;

    connector: SDK.LordfoxConnector;

    events: SDK.EventTable = {
        'click .request-permissions': async (e) => {
            await this.requestClipboardRead();
        },

        'click .me': async (e) => {
            console.log(e);
        },

        'paste .paste-area': async (e) => {
            console.log('hi');
            e.preventDefault();
            console.log('hi2');
            const that = this;
            const clipboardItems = typeof navigator?.clipboard?.read === 'function' ? await navigator.clipboard.read() : e.clipboardData.files;

            // @ts-ignore
            for (const clipboardItem of clipboardItems) {
                let blob;
                console.log(clipboardItem)
                if (clipboardItem.type?.startsWith('image/')) {
                    // For files from `e.clipboardData.files`.
                    blob = clipboardItem
                    // Do something with the blob.
                    console.log(blob)
                    let image = new Image();
                    image.src = URL.createObjectURL(blob);
                    that.el.querySelector('.images').append(image);
                    that.connector.fileUpload({}, blob);
                } else {
                    // For files from `navigator.clipboard.read()`.
                    const imageTypes = clipboardItem.types?.filter(type => type.startsWith('image/'))
                    for (const imageType of imageTypes) {
                        blob = await clipboardItem.getType(imageType);
                        // Do something with the blob.
                        let image = new Image();
                        image.src = URL.createObjectURL(blob);
                        that.el.querySelector('.images').append(image);
                        that.connector.fileUpload({}, blob, that.el.querySelector('.progress'));

                    }
                }
            }
        }
    }

    states: any = {}

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.engine.add('lvp.app.layout', SDK.AppLayoutTemplate);
        this.engine.add('PasteBoard.Layout', Template);
        //document.addEventListener('paste', async (e) => {});
    }

    render() {
        let vars = {};

        this.renderEl('PasteBoard.Layout', vars);
    }

    async requestClipboardRead()
    {
        const permissionStatus  = await navigator.permissions.query({
            // @ts-ignore
            name: "clipboard-read",
            allowWithoutGesture: false
        });
        // Will be 'granted', 'denied' or 'prompt':
        console.log(permissionStatus.state);
        // Listen for changes to the permission state
        permissionStatus.onchange = () => {
            console.log(permissionStatus.state);
        };
    }

}