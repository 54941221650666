import {ConversationDatabase, MessageDatabase, UserDatabase} from "./Database";
import {Table} from "dexie";
import {Conversation} from "./interfaces/Conversation";
import {Message} from "./interfaces/Message";

export class Messages {

    db:MessageDatabase
    messages:Table

    constructor() {
        this.db = new MessageDatabase();
        this.messages = this.db.messages;
    }

    async add(message:Message) {
        this.db.transaction('rw', this.messages, async() => {
            await this.db.messages.add(message);

            /**
             * set lastMessageId and dateTime for the conversation
             */
            const db = new ConversationDatabase();
            db.transaction('rw', db.conversations, async() => {
                let conversation = await db.conversations.get(message.conversation);
                conversation.lastMessageId = message.id;
                conversation.lastMessageAt = message.createdAt;
                db.conversations.where('id').equals(message.conversation).modify(conversation);
            });

        });
    }

    async get(id:string) {
        return this.messages.get(id);
    }

    async getByConversation(conversationId: string) {
        return this.messages.where('conversation').equals(conversationId).sortBy('createdAt')
    }

    async getMessageDataByConversation(conversation:Conversation) {
        let messages = await this.getByConversation(conversation.id);
        let messageById = {};
        for (const message of messages) {
            messageById[message.id] = message;
        }
        return messageById;
    }

    async getLastConversationMessage(conversation:Conversation) {
        return this.messages
            .where('conversation')
            .equals(conversation.id)
            .reverse()
            .sortBy('createdAt');
    }

}

export class Conversations {

    db:ConversationDatabase

    conversations:Table

    constructor() {
        this.db = new ConversationDatabase();
        this.conversations = this.db.conversations;
    }

    async getPinnedConversations() {
        return this.conversations.where('isPinned').equals(1).sortBy('createdAt');
    }

    async getConversations() {
        return this.conversations.where('isPinned').equals(0).sortBy('createdAt');
    }

    async get(id:string) {
        return this.conversations.get(id);
    }




}

export class Users {

    db:UserDatabase;
    users: Table;

    public constructor() {
        this.db = new UserDatabase();
        this.users = this.db.users;
    }

    async get(id:string) {
        return this.users.get(id);
    }

    async getUserDataByConversation(conversation:Conversation) {
        let userById = {};
        const that = this;
        for (const id of conversation.users) {
            userById[id] = await that.users.get(id);
        }
        return userById;
    }

}