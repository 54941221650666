import {Events} from '../../../Framework/Events';
import {
    AxesViewer,
    Quaternion,
    Scalar,
    Scene,
    Vector3,
    TransformNode,
    KeyboardEventTypes,
    ArcRotateCamera,
    Mesh,
    Ray,
    ExecuteCodeAction,
    ActionManager
} from "babylonjs";
import {PlayerInput} from "./PlayerInput";
import {Viewer} from "./Viewer";
import {displayAxis} from "./Utils/MeshUtil";



export class Character extends TransformNode {

    /**
     * Viewer environment
     */
    viewer: Viewer;

    /**
     * origin animationGroups
     */
    animationSet: any []

    /**
     * map of animations
     */
    animationMap: any = [];

    /**
     * root mesh of the character
     */
    _root: any;

    /**
     * all meshes of the character
     */
    meshes: any []

    particles: any []

    skeletons: any []

    inputMap: any = [];

    playerInput: PlayerInput;

    //gravity, ground detection, jumping
    private _gravity: Vector3 = new Vector3();
    private _lastGroundPos: Vector3 = Vector3.Zero(); // keep track of the last grounded position
    private _grounded: boolean;
    private _jumpCount: number = 1;

    private _isFalling: boolean = false;
    private _jumped: boolean = false;

    //dashing
    private _dashPressed: boolean;
    private _canDash: boolean = true;

    //const values
    private static readonly PLAYER_SPEED: number = 0.45;
    private static readonly JUMP_FORCE: number = 0.80;
    private static readonly GRAVITY: number = -1.8;
    private static readonly DASH_FACTOR: number = 2.5;
    private static readonly DASH_TIME: number = 10; //how many frames the dash lasts
    private static readonly DOWN_TILT: Vector3 = new Vector3(0.8290313946973066, 0, 0);
    public dashTime: number = 0;

    /**
     * current scene
     */
    scene: Scene

    /**
     * Animate the character?
     */
    animate:boolean = false;

    allowMovement: boolean = true;

    defaultRezStartPosition:Vector3 = new Vector3(10,0,0.1);

    axesViewer: AxesViewer = null;

    isLoaded: boolean = false;


    _deltaTime: number = 0;
    _moveDirection: Vector3 = Vector3.Zero();

    _h;
    _v;


    /**
     * aktuelle animation
     */
    currentAnimation: any;

    /**
     * letzte animation
     */
    lastAnimation: any;

    /**
     * eine erzwungene animation
     */
    forceAnimation: any = null;

    /**
     * fallback default animation
     */
    idleAnimation: any = null;


    /**
     * aktuelle animation muss durchlaufen
     */
    waitForEndAnimation: boolean = false;

    /**
     * lock keypress delay in ms
     */
    keyPressLockDelay = 300;

    /**
     * aktuell locked keys
     */
    keyLockMap:any = [];

    alphaTarget: any;


    _camRoot: TransformNode;

    _yTilt: TransformNode;

    camera;

    lanternsLit = 0;

    win:boolean = false;

    private static readonly ORIGINAL_TILT: Vector3 = new Vector3(0.5934119456780721, 0, 0);


    constructor(viewer: Viewer, type:string, rezStartPosition?: Vector3) {
        super("player", viewer.SceneManager.scene);
        //super('character')
        this.viewer = viewer;
        this.scene = this.viewer.SceneManager.scene;

        if (type == 'DIRECT_PLAYER') {
            this.setUpPlayerInput();
            this.setUpCamera();
        }

        this.waitLoaded(()=> {

            this.camera = this.scene.activeCamera;

            this._root.actionManager = new ActionManager(this.scene);
/*
            this._root.actionManager.registerAction(
                new ExecuteCodeAction(
                    {
                        trigger: ActionManager.OnIntersectionEnterTrigger,
                        parameter: this.scene.getMeshByName("destination")
                    },
                    () => {

                        if(this.lanternsLit == 22){
                            this.win = true;
                            //tilt camera to look at where the fireworks will be displayed
                            this._yTilt.rotation = new Vector3(5.689773361501514, 0.23736477827122882, 0);
                            this._yTilt.position = new Vector3(0, 6, 0);
                            this.camera.position.y = 17;
                        }
                    }
                )
            );
*/
            //if player falls through "world", reset the position to the last safe grounded position

            this._root.actionManager.registerAction(
                new ExecuteCodeAction({
                        trigger: ActionManager.OnIntersectionEnterTrigger,
                        parameter: this.scene.getMeshByName("ground")
                    },
                    () => {
                        this._root.position.copyFrom(this._lastGroundPos); // need to use copy or else they will be both pointing at the same thing & update together
                        //--SOUNDS--
                        //this._resetSfx.play();
                    }
                )
            );


/*
            this.mesh.actionManager.registerAction(
                new ExecuteCodeAction(
                    {
                        trigger: ActionManager.OnIntersectionEnterTrigger,
                        parameter: this.scene.getMeshByName("destination"),
                    },
                    () => {
                        if (this.lanternsLit == 22) {
                            this.win = true;
                            //tilt camera to look at where the fireworks will be displayed
                            this._yTilt.rotation = new Vector3(5.689773361501514, 0.23736477827122882, 0);
                            this._yTilt.position = new Vector3(0, 6, 0);
                            this.camera.position.y = 17;
                        }
                    },
                ),
            );
*/


            this._root.parent = this;
            //this.rotation = new Vector3(0, Math.PI, 0);

            /**
             * Rez Character on position
             */
            if (rezStartPosition) {
                this.setPosition(rezStartPosition);
            } else {
                this.setPosition(this.defaultRezStartPosition);
            }

            /**
             * enable shadow and collision
             */
            this.setUpMeshes();
            //this.enableAxesViewer();

            if (type == 'DIRECT_PLAYER') {

                // set camera target and position behind the player
                let camera = this.viewer.CameraManager.camera;
                camera.parent = this._yTilt
                camera.lockedTarget = this._camRoot.position;

                //console.log('camera-target', this.getRoot().position.clone());
               // this.viewer.CameraManager.camera.setPosition(new Vector3(Math.PI/2, Math.PI/3, 10));


                const that = this;

                // One way of handling input
                this.scene.onKeyboardObservable.add((eventData) => {
                    if (eventData.type === KeyboardEventTypes.KEYDOWN && eventData.event.keyCode === 87 /* w */) {
                        that.alphaTarget = that.getRoot().rotation.y + Math.PI;
                    }

                });

                this.scene.onBeforeRenderObservable.add(function() {
                    that.handlePlayerMovement();
                    that._updateGroundDetection();
                    that.handlePlayerMovementAnimation();
                    //that.handleCameraMovement();
                    that.updateCamera();
                });

                camera.onViewMatrixChangedObservable.add(() => {
                    //console.log('changed vmat');
                });
            }



        });

    }

    setUpCamera() {

        //root camera parent that handles positioning of the camera to follow the player
        this._camRoot = new TransformNode("root");
        this._camRoot.position = new Vector3(0, 0, 0); //initialized at (0,0,0)
        //to face the player from behind (180 degrees)
        this._camRoot.rotation = new Vector3(0, Math.PI, 0);


        //rotations along the x-axis (up/down tilting)
        let yTilt = new TransformNode("ytilt");
        //adjustments to camera view to point down at our player
        yTilt.rotation = Character.ORIGINAL_TILT;
        this._yTilt = yTilt;
        yTilt.parent = this._camRoot;

    }

    updateCamera() {
        //update camera postion up/down movement
        let centerPlayer = this._root.position.y + 2;
        this._camRoot.position = Vector3.Lerp(this._camRoot.position, new Vector3(this._root.position.x, centerPlayer, this._root.position.z), 0.4);
    }

    handleCameraMovement() {

        let playerInput = this.playerInput;
        let Camera:ArcRotateCamera = this.viewer.CameraManager.camera;
        let Character = this._root;
        let alphaTarget = this.alphaTarget;




        /**
         * @see https://doc.babylonjs.com/features/featuresDeepDive/cameras/camera_introduction
         */
        if (playerInput.moving) {


            //let relPos = this._root.getPositionInCameraSpace(Camera);
            //console.log('apos', this._root.getAbsolutePosition());

            //console.log('rel-rot', relRot);

            //Camera.targetScreenOffset.x = relPos.x;
            //Camera.targetScreenOffset.y = relPos.y;
            //Camera.radius = relPos.z;
            //console.log('rel-pos', relPos);


            let cameraDistance = Vector3.Distance(Camera.position, Character.position);
            //let alphaTarget = Math.PI;
            let betaTarget = Math.PI/3;
            //Camera.setTarget(Character);
            //Camera.lockedTarget = Character;

            //console.log(Character.getDirection(Vector3.Zero()));
            //Camera.alpha = Scalar.Lerp(Camera.alpha, alphaTarget, 0.05);


            let delta = 0.001;
            let dAlpha = Math.abs(Camera.alpha - alphaTarget);
            let dBeta = Math.abs(Camera.beta - betaTarget);

            if (dAlpha > delta || dBeta > delta) {
                //Camera.spinTo("alpha", Scalar.Lerp(Camera.alpha, alphaTarget, 0.05), 200);
                Camera.alpha = Scalar.Lerp(Camera.alpha, alphaTarget, 0.05);
            } else {
                //Camera.spinTo("alpha", alphaTarget, 200);
                Camera.alpha = alphaTarget;
            }


        }

    }

    setCurrentAnimation(animation, animationMustEnd = false) {
        // wenn neue Animation
        if (animation && animation != this.currentAnimation && !this.waitForEndAnimation) {

            if (this.currentAnimation) {
                this.currentAnimation.stop();
                this.lastAnimation = this.currentAnimation;
            }

            if (animationMustEnd) {
                this.waitForEndAnimation = true;
                this.currentAnimation = animation;
                this.currentAnimation.onAnimationEndObservable.add(()=> {
                    this.waitForEndAnimation = false;
                });
                this.currentAnimation.start(false, 1, this.currentAnimation.from, this.currentAnimation.to-10);
            } else {
                this.currentAnimation = animation;
                this.currentAnimation.start(true);
            }
        }
    }
    handlePlayerMovementAnimation() {

        let InputMap = this.inputMap;
        let playerInput = this.playerInput;

        let animation = null;
        let animationMustEnd = false;

        let forward = InputMap["w"];
        let backward = InputMap["s"];
        let left = InputMap["a"];
        let right = InputMap["d"];

        let moving = playerInput.moving
        let running = playerInput.dashing;
        let jumping = playerInput.jumpKeyDown;


        if (!this.waitForEndAnimation) {
            if (moving && running && !jumping) {
                animation = this.getAnimation('run');
            } else if (moving && !jumping) {
                animation = this.getAnimation('walk');
            } else if (jumping) {
                // inital jump

                if (this._isGrounded()) {
                    animation = this.getAnimation('idle');
                } else {
                    animation = this.getAnimation('jump');
                    animationMustEnd = true;
                }
            } else {

                animation = this.getAnimation('idle');
            }

            this.setCurrentAnimation(animation, animationMustEnd);
        }



        /*
        if (InputMap["b"]) {

            if (lockDelay==0) {
                //keydown = true;
                if (fallbackAnimation !== dance1Anim) {
                    fallbackAnimation = dance1Anim;
                } else {
                    fallbackAnimation = idleAnim;
                }
                newAnimation = fallbackAnimation;
                lockDelay=1;
            } else {
                newAnimation = null;
                setTimeout(function() {
                    lockDelay=0;
                },400);
            }
        }
        */




    }
    handlePlayerMovementAnimation2() {

        let InputMap = this.inputMap;
        let playerInput = this.playerInput;

        let animation = null;
        let animationMustEnd = false;

        let forward = InputMap["w"];
        let backward = InputMap["s"];
        let left = InputMap["a"];
        let right = InputMap["d"];

        let moving = playerInput.moving
        let running = playerInput.dashing;
        let jumping = playerInput.jumpKeyDown;


        if (!this.waitForEndAnimation) {
            if (moving && !jumping) {

                if (forward && running) {
                    animation = this.getAnimation('run');
                } else if (forward) {
                    if (right) {
                        animation = this.getAnimation('walkRight');
                    } else if (left) {
                        animation = this.getAnimation('walkLeft');
                    } else {
                        animation = this.getAnimation('walk');
                    }
                } else if (backward) {
                    animation = this.getAnimation('walkBack');
                }

            } else if (jumping) {

                // inital jump
                animation = this.getAnimation('jump');
                animationMustEnd = true;

            } else {

                animation = this.getAnimation('idle');
            }

            this.setCurrentAnimation(animation, animationMustEnd);
        }



        /*
        if (InputMap["b"]) {

            if (lockDelay==0) {
                //keydown = true;
                if (fallbackAnimation !== dance1Anim) {
                    fallbackAnimation = dance1Anim;
                } else {
                    fallbackAnimation = idleAnim;
                }
                newAnimation = fallbackAnimation;
                lockDelay=1;
            } else {
                newAnimation = null;
                setTimeout(function() {
                    lockDelay=0;
                },400);
            }
        }
        */




    }

    handlePlayerMovement() {


        let playerInput = this.playerInput;

            this._deltaTime = this.scene.getEngine().getDeltaTime() / 1000.0;

            this._moveDirection = Vector3.Zero();
            this._h = this.playerInput.horizontal; //right, x
            this._v = this.playerInput.vertical; //fwd, z

            //tutorial, if the player moves for the first time
           // if((this._h != 0 || this._v != 0) && !this.tutorial_move){
           //     this.tutorial_move = true;
           // }

            //--DASHING--
            //limit dash to once per ground/platform touch
            //can only dash when in the air
            if (this.playerInput.dashing && !this._dashPressed && this._canDash && !this._grounded) {
                this._canDash = false;
                this._dashPressed = true;

                //sfx and animations
                //this._currentAnim = this._dash;
                //this._dashingSfx.play();

                //tutorial, if the player dashes for the first time
                //if(!this.tutorial_dash){
                //    this.tutorial_dash = true;
               // }
            }

            let dashFactor = 1;
            //if you're dashing, scale movement
            if (this._dashPressed) {
                if (this.dashTime > Character.DASH_TIME) {
                    this.dashTime = 0;
                    this._dashPressed = false;
                } else {
                    dashFactor = Character.DASH_FACTOR;
                }
                this.dashTime++;
            }

            /**
             * @see https://playground.babylonjs.com/#3B5W22#30
             */

            let _moveDirection = this._moveDirection;

            let Mesh = this._root;

            let CamRoot = this._camRoot;
            Mesh.checkCollisions = true;
            let Scene = this.scene;

            let speedRotation:string = "0.02";

            let _inputAmt=0;

            let _h = playerInput.horizontal; //x-axis
            let _v = playerInput.vertical; //z-axis

            let fwd = CamRoot.forward;
            let right = CamRoot.right;

            let correctedVertical = fwd.scaleInPlace(_v);
            let correctedHorizontal = right.scaleInPlace(_h);
            let move = correctedHorizontal.addInPlace(correctedVertical);

            if (!playerInput.dashing) {
                _moveDirection= new Vector3((move).normalize().x, 0, (move).normalize().z);
            } else {
                _moveDirection= new Vector3((move).normalize().x*dashFactor, 0, (move).normalize().z*dashFactor);
            }

            //clamp the input value so that diagonal movement isn't twice as fast
            let inputMag = Math.abs(_h) + Math.abs(_v);
            if (inputMag < 0) {
                _inputAmt = 0;
            } else if (inputMag > 1) {
                _inputAmt = 1;
            } else {
                _inputAmt = inputMag;
            }

            //final movement that takes into consideration the inputs
            _moveDirection = _moveDirection.scaleInPlace(_inputAmt * Character.PLAYER_SPEED);

            Mesh.moveWithCollisions(_moveDirection);
            //Character.rotationQuaternion=null;
            //check if there is movement to determine if rotation is needed
            let input = new Vector3(playerInput.horizontalAxis, 0, playerInput.verticalAxis); //along which axis is the direction
            if (input.length() == 0) {//if there's no input detected, prevent rotation and keep player in same rotation
                return;
            } else {

                //rotation based on input & the camera angle
                let angle = Math.atan2(playerInput.horizontalAxis, playerInput.verticalAxis);

                //console.log('angle', angle);
                angle += CamRoot.rotation.y;
                let targ = Quaternion.FromEulerAngles(0, angle, 0);
                Mesh.rotationQuaternion = Quaternion.Slerp(Mesh.rotationQuaternion, targ, 10 * this._deltaTime);
            }


            //console.log(_moveDirection);
            //console.log('before', Character.position);
            //console.log('move', _moveDirection);

            //console.log('after', Character.position);

/*
            if (InputMap["a"] && !playerInput.dashing) {
                //console.log('ROTATE!');
                Character.rotate(Vector3.Up(), -parseFloat(speedRotation));
                //console.log(Character.rotation);
            } else if (InputMap["a"] && playerInput.dashing) {
                //console.log('ROTATE!');
                Character.rotate(Vector3.Up(), -parseFloat(speedRotation));
                //console.log(Character.rotation);
            }
            if (InputMap["d"] && !playerInput.dashing) {
                //console.log('ROTATE!');
                Character.rotate(Vector3.Up(), parseFloat(speedRotation));
                //console.log(Character.rotation);
            } else if (InputMap["a"] && playerInput.dashing) {
                //console.log('ROTATE!');
                Character.rotate(Vector3.Up(), parseFloat(speedRotation));
                //console.log(Character.rotation);
            }
*/



    }

    waitLoaded(cb) {
        if (this.isLoaded) {
            cb();
        } else {
            setTimeout(()=> {
                this.waitLoaded(cb);
            },100);
        }
    }

    setPosition(vec3:Vector3) {
        this._root.position = vec3;
    }

    getPosition() {
        return this._root.position;
    }

    setUpMeshes() {

        this.meshes.forEach((m)=> {
            m.receiveShadows = true;
            m.checkCollisions = false;
        });

    }

    setUpPlayerInput() {
        this.inputMap = this.viewer.ActionManager.InputMap;
        this.playerInput = new PlayerInput(this.scene, this.viewer.ActionManager);
    }

    getAnimation(name: string) {
        return this.animationMap[name];
    }

    enableAxesViewer() {
        this.axesViewer = displayAxis(this.scene, this._root);
    }

    disableAxesViewer() {
        this.axesViewer.dispose();
        this.axesViewer = null;
    }

    getRoot() {
        return this._root;
    }

    //--PLATFORM DETECTION--
    //Send raycast to the floor to detect if there are any hits with meshes below the character
    private _platformRaycast(offsetx: number, offsetz: number, raycastlen: number) {
        let mesh = this._root;
        //position the raycast from bottom center of mesh
        let raycastFloorPos = new Vector3(mesh.position.x + offsetx, mesh.position.y - 0.5, mesh.position.z + offsetz);
        let ray = new Ray(raycastFloorPos, Vector3.Up().scale(-1), raycastlen);

        //defined which type of meshes should be pickable
        let predicate = function (mesh) {
            //console.log('enabled', mesh.isEnabled());
           // console.log('name match:', mesh.name.includes("platform"));
           // console.log('pickable', mesh.isPickable);
            if (mesh.isPickable && mesh.isEnabled() && mesh.name.includes("platform")) {
                mesh.material.alpha=0.5;
            }

            return mesh.isPickable && mesh.isEnabled() && mesh.name.includes("platform");
        }

        let pick = this.scene.pickWithRay(ray, predicate);
        if (pick.hit) { //grounded
            return pick;
        } else { //not grounded
            return null;
        }
    }

    //--GROUND DETECTION--
    //Send raycast to the floor to detect if there are any hits with meshes below the character
    private _floorRaycast(offsetx: number, offsetz: number, raycastlen: number): Vector3 {
        let mesh = this._root;
        //position the raycast from bottom center of mesh
        let raycastFloorPos = new Vector3(mesh.position.x + offsetx, mesh.position.y - 0.5, mesh.position.z + offsetz);
        let ray = new Ray(raycastFloorPos, Vector3.Up().scale(-1), raycastlen);

        //defined which type of meshes should be pickable
        let predicate = function (mesh) {
            return mesh.isPickable && mesh.isEnabled();
        }

        let pick = this.scene.pickWithRay(ray, predicate);

        if (pick.hit) { //grounded
            return pick.pickedPoint;
        } else { //not grounded
            return Vector3.Zero();
        }
    }

    //raycast from the center of the player to check for whether player is grounded
    private _isGrounded(): boolean {
        if (this._floorRaycast(0, 0, .6).equals(Vector3.Zero())) {
            return false;
        } else {
            return true;
        }
    }

    private _checkSlope(): boolean {

        //only check meshes that are pickable and enabled (specific for collision meshes that are invisible)
        let predicate = function (mesh) {
            return mesh.isPickable && mesh.isEnabled();
        }
        
        let mesh = this._root;

        //4 raycasts outward from center
        let raycast = new Vector3(mesh.position.x, mesh.position.y + 0.5, mesh.position.z + .25);
        let ray = new Ray(raycast, Vector3.Up().scale(-1), 1.5);
        let pick = this.scene.pickWithRay(ray, predicate);

        let raycast2 = new Vector3(mesh.position.x, mesh.position.y + 0.5, mesh.position.z - .25);
        let ray2 = new Ray(raycast2, Vector3.Up().scale(-1), 1.5);
        let pick2 = this.scene.pickWithRay(ray2, predicate);

        let raycast3 = new Vector3(mesh.position.x + .25, mesh.position.y + 0.5, mesh.position.z);
        let ray3 = new Ray(raycast3, Vector3.Up().scale(-1), 1.5);
        let pick3 = this.scene.pickWithRay(ray3, predicate);

        let raycast4 = new Vector3(mesh.position.x - .25, mesh.position.y + 0.5, mesh.position.z);
        let ray4 = new Ray(raycast4, Vector3.Up().scale(-1), 1.5);
        let pick4 = this.scene.pickWithRay(ray4, predicate);

        if (pick.hit && !pick.getNormal().equals(Vector3.Up())) {
            if(pick.pickedMesh.name.includes("stair")) {
                return true;
            }
        } else if (pick2.hit && !pick2.getNormal().equals(Vector3.Up())) {
            if(pick2.pickedMesh.name.includes("stair")) {
                return true;
            }
        }
        else if (pick3.hit && !pick3.getNormal().equals(Vector3.Up())) {
            if(pick3.pickedMesh.name.includes("stair")) {
                return true;
            }
        }
        else if (pick4.hit && !pick4.getNormal().equals(Vector3.Up())) {
            if(pick4.pickedMesh.name.includes("stair")) {
                return true;
            }
        }
        return false;
    }

    private _updateGroundDetection(): void {
        this._deltaTime = this.scene.getEngine().getDeltaTime() / 1000.0;

        //if not grounded
        if (!this._isGrounded()) {
            //if the body isnt grounded, check if it's on a slope and was either falling or walking onto it
            if (this._checkSlope() && this._gravity.y <= 0) {
                console.log("slope")
                //if you are considered on a slope, you're able to jump and gravity wont affect you
                this._gravity.y = 0;
                this._jumpCount = 1;
                this._grounded = true;
            } else {
                //keep applying gravity
                this._gravity = this._gravity.addInPlace(Vector3.Up().scale(this._deltaTime * Character.GRAVITY));
                this._grounded = false;
            }
        }

        //limit the speed of gravity to the negative of the jump power
        if (this._gravity.y < -Character.JUMP_FORCE) {
            this._gravity.y = -Character.JUMP_FORCE;
        }

        //cue falling animation once gravity starts pushing down
        if (this._gravity.y < 0 && this._jumped) { //todo: play a falling anim if not grounded BUT not on a slope
            this._isFalling = true;
        }

        //update our movement to account for jumping
        this._root.moveWithCollisions(this._moveDirection.addInPlace(this._gravity));

        if (this._isGrounded()) {
            this._gravity.y = 0;
            this._grounded = true;
            //keep track of last known ground position
            this._lastGroundPos.copyFrom(this._root.position);

            this._jumpCount = 1;
            //dashing reset
            this._canDash = true;
            //reset sequence(needed if we collide with the ground BEFORE actually completing the dash duration)
            this.dashTime = 0;
            this._dashPressed = false;

            //jump & falling animation flags
            this._jumped = false;
            this._isFalling = false;

        }

        let pick = this._platformRaycast(0, 0, .6);
        //console.log('plRayPick', pick);
        if (pick != null) {
           pick.pickedMesh.material.alpha = 1;
        }

        //Jump detection
        if (this.playerInput.jumpKeyDown && this._jumpCount > 0) {
            this._gravity.y = Character.JUMP_FORCE;
            this._jumpCount--;

            //jumping and falling animation flags
            this._jumped = true;
            this._isFalling = false;
            //this._jumpingSfx.play();

            //tutorial, if the player jumps for the first time
            //if(!this.tutorial_jump){
              //  this.tutorial_jump = true;
           // }
        }

    }
}