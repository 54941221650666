import * as LVP from "../../Application/SDK/ViewSDK";




export class LvpBrand extends LVP.Entity implements LVP.IEntity {

    cache: any = {};

    entityName: string = 'LvpBrand';

    entityIdField: string = 'id';

    defaults: LVP.ModelAttributes = {
        "brand": "",
        "id": "",
        "active": 1
    }

    attributes: LVP.ModelAttributes = {
        "brand": "",
        "id": "",
        "active": 1
    }

    editable:any = {
        'brand': {
            'label': 'Marke',
            'type': 'blocks',
            'prependText': ''
        },

        'active': {
            'label': 'Active',
            'type': 'boolean',
            'prependText': ''
        }
    }

    constructor(attributes?:LVP.ModelAttributes, defaultAttributes?:LVP.ModelAttributes, collection?:LVP.IModelCollection) {
        super(attributes, defaultAttributes, collection);
        /*
        this.cache = new LVP.CacheHandler();
        this.editable.countryCode.options.of = this.cache.get('Countries');

         */
    }

}