import {PreLoader, LoadData} from "../Framework/PreLoader"
import {CacheHandler} from "./CacheHandler"
import {LordfoxConnector} from "./Connector/LordfoxConnector";


export class Bootloader extends PreLoader {

    cache:CacheHandler;

    connector: LordfoxConnector;

    constructor() {

        super();
        const that = this;

        this.cache = new CacheHandler();
        this.connector = window.Application.connector;

        this.add('currencies', {
            init(PD:LoadData) {
                that.connector.request('/CurrencyExchange/GetCurrencies/',{}, 'GET').then(response => {
                    if (response.Ack == 'success') {
                        that.cache.set('Currencies', response.Currencies);
                        PD.done=true;
                    }
                });
            }
        });

        this.add('countries', {
            init: function(PD) {
                that.connector.rest.findBy('LvpCountry', {}, 300, 1).then(response => {
                    that.cache.set('Countries', response);
                    PD.done=true;
                });
            }
        });

        this.add('languages', {
            init: function(PD) {
                that.connector.rest.findBy('LvpLanguage', {}, 300, 1).then(response => {
                    that.cache.set('Languages', response);
                    PD.done=true;
                });
            }
        });

    }

}