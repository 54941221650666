import {MeshBuilder, PhysicsImpostor} from "babylonjs";
import {StandardMaterial} from "babylonjs";
import {Texture} from "babylonjs";
import {
    Color3,
    Vector3
} from "babylonjs";
import {CubeFactory} from "../Meshes/CubeFactory";


export function create(Viewer) {

    let Scene = Viewer.SceneManager.scene;

    /*
    let groundMaterial = new StandardMaterial("groundMaterial", Scene);
    let texture = new Texture("res/textures/wood.jpg", Scene);
        texture.uScale = 30;
        texture.vScale = 30;
        groundMaterial.diffuseTexture = texture
        groundMaterial.specularColor = new Color3(.1, .1, .1);
     */

    let groundMaterial = new StandardMaterial("groundMaterial", Scene);
    //let texture = new Texture("http://osd.lordfox.de:8081/mse/components/Viewer/res/textures/Lordfox_Logo_Wallpaper_1920x1080.png", Scene);
    //let texture = new Texture("res/textures/Lordfox_Logo_Wallpaper_1920x1080.png", Scene);
    //let texture = new Texture("https://app.lordfox.de/mse/cdn/img/lordfox-wallpapers/lvp-wallpaper-trans5.png", Scene);


    let texture = new Texture(new URL('Lordfox_Logo_Wallpaper_1920x1080_sign_only.png', import.meta.url).href, Scene) ;
        texture.uScale = 1;
        texture.vScale = 1;
        texture.wRotationCenter = 0;
        texture.wAng = Math.PI;
        groundMaterial.diffuseTexture = texture
        groundMaterial.specularColor = new Color3(.1, .1, .1);
        groundMaterial.alpha = 1;


    let ground = MeshBuilder.CreateGround("ground", {
            height: 1024,
            width: 1024,
            subdivisions: 32
        }, Scene);
        ground.material = groundMaterial;
        ground.checkCollisions = true;
        ground.isPickable = true;
        ground.receiveShadows = true;

        ground.position = new Vector3(0,-22, 0);


    let ground2 = MeshBuilder.CreateGround("surface", {
        height: 256,
        width: 256,
        subdivisions: 32
    }, Scene);
    ground2.material = groundMaterial;

    ground2.outlineWidth = 20;
    ground2.outlineColor = Color3.Red();
    ground2.showBoundingBox = true;
    ground2.checkCollisions = true;
    ground2.isPickable = true;

    ground2.receiveShadows = true;

    ground2.position = new Vector3(0,-20, 0);

    let cubeSize = new Vector3(10,1,10);
    let boxSize = new Vector3(10, 0.5,10);
    let centerPosition = new Vector3(10,-10,10);
    let boxSpace =  new Vector3(0,5,0);

    let cf = new CubeFactory(Scene, cubeSize, boxSize, centerPosition, boxSpace);



    return ground;

}