import {AudioPlayer, TrackData} from "./AudioPlayer";
import { getId3 } from "./libs/mp3-util";
import {readAsArrayBuffer} from "./libs/file-util";
import * as SDK from "../../SDK/ViewSDK";

export class DropFileDataProvider {

    player:AudioPlayer;

    connector: SDK.LordfoxConnector;

    constructor(player: AudioPlayer) {
        this.player = player;
        this.connector = window.Application.connector;
        this.player.events['dropped lvp-drop-file'] = (e) => {
            this.initDrop(e);
        }
    }

    // @ts-ignore
    async initDrop(e:Event) {
        // @ts-ignore
        const file = e.file;
        // @ts-ignore
        let fwm = { file: file, id3: getId3(e.data) };
        await this.convertToTrackList(fwm);
        this.player.updateTracks();
    }

    async convertToTrackList(f) {

        const file:File = f.file;
        let id3 = f.id3;
        let trackData:TrackData = {
            title: "",
            artist: "",
            url: "",
            thumb: "",
            durationTime: "",
            local: f
        };
        const fileArrayBuffer = await readAsArrayBuffer(file);

        // @ts-ignore

        this.connector.fileUpload({}, file);

        this.player.setTrackDurationFromContext(fileArrayBuffer, trackData);
        if(id3["APIC"]){
            trackData.thumb = URL.createObjectURL(new Blob([id3["APIC"][0].data]));
            //URL.revokeObjectURL(url);
        } else {
            trackData.thumb = new URL('default-thumb-player.png', import.meta.url).href;
        }
        trackData.title = id3["TIT2"] ?? file.name;
        trackData.artist = id3["TPE1"] ?? "";
        trackData.url = URL.createObjectURL(file);
        this.player.addTrack(trackData);

    }

}