import * as Component from "./Components";
import {Application} from "./Application";
import {TestView} from "./views/TestView";
import {DomHelper} from "../Framework/DomHelper";
import {DynamicEntityEditor} from "./Components";

export class RoutingHandler {
    
    app: Application;
    
    constructor(app: Application) {
        
        this.app = app;
        
    }
    
    handle() {
        
        const app = this.app;

        app.router.route(/^test.html$/, (a,b,c,d) =>
        {
            let v = new TestView();
            app.currentApp = v;
            v.viewProperties.model.set('name', 'Peter');
            DomHelper.html(app.el, '#content', v.el);
            v.update();
        });

        app.router.route(/^view.html$/, (a,b,c,d) =>
        {
            app.setCurrentApp(new TestView());
        });

        app.router.route(/^logout\/$/, (a) =>
        {
            DomHelper.html(app.el, '#content', "");
            let login = new Component.Login();
            login.logout();
            app.setCurrentApp(login);
        });

        app.router.route(/^login\/$/, (a) =>
        {
            app.setCurrentApp(new Component.Login());
        });

        app.router.route(/^lost-password\/$/, (a) =>
        {
            app.setCurrentApp(new Component.LostPassword());
        });

        app.router.route(/^change-password\/$/, (a) =>
        {
            app.setCurrentApp(new Component.ChangePassword());
        });

        app.router.route(/^register\/$/, (a) =>
        {
            app.setCurrentApp(new Component.Registration());
        });

        app.router.route(/^linked-accounts\/$/, (a) =>
        {
            app.setCurrentApp(new Component.LinkedAccounts());
        });

        app.router.route(/^my-account\/$/, (a) =>
        {
            app.setCurrentApp(new Component.MyAccount());
        });

        app.router.route(/^my-orders\/$/, (a) =>
        {
            app.setCurrentApp(new Component.MyOrders());
        });

        app.router.route(/^my-address\/$/, (a) =>
        {
            const o_myAddress = new Component.MyAddress();
            o_myAddress.getById(app.connector.Session.getUser().addressId, function() {
                app.setCurrentApp(o_myAddress);
            });
        });

        app.router.route(/^audio-player\/$/, (a) =>
        {
            const o = new Component.AudioPlayer();
            app.setCurrentApp(o);
        });

        app.router.route(/^paste-board\/$/, (a) =>
        {
            const o = new Component.PasteBoard();
            app.setCurrentApp(o);
        })

        app.router.route(/^drive\/$/, (a) => {
            const o = new Component.UserDrive();
            app.setCurrentApp(o);
        });

        app.router.route(/^brand\/$/, (a) =>
        {
            console.log('#Brand-Route: ');
            console.debug(a);
            const o = new Component.Brand();
            o.getById("291079E1-E9DC-4DE1-A001-F8209D3973B3", () => {
                app.setCurrentApp(o);
            });
        });

        app.router.route(/^editor\/(.*)\/(.*)$/, (a,b,c,d) =>
        {
            let url = a.split('/');
            let entityName = url[1];
            let primaryId = url[2];
            console.debug(url);
            if (DynamicEntityEditor.getEntityClass(entityName)) {
                let v = new DynamicEntityEditor(entityName);
                if (primaryId) {
                    v.getById(primaryId, function() {
                        app.setCurrentApp(v);
                    });
                } else {
                    app.setCurrentApp(v);
                }
            }
        });

        app.router.route(/^view\/(.*)\/(.*)$/, (a,b,c,d) =>
        {
            let v = new TestView();
            v.viewProperties.model.set('name', app.router.getParam('x'));
            app.setCurrentApp(v);
        });

        app.router.route(/^stats\/(.*)\/(.*)$/, (a,b,c,d) =>
        {
            app.connector.request('/Stats/GetStats/', {
                'name': 'LCP'
            }).then((r)=> {
                console.debug(r);
            })
        });

        app.router.route(/^viewer\/$/, (a) =>
        {
            DomHelper.html(app.el, '#content', "");
            document.querySelector('main').style.pointerEvents = 'painted';
        });


        //tn.update();

        setTimeout(function(){

            if (true) {
                app.router.route(/^$/, (a) => {
                        const o = new Component.FrontPage();
                        app.setCurrentApp(o);
                        //document.querySelector('main').style.pointerEvents = 'all';
                    });
            }

            console.log('lb: fire init route delay on initial direct-call');
            app.router.initRoute(app.router.locationBar.fragment, document.querySelector('#content'));
            console.log('trigger');
        },300);

        console.log('routes init done');
        
    }
    
    
    
    
}
