import {getEntityClass} from "../../../Domain/Entities";
import {EntityEditor, IEntity} from "../EntityEditor/EntityEditor";

export class MyAddress extends EntityEditor {

    constructor() {

        super(new (getEntityClass('LvpAddress'))(), []);

        this.on('collect.success', (ident, context) => {
            console.log('entity collected');
            console.debug(context);
        });

        this.on('persist.success', (ident, context) => {
            console.log('entity persist');
        });

    }
}