import LayoutTemplate from "bundle-text:./templates/Messenger.Layout.html";
import UsersListTemplate from "bundle-text:./templates/Messenger.Users.List.html";
import MessagesTemplate from "bundle-text:./templates/Messenger.Messages.html";
import ConversationsListTemplate from "bundle-text:./templates/Messenger.Conversations.List.html";
import ConversationsListItemTemplate from "bundle-text:./templates/Messenger.Conversations.List.Item.html";
import ConversationsListItemLastMessageTemplate from "bundle-text:./templates/Messenger.Conversations.List.Item.LastMessage.html";
import ConversationTemplate from "bundle-text:./templates/Messenger.Conversation.html";
import ConversationInfoTemplate from "bundle-text:./templates/Messenger.Conversation.Info.html";
import ConversationNavTemplate from "bundle-text:./templates/Messenger.Conversation.Nav.html";
import ConversationInputTemplate from "bundle-text:./templates/Messenger.Conversation.Input.html";
import ConversationInputReplayTemplate from "bundle-text:./templates/Messenger.Conversation.Input.ReplayTo.html";
import ConversationMessagesTemplate from "bundle-text:./templates/Messenger.Conversation.Messages.html";
import ConversationMessageTemplate from "bundle-text:./templates/Messenger.Conversation.Message.html";
import ConversationMessageNavTemplate from "bundle-text:./templates/Messenger.Conversation.Message.Nav.html";
import ConversationMessageImageFormatTemplate from "bundle-text:./templates/snippets/Messenger.Conversation.Message.ImageFormat.html";
import GroupCreateTemplate from "bundle-text:./templates/Messenger.Group.Create.html";

export function assignTemplates(view) {

    // templates
    view.engine.add('Messenger.Layout', LayoutTemplate);
    view.engine.add('Messenger.Users.List', UsersListTemplate);
    view.engine.add('Messenger.Messages', MessagesTemplate);
    view.engine.add('Messenger.Conversations.List', ConversationsListTemplate);
    view.engine.add('Messenger.Conversations.List.Item', ConversationsListItemTemplate);
    view.engine.add('Messenger.Conversations.List.Item.LastMessage', ConversationsListItemLastMessageTemplate);
    view.engine.add('Messenger.Conversation', ConversationTemplate);
    view.engine.add('Messenger.Conversation.Info', ConversationInfoTemplate);
    view.engine.add('Messenger.Conversation.Nav', ConversationNavTemplate);
    view.engine.add('Messenger.Conversation.Input', ConversationInputTemplate);
    view.engine.add('Messenger.Conversation.Input.ReplayTo', ConversationInputReplayTemplate);
    view.engine.add('Messenger.Conversation.Messages', ConversationMessagesTemplate);
    view.engine.add('Messenger.Conversation.Message', ConversationMessageTemplate);
    view.engine.add('Messenger.Conversation.Message.Nav', ConversationMessageNavTemplate);
    view.engine.add('Messenger.Conversation.Message.ImageFormat', ConversationMessageImageFormatTemplate);
    view.engine.add('Messenger.Group.Create', GroupCreateTemplate);

}