//import {View, EventTable} from "../Framework/View";
import {TestView} from "./views/TestView";

import {Router} from '../Framework/Router';
import {DomHelper} from '../Framework/DomHelper';

import * as Component from "./Components";
import * as LVP from "./SDK/ViewSDK";

import {Bootloader} from "./Bootloader"
import {RoutingHandler} from "./Routing"
import {registerCustomElements} from "./WebComponents"

import Hammer from "hammerjs";
import {AudioPlayer, Messenger, PasteBoard, SideBar, SubSideBar} from "./Components";

/*
import { initializeApp } from "firebase/app";

import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const firebaseSDK = {
    init: initializeApp,
    getMessaging: getMessaging,
    getToken: getToken,
    getAnalytics: getAnalytics
};
*/

import {fadeIn, fadeOut, defaultFadeConfig} from "../Framework/Utils/fade";

const firebaseSDK:any = window.firebaseSDK;

declare var LVPX:any;


export class Application extends LVP.View {

    tagName:string = "body";

    router: Router;

    connector: LVP.LordfoxConnector;

    cache: any = {

    };

    currentApp: LVP.View;

    appElement: any

    doc: any

    views:object={};

    viewer: Component.Viewer;

    hammer: Hammer;

    AudioPlayer: AudioPlayer;

    Messenger: Messenger;


    events: LVP.EventTable = {

        "*click a":(e) => {
            e.preventDefault();
            const that = this;
            let elem;
            if (e.target.tagName !== 'A') {
                elem = e.target.closest('a');
            } else {
                elem = e.target;
            }
            console.debug(elem);
            let url = elem.attributes.href.value;
            //let url = $(e.currentTarget).attr('href');
            that.trigger('navigate', {
                event: e,
                url: url,
                router: that.router,
                app: that
            });

            if (url !== "/" && url !== '#') {

                //let url = $(e.currentTarget).attr('href');
                //let dataContainer = $(e.currentTarget).data('container');
                let dataContainer = e.target.dataset.container;
                let container = document.querySelector('#content');
                that.router.initRoute(url, container);
                /**
                 let $container = $("#content");
                 if (typeof dataContainer !== 'undefined') {
                    $container = $(dataContainer);
                }
                 s.router.initRoute(url, $container);
                 */


            } else {
                let container = document.querySelector('#content');
                that.router.initRoute(url, container);
                //that.router.locationBar.update("/");
            }
        },

        "*submit form":(e) => {
            const that=this;
            e.preventDefault();
            //console.log('stopped form submission');
        },

        "click .btn": function () {
            console.log('pressed');
        }


    }

    firebase:any;

    constructor(targetElementSelector:string = 'main') {

        super();
        this.el = document.createElement(this.tagName);
        this.engine.add('app.layout', LVP.ApplicationLayoutTemplate);


        this.initRouter();
        registerCustomElements();

        this.hammer = Hammer;

    }

    addOverlayView(name:string, view:LVP.View, targetSelector:string, options:any) {

        const that=this;

        let content = this.el.querySelector(targetSelector);

        fadeOut(content, 300).then(function() {

            that.currentApp = view;

            that.currentApp.on('update.done', () => {
                //console.log('bindEvents ...');
                that.bindEvents(that.events);
            });

            DomHelper.html(that.el, targetSelector, that.currentApp.el);
            that.currentApp.update();
            document.querySelector('main').style.pointerEvents = 'all';
            fadeIn(content, 300);
        });

    }

    setCurrentApp(app?:LVP.View) {
        const that=this;

        let content = this.el.querySelector('#content');

        fadeOut(content, 300).then(function() {
            that.currentApp = app;
            that.currentApp.on('update.done', () => {
                //console.log('bindEvents ...');
                that.bindEvents(that.events);

            });
            DomHelper.html(that.el, '#content', that.currentApp.el);
            that.currentApp.update();
            document.querySelector('main').style.pointerEvents = 'all';
            fadeIn(content, 300);
        });


    }

    initCoreEvents() {

    }

    updateViews() {
        console.log('updating views ...');
        const that = this;
        Object.values(that.views).forEach(view => {
            view.update();
        });
        this.currentApp.update();
    }

    initRoutes() {
        const handler = new RoutingHandler(this);
        handler.handle();
    }

    initViewer() {

        window.OSBFactory = Component.Viewer;

        this.viewer = new Component.Viewer('OSBViewer2', {
            fitContainer: true
        });

    }

    toggleViewerAnimation() {
        this.viewer.CameraManager.camera.presenterAnimation=true;
    }

    initServiceWorker() {

        const that = this;

        this.registerServiceWorker(function(registration){

            /**
             * Google Firebase integration
             *


            let vapidKey2 = "BCU5Ghj-6EkbWH5EKB76rY4fqn0H-hyQ3d1GJU6Vt6hbXgqko-E_-IG0ejlLopgRVfBevp0cVXqeRKy8FeYmy_Q";

            const firebaseConfig2 = {
                "apiKey": "AIzaSyDml62-md3lOA64LxmGgjPWJewjym7-XME",
                "authDomain": "lordfox-4c297.firebaseapp.com",
                "projectId": "lordfox-4c297",
                "storageBucket": "lordfox-4c297.appspot.com",
                "messagingSenderId": "671358992712",
                "appId": "1:671358992712:web:76b5a878d4cb7b122f337d",
                "measurementId": "G-YL4R3VJXFL"
            }

            const vapidKey = "BJ9HgG_LgJZwyJT5b3K8nFvPKLOz1MNreHmtNSTsHVDpAmJ8mg5jqxZZmoq7Bedl9VibkbM-EF8d3WtLQ_kzm6E";

            const firebaseConfig = {
                apiKey: "AIzaSyDDQfk9jleImbkDhe7J9jwA-nkgsyX7gs4",
                authDomain: "lordfox-web.firebaseapp.com",
                projectId: "lordfox-web",
                storageBucket: "lordfox-web.appspot.com",
                messagingSenderId: "432523864993",
                appId: "1:432523864993:web:75b8cd92daa79cf45ab9e0",
                measurementId: "G-CV8P3W1B9T"
            };



            let firebaseApp = firebaseSDK.init(firebaseConfig);

            that.firebase = {
                app: firebaseApp,
                messaging: firebaseSDK.getMessaging()
            }

            firebaseSDK.getToken(that.firebase.messaging, {
                serviceWorkerRegistration: registration,
                vapidKey: vapidKey
            }).then((currentToken) => {

                if (currentToken) {
                    // @todo: Send the token to your server and update the UI if necessary
                    console.log('FIREBASE TOKEN START');
                    console.debug(currentToken);
                    console.log('FIREBASE TOKEN END!');
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }

            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err)
            });

            /**
             * Firebase messaging - App foreground Payload handler

            that.firebase.messaging.onMessage = function(mes, payload) {
                console.log('Message received. ', payload);
                // ...
            };
        */
        });


    }

    registerServiceWorker(onRegistration) {
        /**
         * PWA Support
         *
         *  - register the service-worker
         */

        if ('serviceWorker' in navigator) {
            // Register the ServiceWorker
            navigator.serviceWorker.register(new URL('../service-worker.js', import.meta.url), {
                scope: '.',
                type: 'module'
            }).then(function(registration) {
                console.log('The service worker has been registered ', registration);
                onRegistration(registration);


                // Feature detection: Background Sync API
                if ('sync' in registration) {

                    // In the real projects, the code line below is placed within some event like submitting the form, i.e.
                    // right before the moment where we would send the data to a remote server. And if the 'sync' was registered
                    // successfully, we do not do a request here anymore but save the data we were going to send. The service
                    // worker will get this data and send it to the destination (and we have to write this code also).
                    // @ts-ignore
                    registration.sync.register('SYNC-NAME');
                }


            });

            // Listen for claiming of our ServiceWorker
            navigator.serviceWorker.addEventListener('controllerchange', function(event) {
                console.log(
                    '[controllerchange] A "controllerchange" event has happened ' +
                    'within navigator.serviceWorker: ', event
                );

                // Listen for changes in the state of our ServiceWorker
                navigator.serviceWorker.controller.addEventListener('statechange',
                    function() {
                        console.log('[controllerchange][statechange] ' +
                            'A "statechange" has occured: ', this.state
                        );

                        // If the ServiceWorker becomes "activated", let the user know they can go offline!
                        if (this.state === 'activated') {
                            // Show the "You may now use offline" notification
                            // document.getElementById('offlineNotification')
                            //   .classList.remove('hidden');
                        }
                    }
                );
            });
        }
    }

    initViews() {
        const that = this;

        const audioPlayer = new Component.AudioPlayer();
        this.AudioPlayer = audioPlayer;
        this.addOverlayView('AudioPlayer', audioPlayer, '#overlay-content', {});
        this.AudioPlayer.initMediaSessionHandler();

        const messenger = new Component.Messenger();
        this.Messenger = messenger;
        this.addOverlayView('Messenger', messenger, '#overlay-messenger', {});



        let tn = new Component.TopNavigation();
        this.views['TopNavigation'] = tn;
        that.el.querySelector('topnav').replaceWith(tn.el);
        //DomHelper.html(that.el, '.lvp-app-nav', tn.el);
        tn.update();

        let sb = new Component.SideBar();
        this.views['SideBar'] = sb;
        that.el.querySelector('sidebar').replaceWith(sb.el);
        //DomHelper.html(that.el, 'sidebar', sb.el);
        sb.update();

        let ssb = new Component.SubSideBar();
        this.views['SubSideBar'] = ssb;
        that.el.querySelector('subsidebar').replaceWith(ssb.el);
        ssb.update();

        this.bindEvents(this.events);

        this.on('lvp.user.logout.success', ()=> {
            console.log('logout.success evt');
            that.updateViews();
            that.currentApp.update();
            //that.bindEvents(that.events);
        });

        this.on('lvp.user.login.success', ()=> {
            that.updateViews();
            //that.bindEvents(that.events);
        });

    }

    initConnector() {
        this.connector = new LVP.LordfoxConnector();
    }

    initRouter() {
        this.router = new Router({
            Router: {
                LocationBar: {
                    pushState: true,
                    hashChange: true,
                    root: '/',
                    silent: false,
                    location: window.location,
                    history: window.history
                }
            },
            Ajax: {
                ignoreKeyElements: "main, .acp-important, #content, .navbar, .lvp-sidebar, #overlay-content"
            },
            Debugging: {
                state: true,
                style:{
                    "default": "color:red;"
                }
            }
        });
    }

    initBootLoader(cb) {
        const loader = new Bootloader();
        loader.on('success', (type, context:any)=>{
            cb();
        });
        loader.preload();
    }

    render() {
        this.el.innerHTML = this.engine.render('app.layout', {});
    }

    /**
     * @see https://pwadvent.dev/day/7/
     * @see https://storage.spec.whatwg.org/#example-3a7051a8
     */

    async storagePersist() {
        // resolve all promises
        Promise.all([
            // check if persisted
            navigator.storage.persisted(),
            // request permission
            navigator.permissions.query({name: "persistent-storage"})
        ]).then(([persisted, permission]) => {
            // permission granted
            if (!persisted && permission.state == "granted") {
                // persist storage
                navigator.storage.persist().then( /* … */ );
                // prompt user for permissions
            } else if (!persisted && permission.state == "prompt") {
                // show explanation why and for what is used
                //showPersistentStorageExplanation();
            }})
    }

    async requestClipboardRead()
    {
        const permissionStatus = await navigator.permissions.query({
            // @ts-ignore
            name: "clipboard-read",
            allowWithoutGesture: false
        });
        // Will be 'granted', 'denied' or 'prompt':
        console.log(permissionStatus.state);
        // Listen for changes to the permission state
        permissionStatus.onchange = () => {
            console.log(permissionStatus.state);
        };
    }

    async userStorageQuota() {
        //let {quota, usage, usageDetails} =  await navigator.storage.estimate();
    }

    /**
     * @see https://developer.chrome.com/articles/web-share-target/#accepting-files
     * @see https://pwadvent.dev/day/2/
     */

    shareToApp() {

        if (navigator.share) {
            navigator.share({
                title: 'PWAdvent',
                text: 'Check out PWAdvent.dev, the perfect advent calendar for everyone who\'s excited about the web platform',
                url: 'https://pwadvent.dev/',
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }

    }

    /**
     * @see: https://web.dev/customize-install/
     * @see: https://pwadvent.dev/day/1/
     */
    installPrompt() {

        let installPrompt;

        const button:HTMLElement = document.querySelector('#installButton');
            // hide the button initially
        button.style.display = "none";

        window.addEventListener('beforeinstallprompt', e => {
            e.preventDefault();
            // show the button if it makes sense
            button.style.display = "block";
            installPrompt = e;
        });

        button.addEventListener('click', () => {
            if (!installPrompt ) {
                // The deferred prompt isn't available.
                return;
            }

            // Show install prompt.
            installPrompt.prompt();

            // Log the result
            installPrompt.userChoice.then((result) => {
                console.log('userChoice', result);
                // Reset the deferred prompt variable, since prompt() can only be called once.
                installPrompt = null;
                // Hide install button.
                button.style.display = "none";
            });
        });


    }

    resize() {
        this.trigger('resize', {});
    }

}