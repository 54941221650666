import * as SDK from "../../SDK/ViewSDK";
import Template from "bundle-text:./UserDrive.Layout.html";
import {uuidv4} from "@firebase/util";
import {GenUUID} from "../../../Framework/Generator";

export class UserDrive extends SDK.View {

    session: SDK.SessionHandler;

    connector: SDK.LordfoxConnector;

    tapStartTime: number;

    tapStartEntryKey: number = null;

    eventsLocked: boolean = false;

    isScrolling: boolean = false;

    lastScrollTime: number = 0;

    selectionTimeout : any = {};

    events: SDK.EventTable = {

        'contextmenu .dir-entry': (e) => {
            e.preventDefault();
        },

        'mousedown .entry-content': (e) =>
        {
            if (!this.eventsLocked && e.buttons === 1) {

                //await this.touchEntry(e);
                this.dblTouchEntry(e).then();
            }
        },

        'dblclick .entry-content': (e) =>
        {
            if (!this.eventsLocked) {
                this.dblTouchEntry(e).then();
            }
        },

        'mousedown .entry-navigation': (e) => {
            if (!this.eventsLocked) {
                if (e.buttons === 1) {
                    if (!e.currentTarget.classList.contains('entry-nav')) {
                        this.toggleSelectElement(e.currentTarget);
                    }
                } else {
                    e.preventDefault();
                }

            }
        },

        'touchstart .entry-navigation': (e) => {
            if (!this.eventsLocked) {
                if (!e.currentTarget.classList.contains('entry-nav')) {
                    this.toggleSelectElement(e.currentTarget);
                }
            }
        },

        'touchstart .entry-content': (e) => {
            if (!this.eventsLocked) {
                const that = this;
                let element = e.currentTarget;
                let key = element.closest('.dir-entry').dataset.key;

                this.selectionTimeout[key] = {
                    fired: false,
                    startTime: new Date().getTime(),
                    timeout: setTimeout(()=> {
                        that.selectionTimeout[key].fired = true;
                        if (!that.eventsLocked) {
                            //console.log(that.selectionTimeout[key].startTime - that.lastScrollTime);
                            if (that.selectionTimeout[key].startTime - that.lastScrollTime > 0) {
                                //console.log('fire timeout');
                                that.toggleSelectElement(element);
                            }
                        }
                    }, 1000)
                }
                /*
                this.one('scrolling', ()=> {
                    console.log('disable timeout '+key);
                    clearTimeout(that.selectionTimeout[key].timeout);
                    that.selectionTimeout[key].fired = true;
                });
                 */


            }
        },

        'touchend .entry-content': (e) => {
            if (!this.eventsLocked) {
                //let currentTime = new Date().getTime();
                let key = e.currentTarget.closest('.dir-entry').dataset.key;
                if (this.selectionTimeout[key] !== 'undefined') {
                    if (this.selectionTimeout[key].fired) {
                        clearTimeout(this.selectionTimeout[key].timeout);
                        delete this.selectionTimeout[key];
                    } else {
                        clearTimeout(this.selectionTimeout[key].timeout);
                        delete this.selectionTimeout[key];
                        if (!this.eventsLocked) {
                            this.dblTouchEntry(e).then();
                        }
                    }
                }
            }
        },

        'click .dir-add': (e) =>
        {
            if (!this.eventsLocked) {
                let input : HTMLInputElement =  this.el.querySelector('.new-dir-name');

                this.addDir(input.value).then();
            }
        },

        'click .entry-del': (e) =>
        {
            if (!this.eventsLocked) {
                e.preventDefault();
                let key = e.currentTarget.closest('.dir-entry').dataset.key;
                let entry = this.data.entries[key];
                this.delEntry(entry).then();
            }
        },

        'click .entry-video-play': (e) =>
        {
            if (!this.eventsLocked) {
                e.preventDefault();
                let key = e.currentTarget.closest('.dir-entry').dataset.key;
                let entry = this.data.entries[key];
                let link = this.getDirectLink(entry, false, 'direct');
                window.open(link);
            }
        },

        'click .selection-del': (e) =>
        {
            const that = this;
            if (!this.eventsLocked) {
                e.preventDefault();
                this.data.selectedKeys.forEach((key) => {
                    let entry = that.data.entries[key];
                    that.delEntry(entry).then(()=> {
                        let index = that.data.selectedKeys.indexOf(key);
                        that.data.selectedKeys.splice(index, 1);
                        if (that.data.selectedKeys.length === 0) {
                            that.update();
                        }
                    });
                });
            }
        },

        'scroll .drive-container': (e) => {
            e.preventDefault();
            this.eventsLocked = true;
            this.lastScrollTime = new Date().getTime();
            //const event = new CustomEvent('scrolling', {bubbles: true, cancelable: true});
            //this.el.dispatchEvent(event);
            this.trigger('scrolling', {});
        },

        'scrollend .drive-container': (e) => {
            e.preventDefault();
            this.eventsLocked = false;
            this.lastScrollTime = new Date().getTime();
            this.loadImagesLazily();
            this.trigger('scrolling.end', {});
        }

    }

    loadImagesLazily() {
        let images = this.el.querySelectorAll("img[data-src]");
        for (let i = 0; i < images.length; i++) {
            let rect = images[i].getBoundingClientRect();
            if (images[i].hasAttribute("data-src")
                && rect.bottom > 0 && rect.top < window.innerHeight
                && rect.right > 0 && rect.left < window.innerWidth) {
                images[i].classList.remove('hide');
                images[i].setAttribute("src", images[i].getAttribute("data-src"));
                images[i].removeAttribute("data-src");
            } else {
                images[i].classList.add('hide');
            }
        }
    }

    updateSelectedCount() {
        this.el.querySelector('.entry-selected-count').innerHTML = this.data.selectedKeys.length;
        this.el.querySelector('.drive-selection').setAttribute('data-count', this.data.selectedKeys.length);
    }

    toggleSelectElement(element) {
        if (!element.classList.contains('dir-entry')) {
            element = element.closest('.dir-entry');
        }
        let key = element.dataset.key;
        let entry = this.data.entries[key];
        if (!entry.isDot) {
            if (this.data.selectedKeys.indexOf(key)>=0) {
                const index = this.data.selectedKeys.indexOf(key);
                const x = this.data.selectedKeys.splice(index, 1);
                element.classList.remove('selected');
                this.updateSelectedCount();
            } else {
                this.data.selectedKeys.push(key);
                element.classList.add('selected');
                this.updateSelectedCount();
            }
        }
    }

    async executeEntry(entry) {
        if (entry.isDot) {
            if (entry.fileName === "..") {
                let paths = this.states.dir.split("/");
                paths.pop();
                let backPath = paths.join("/");
                if (backPath === "") backPath="/";
                this.states.dir = backPath;
                await this.readDir();
            }
        } else if (entry.isDir) {
            this.states.dir = entry.pathName;
            await this.readDir();
        } else {

        }
    }

    async touchEntry(e) {
        let element = e.currentTarget;
        this.toggleSelectElement(element)
    }

    async dblTouchEntry(e) {
        let element = e.currentTarget;
        if (!element.classList.contains('dir-entry')) {
            element = e.currentTarget.closest('.dir-entry');
        }
        let key = element.dataset.key;
        let entry = this.data.entries[key];
        await this.executeEntry(entry);
    }

    async delEntry(entry:any) {
        let res = await this.connector.request('/DriveDirectory/DelEntry/', {
            'entryPath': entry.pathName,
        }, 'POST');
        await this.readDir()
    }

    states: any = {
        dir: "/"
    }

    state: SDK.Model

    data: any = {
        entries: [],
        directory: '',
        selectedKeys: []
    }

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.engine.add('lvp.app.layout', SDK.AppLayoutTemplate);
        this.engine.add('UserDrive.Layout', Template);
        //document.addEventListener('paste', async (e) => {});
        this.state = new SDK.Model({
            'selectedEntries': []
        });
        const that = this;

        if (this.state.on('change', ()=> {
            that.update();
        }))

        this.readDir().then((r)=> {

        });
    }

    getDirectLink(entry, simple = false, forType: string = 'image')
    {
        if ((entry.mimeType.startsWith('image') && forType === 'image') || forType === 'direct') {
            if (simple) {
                return "connect.lordfox.de/stream?file="+entry.pathName;
            } else {
                return "https://www.connect.lordfox.de/stream?file="+entry.pathName;
            }
        } else {
            if (simple) {
                return "connect.lordfox.de/templates/lvp_lordfox-front-base/img/Lordfox_Sign_512x512_rounded.png";
            } else {
                return "https://www.connect.lordfox.de/templates/lvp_lordfox-front-base/img/Lordfox_Sign_512x512_rounded.png";
            }
        }

    }

    getDirectLink2(entry, simple = false)
    {
        if (simple) {
            return "connect.lordfox.de/stream?userToken="+this.connector.Session.getUserToken()+"&file="+entry.pathName;
        } else {
            return "https://www.connect.lordfox.de/stream?userToken="+this.connector.Session.getUserToken()+"&file="+entry.pathName;
        }

    }

    async addDir(name : string) {
        const that = this;
        let res = await this.connector.request('/DriveDirectory/AddDir/', {
            'dir': that.states.dir,
            'name': name
        }, 'POST');
        await this.readDir()
    }

    async readDir() {
        const that = this;
        let res = await this.connector.request('/DriveDirectory/Iterate/', {
            'dir': that.states.dir
        }, 'POST');
        this.data.entries = res.content.entries;
        this.data.directory = res.content.directory;
        this.states.currentDirectory = res.content.directory;
        this.update();
    }

    render() {
        const data = this.data;
        this.renderEl('UserDrive.Layout', {
            'entries': data.entries,
            'directory': data.directory,
            'selectedKeys': data.selectedKeys,
            'view': this
        });
        this.loadImagesLazily();
    }
}