import {ConversationDatabase, MessageDatabase, UserDatabase} from "./Database";
import {User} from "./interfaces/User";
import {v4 as uuidv4} from 'uuid';

export class DummyDataFactory {

    public constructor() {}

    public createUsers() {
        const db = new UserDatabase();
        db.transaction('rw', db.users, async()=> {

            if ((await db.users.where({userName: "Lordfox"}).count()) === 0) {
                const dummyUser1:User = {
                    id: "1",
                    userName: "Lordfox",
                    userImage: null,
                    status: 0,
                    statusText: ""
                }
                const id1 = await db.users.add(dummyUser1);
            }
            if ((await db.users.where({userName: "Nilius"}).count()) === 0) {
                const dummyUser2:User = {
                    id: "2",
                    userName: "Nilius",
                    userImage: null,
                    status: 0,
                    statusText: ""
                }
                const id2 = await db.users.add(dummyUser2);
            }

            if ((await db.users.where({userName: "NeoMo"}).count()) === 0) {
                const dummyUser2:User = {
                    id: "97",
                    userName: "NeoMo",
                    userImage: null,
                    status: 0,
                    statusText: ""
                }
                const id2 = await db.users.add(dummyUser2);
            }
        });

    }

    public createConversations() {

        const db = new ConversationDatabase();
        db.transaction('rw', db.conversations, async() => {

            if ((await db.conversations.where({title: "Neomoni"}).count()) === 0) {
                await db.conversations.add({
                    id: uuidv4(),
                    title: "Neomoni",
                    icon: "",
                    image: "",
                    description: "",
                    type: "group",
                    community: null,
                    admins: ["1"],
                    users: ["1", "97"],
                    createdAt: Date.now(),
                    lastMessageAt: null,
                    lastMessageId: null,
                    isPinned: 1,
                    readOnly: 0
                });
            }
            /*
                        if ((await db.conversations.where({title: "#USG Saloon"}).count()) === 0) {
                            await db.conversations.add({
                                id: "1223",
                                title: "#USG Saloon",
                                icon: "",
                                image: "",
                                description: "",
                                type: "group",
                                community: null,
                                admins: ["1"],
                                users: ["1", "2"],
                                createdAt: Date.now()-10,
                                lastMessageAt: null,
                                lastMessageId: null,
                                isPinned: 1,
                                readOnly: 0
                            });
                        }

                        if ((await db.conversations.where({title: "#USG Stammtisch"}).count()) === 0) {
                            await db.conversations.add({
                                id: "1224",
                                title: "#USG Stammtisch",
                                icon: "",
                                image: "",
                                description: "",
                                type: "group",
                                community: null,
                                admins: ["1"],
                                users: ["1", "2"],
                                createdAt: Date.now()-10,
                                lastMessageAt: null,
                                lastMessageId: null,
                                isPinned: 1,
                                readOnly: 0
                            });
                        }

             */

        });
    }

    public createMessages() {

        const db = new MessageDatabase();
        db.transaction('rw', db.messages, async() => {

            if ((await db.messages.where({id: "m1"}).count()) === 0) {
                await db.messages.add({
                    id: "m1",
                    conversation: "1223",
                    replayTo: null,
                    authorId: "1",
                    content: "Hallo",
                    contentType: "text",
                    createdAt: Date.now(),
                    mediaAttachments: null,
                    readers:[],
                    recieved:[]
                });
            }

            if ((await db.messages.where({id: "m2"}).count()) === 0) {
                await db.messages.add({
                    id: "m2",
                    conversation: "1223",
                    replayTo: "m1",
                    authorId: "2",
                    content: "Moin",
                    contentType: "text",
                    createdAt: Date.now()+2,
                    mediaAttachments: null,
                    readers:[],
                    recieved:[]
                });
            }

            if ((await db.messages.where({id: "m3"}).count()) === 0) {
                await db.messages.add({
                    id: "m3",
                    conversation: "1223",
                    replayTo: "m1",
                    authorId: "2",
                    content: "Das ist mal ein recht langer Text der eventuell Probleme bereiten könnte",
                    contentType: "text",
                    createdAt: Date.now()+2,
                    mediaAttachments: null,
                    readers:[],
                    recieved:[]
                });


            }

            if ((await db.messages.where({id: "m4"}).count()) === 0) {
                await db.messages.add({
                    id: "m4",
                    conversation: "1223",
                    replayTo: "m1",
                    authorId: "2",
                    content: "Das ist mal ein recht langer Text der eventuell Probleme bereiten könnte. Das ist mal ein recht langer Text der eventuell Probleme bereiten könnte. Das ist mal ein recht langer Text der eventuell Probleme bereiten könnte.Das ist mal ein recht langer Text der eventuell Probleme bereiten könnte. Das ist mal ein recht langer Text der eventuell Probleme bereiten könnte.",
                    contentType: "text",
                    createdAt: Date.now()+2,
                    mediaAttachments: null,
                    readers:[],
                    recieved:[]
                });
            }

        });
    }

    public create() {
        this.createUsers();
        this.createConversations();
        //this.createMessages();
    }

}