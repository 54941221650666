import {BaseManager} from "./BaseManager";
import {IManager} from "./IManager";

export class LightManager extends BaseManager implements IManager {

    lightsRegister={};

    async init() {
        this.addLight('HemisphericLight1', await this.importLightByName('HemisphericLight1'));
        this.addLight('sunLight', await this.importLightByName('DirectionalLight1'));
    }

    clearLightRegister() {
        delete this.lightsRegister;
        this.lightsRegister = {};
    }

    hasLight(lightUnique: string) {
        return (this.lightsRegister.hasOwnProperty(lightUnique));
    }

    addLight(lightUnique: string, light) {
        if (!this.lightsRegister.hasOwnProperty(lightUnique)) {
            this.lightsRegister[lightUnique] = light;
        }
    }

    getLight(lightUnique: string)
    {
        if (this.hasLight(lightUnique)) {
            return this.lightsRegister[lightUnique];
        } else {
            return null;
        }
    }

    delLight(lightUnique) {
        if (this.hasLight(lightUnique)) {
            delete this.lightsRegister[lightUnique];
        }
    }

    async importLightByName(name) {
        return await this.importByName(name, 'Lights');
    }

}