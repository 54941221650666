import {Engine, WebGPUEngine, AssetsManager, ShadowGenerator} from "babylonjs";
import * as Manager from './Manager';
import {ChatBubble} from './GUI/ChatBubble';
//import Speech from "speak-tts";

import {InputHandler} from "./InputHandler";
import {PickingInfo} from "babylonjs";
import {LoadingScreen} from "./LoadingScreen";
import {Events} from "../../../Framework/Events";

declare var LVPX:any;

export interface IViewer {

    canvas: HTMLCanvasElement;

    viewerElement: HTMLElement;

    // WebGPU
    //engine: any;
    engine: Engine;

    AssetsManager;
    MeshManager: Manager.MeshManager;
    ActionManager: Manager.ActionManager;
    SceneManager: Manager.SceneManager;
    CameraManager: Manager.CameraManager;
    CharacterManager: Manager.CharacterManager;
    LightManager: Manager.LightManager;
    GroundManager: Manager.GroundManager;

    InputHandler;

    PickInfo: PickingInfo;
    PickMesh:any;

    isReady():boolean;

    loop():void;

    sceneFactory();

    managerFactory();

    documentFactory();

    engineFactory();

    resize();

}

interface IViewerOptions {
    fitContainer?: boolean;
    width?: number;
    height?: number;
}

export class Viewer extends Events {

    options: IViewerOptions = null;

    canvas: HTMLCanvasElement;

    viewerElement: HTMLElement;

    // WebGPU
    //engine: any;
    engine: Engine;

    AssetsManager: AssetsManager;
    MeshManager: Manager.MeshManager;
    ActionManager: Manager.ActionManager;
    SceneManager: Manager.SceneManager;
    CameraManager: Manager.CameraManager;
    CharacterManager: Manager.CharacterManager;
    LightManager: Manager.LightManager;
    GroundManager: Manager.GroundManager;

    InputHandler: InputHandler;

    PickInfo: PickingInfo;
    PickMesh:any;

    constructor(elementId: string = 'OSBViewer', options: IViewerOptions = null) {
        super();

        if (options != null) {
            this.options = options;
        } else {
            this.options = {
                fitContainer: true
            }
        }


        let s = this;

        // WebGPU
        /*
        this.documentFactory(elementId);
        this.engineFactory().then(() => {
            this.managerFactory();
            this.sceneFactory().then(() => {
                s.loop();
            });
        });
         */
        this.documentFactory(elementId);
        this.engineFactory();
        this.managerFactory();
        this.sceneFactory().then(() => {
            s.trigger('scene.ready', {});
            s.loop();
        });

    }

    isReady() {
        return (this.SceneManager.scene.activeCamera);
    }

    loop() {
        let s = this;
        s.engine.runRenderLoop(function () {
            if (s.isReady()) {
                s.SceneManager.scene.render();
            }
        });
    }

    async sceneFactory() {
        let s = this;
        let currentScene = await this.SceneManager.init();
    }

    managerFactory() {
        this.MeshManager = new Manager.MeshManager(this);
        this.ActionManager = new Manager.ActionManager(this);
        this.SceneManager = new Manager.SceneManager(this);
        this.CameraManager = new Manager.CameraManager(this);
        this.CharacterManager = new Manager.CharacterManager(this);
        this.LightManager = new Manager.LightManager(this);
        this.GroundManager = new Manager.GroundManager(this);
        this.InputHandler = new InputHandler(this);

    }

    documentFactory(elementId: string = 'OSBViewer') {
        // @ts-ignore
        this.viewerElement = document.getElementById(elementId);
        this.canvas = document.createElement('canvas');
        this.canvas.setAttribute('id', 'viewer');

        if (this.options.width) {
            this.canvas.width = this.options.width;
        }
        if(this.options.height) {
            this.canvas.height = this.options.height;
        }

        if (this.options.fitContainer) {
            const vel = this.viewerElement
            const rect = vel.getBoundingClientRect();
            this.canvas.width = rect.width;
            this.canvas.height = rect.height;
        }


        this.viewerElement.append(this.canvas);
    }

    // WebGPU
    //await engineFactory() {
    engineFactory() {

        // WebGPU
        /*
        this.engine = new BABYLON.WebGPUEngine(this.canvas);
        await this.engine.initAsync();
        */

        this.engine = new Engine(this.canvas, false, {
            preserveDrawingBuffer: true,
            stencil: true,
        }, false);

        //this.engine.setHardwareScalingLevel(2 / window.devicePixelRatio);
        //this.engine.adaptToDeviceRatio = false;

        this.engine.loadingScreen = new LoadingScreen("I'm loading");
        this.engine.displayLoadingUI();

        let caps = this.engine.getCaps();

        console.log ( "MaxVertexAttribs: "+ caps.maxVertexAttribs);
        let that = this;
        this.resize();

        window.Application.on('resize', function() {
            that.resize();
        });

        window.addEventListener('resize', function() {
            that.resize();
        }, false);
    }

    resize() {
        console.log('BABY RESIZE!');
        if (this.options.fitContainer) {
            const vel = this.viewerElement
            const rect = vel.getBoundingClientRect();
            this.canvas.width = rect.width;
            this.canvas.height = rect.height;
        }
        //this.canvas.width = ;
        //this.canvas.height = this.window.innerHeight;
        if (this.engine) {
            this.engine.resize();
        }
    }

    test(text?:string) {
        let bubble = new ChatBubble(text, this.CharacterManager.character);
    }
}