import {Events} from "./Events";

export interface LoadDataSet {
    [key: string]:LoadData
}

export interface LoadData {
    isInit?:boolean;
    init(data: LoadData):any;
    done?:boolean;
    success?:Function;
    name?:string;
}

export class PreLoader extends Events {

    data:LoadDataSet={}

    maxLoads:number=3

    loads:number=0

    interval:any;

    loading:boolean=false

    success(data:LoadDataSet) {
        this.trigger('success', {
            data: data
        });
    }

    add(name:string, data:LoadData) {
        data.done = false;
        data.name = name;
        this.data[name] = data;
    }

    isLoading() {
        let self = this;

        let isLoading = false;

        let running = 0;

        for (const [name, data] of Object.entries(this.data)) {

            if (!data.isInit && self.isLoadAllowed()) {
                self.loads++;
                data.isInit = true;
                data.init(data);
            }

            if (data.isInit && !data.done) {
                isLoading = true;
                running++;
                if (running === self.maxLoads) {
                    return false;
                }
            } else {
                if (typeof data.success === 'function') {
                    data.success(data);
                }
                this.trigger('unit.success', {data:data});

                self.loads--;
                delete self.data[name];
            }

        }

        return isLoading;
    }

    isLoadAllowed() {
        return (this.maxLoads > this.loads);
    }

    check() {
        let self = this;
        if (!self.isLoading()) {
            clearInterval(self.interval);
            self.loading = false;
            if (typeof self.success === 'function') {
                self.success(self.data);
            }
        }
    }

    preload() {
        let self = this;
        self.loading = true;

        for (const [name, data] of Object.entries(this.data)) {

            if (typeof data.init === 'function') {
                if (self.isLoadAllowed()) {
                    self.loads++;
                    data.isInit = true;
                    data.init(data);

                } else {
                    data.isInit = false;
                }
            }

        }

        self.interval = setInterval(function(){ self.check() }, 100);
    }


}