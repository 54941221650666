import {Model, ModelAttributes, IModel} from "./Model";
import {IModelCollection} from "./ModelCollection";
import * as LVP from "../Application/SDK/ViewSDK";

export interface IEntity extends IModel {

    entityName: string;

    entityIdField: string;

    getEntityName():string;

    getPrimaryIdField():string;

    getId():any;
}

export class Entity extends Model implements IEntity {

    entityName: string;

    entityIdField: string;

    constructor(attributes:ModelAttributes, defaultAttributes?:ModelAttributes, collection?:IModelCollection) {
        super(attributes, defaultAttributes, collection);
    }

    getEntityName() {
        return this.entityName;
    }

    getPrimaryIdField() {
        return this.entityIdField;
    }

    getId() {
        return this.get(this.getPrimaryIdField());
    }

}