import {Events} from "./Events";

export type ConfigObject = {
    page:number,
    pagebreak:number,
    items:number,
    lastpage:number
}

export const DefaultConfig:ConfigObject = {
    page : 1,
    pagebreak : 10,
    items : 0,
    lastpage : 1
}

export interface IPager {

    config:ConfigObject;

    defaultConfig:ConfigObject;

    calculateLastPage():void;

    init():void;

    getConfig() : ConfigObject

    setConfig(config:ConfigObject) : void

    mergeConfig(newConfig:ConfigObject) : void

    isFirstPage() : boolean

    isLastPage() : boolean

    getLastPage() : number

    setPage(page:number) : void

    firstPage() : void

    lastPage() : void

    prev() : void

    next() : void

    changePagebreak(pagebreak:number) : void

    currentPage() : number

}

export class Pager extends Events {

    config:ConfigObject = {
        page: 0,
        pagebreak: 10,
        items: 0,
        lastpage: 1
    }

    defaultConfig:ConfigObject = DefaultConfig;

    public constructor(config: ConfigObject) {
        super();
        this.config = this.defaultConfig;
        this.mergeConfig(config);
        this.init();
    }

    init() : void {
       this.calculateLastPage();
    }

    getConfig() : ConfigObject {
        return this.config;
    }

    setConfig(config:ConfigObject) : void {
        // @todo: maybe evt trigger?
        this.config = config;
    }

    mergeConfig(newConfig:ConfigObject) : void {
        this.config = { ...newConfig };
    }

    isFirstPage() : boolean {
        return (this.config.page === 1);
    }

    isLastPage() : boolean {
        return (this.config.page === this.config.lastpage);
    }

    calculateLastPage() : void {
        let x = this.config.items / this.config.pagebreak;
        this.config.lastpage = parseInt(Math.ceil(x).toString());
    }

    getLastPage() : number {
        this.calculateLastPage();
        return this.config.lastpage;
    }

    setPage(page:number) : void {
        if (this.config.page !== page
            && this.config.lastpage >= page
            && page !==0
        ) {
            this.config.page = page;
        }
    }

    firstPage() : void {
        if (this.config.page > 1) {
            this.setPage(1);
        }
    }

    lastPage() : void {
        if (this.config.page < this.config.lastpage) {
            this.setPage(this.config.lastpage);
        }
    }

    prev() : void {
        if (this.config.page > 1) {
            this.setPage(this.config.page-1);
        }
    }

    next() : void {
        if (this.config.page < this.config.lastpage) {
            this.setPage(this.config.page+1);
        }
    }

    changePagebreak(pagebreak:number) : void {
        this.config.pagebreak = pagebreak;
    }

    currentPage() : number {
        return this.config.page;
    }

}