import * as SDK from "../../../Application/SDK/ViewSDK";
import LinkedAccountsTemplate from "bundle-text:./LinkedAccounts.html";

export class LinkedAccounts extends SDK.View {

    session: SDK.SessionHandler;

    connector: SDK.LordfoxConnector;

    events: SDK.EventTable = {
        'submit #lvp-change-pass-user-form'(e) {

        }

    }

    items:[] = [];

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.engine.add('lvp.app.layout', SDK.AppLayoutTemplate);
        this.engine.add('Account.LinkedAccounts.index', LinkedAccountsTemplate);
        this.getLinkedAccounts();
    }

    isUserLoggedIn() {
        return this.connector.Session.isUserLoggedIn();
    }

    getLinkedAccounts() {
        const that = this;
        this.connector.request("/LinkedUser/GetLinkedAccounts/", {
            "userId": this.connector.Session.getUser().userId
        },'GET').then(response => {
            if (response.Ack == 'success') {
                that.success(response);
            } else {
                that.fail(response);
            }
        });
    }

    success(response) {
        this.items = response.LinkedUsers;
        this.update();
    }

    fail(response) {
        this.update();
    }

    render() {
        let vars:any = {
            app: {},
            view: this,
            items: this.items,
        };

        vars.app = {
            title: "Verknüpfte Konten",
            subtext: "Verknüpfte Konten zu anderen Anbietern und Services"
        };

        this.renderEl('Account.LinkedAccounts.index', vars);
    }

}