import * as LVP from "../../SDK/ViewSDK";
import TplMyAccount from "bundle-text:./MyAccount.html";

export class UserModel extends LVP.Model {}

export class MyAccount extends LVP.View {

    session: LVP.SessionHandler;

    connector: LVP.LordfoxConnector;

    user: UserModel;

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.engine.add('lvp.app.layout', LVP.AppLayoutTemplate);
        this.engine.add('Account.MyAccount.Index', TplMyAccount);
    }

    render() {
        if (this.session.isUserLoggedIn()) {
            this.user = new UserModel(this.session.getUser());
        } else {
            this.user = new UserModel({});
        }
        let vars:any = {
            view: this,
            user: this.user
        };
        vars.app = {
            title: "Mein Konto",
            subtext:""
        };
        this.renderEl('Account.MyAccount.Index', vars);
    }

    isUserLoggedIn() {
        return this.session.isUserLoggedIn();
    }


}