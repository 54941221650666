import {Events, IEvents} from "./Events";
import {GenKey} from "./Generator";
import {IPager, Pager, DefaultConfig} from "./Pager";
//import {XmlUtil} from "./XmlUtils";
import {IModel, IModelConstructor, Model} from "./Model";

//import {IServiceConnector, IServiceConnectorConstructor, ServiceConnector} from "./Service";

export interface IModelCollection extends IEvents {
    getPager():IPager;
}

export abstract class ModelCollection extends Events {

    autoload: boolean = false;

    onLoad: boolean = false;

    loading: boolean = false;

    currentItem = 1;

    models:Array<Array<IModel>> = [];

    pager:IPager;

    ident = "";

    model:IModelConstructor;

//    connector?:IServiceConnector | IServiceConnectorConstructor;

    params = {};

    filter = {};
    orderby = {};

    protected constructor() {
        super();
        this.pager = new Pager(DefaultConfig);
        this.model = Model;
        this._init();
    }

    _init() {
        this.ident = GenKey();
        if (this.autoload) {
            this.load();
            console.log('autoload collection');
        }
    }

    /**
     * @todo:   currently only the XML LVPX SOA2 Services supported - we need modern solutions
     *          inside the ModelCollection - its too concrete DataService related stuff
     *          could be fixed with an IDataServiceProvider to handle that kind of issue
     */
    load() {
/*
        if (this.connector !== undefined) {

            if (this.connector instanceof Function) {
                this.connector = new this.connector();
                this.connector.setCollection(this);
            }

            if (this.connector instanceof ServiceConnector) {

                let caps = this.connector.getCapabilities()



            }

        }
*/
    }

    update(cb?:Function) {
        let s=this;
        this.trigger('update', {
           collection: s
        });

        if (cb instanceof Function) {
            this.one('onDataLoaded', function (event, eventContext) {
                cb(s, event, eventContext);
            });
        }
        this.load();
    }

    getPager() : IPager {
        return this.pager;
    }

    each(cb:Function, page?:number) {

        if (!page) page = this.pager.config.page;
        let models = this.models[page];
        let more:boolean|undefined;
        if (Object.keys(models).length !== 0) {
            for (let index in models) {
                if (models.hasOwnProperty(index)) {
                    more = cb(models[index], index);
                }
                if (more !== undefined && !more) break;
            }
        }
    }

    find(cb:any) {
        this.models.find(cb);
    }

    findModelsByKey(key:string, value:any, cb:Function) {
        let list:Array<IModel> = [];
        this.each(function (model:IModel) {
            if (model.has(key)) {
                let mVal = model.get(key);
                if (mVal.toString() === value.toString()) {
                    list.push(model);
                }
            }
        });
        cb(list);
    }

    count() : number {
        let models = this.models[this.pager.config.page];
        if (Object.keys(models).length !== 0) {
            return models.filter(function (a) {
                return a !== undefined
            }).length;
        } else {
            return 0;
        }
    }

    next(cb?:Function) {

        let count = this.count();

        if (count > this.currentItem) {
            if (cb instanceof Function) {
                cb(this);
            }
        } else {
            if (count === this.currentItem) {
                this.currentItem = 1;
                // if last set first
                // @todo: maybe optional via config
                if (this.pager.isLastPage()) {
                    this.pager.setPage(1);
                    if (cb instanceof Function) {
                        let s = this;
                        this.one('onDataLoaded', function () {
                            cb(s);
                        });
                    }
                    this.update();
                } else { // next page
                    this.pager.next();
                    if (cb instanceof Function) {
                        let s = this;
                        this.one('onDataLoaded', function () {
                            cb(s);
                        });
                    }
                    this.update();
                }

            }
        }

    }

    prev(cb?:Function) {

        let count = this.count();
        if (count < 0) count = 0;

        if (this.currentItem >= 1) { //set prev item

            this.currentItem--;
            if (cb instanceof Function) {
                cb(this);
            }

        } else if (this.currentItem === 1) {
            this.currentItem = count;
            if (this.pager.isFirstPage()) {
                this.pager.setPage(this.pager.config.lastpage);
            } else { // set prev item
                this.pager.prev();
            }
            if (cb instanceof Function) {
                let s=this;
                this.one('onDataLoaded', function () {
                    cb(s);
                });
            }
            this.update();
        }

    }

    changePagebreak(pageBreak:number) {
        this.pager.changePagebreak(pageBreak)
        this.update();
    }

    jumpPage(page:number) {
        this.currentItem = 1;
        this.pager.setPage(page);
        this.update();
    }

    nextPage() {
        this.currentItem = this.count(); // @todo: check why
        this.pager.next();
        this.update();
    }

    prevPage() {
        this.currentItem = 1;
        this.pager.prev();
        this.update();
    }

    currentKey() {
        return this.currentItem;
    }

    fetch(key?:number) {
        let models = this.models[this.pager.config.page];
        if (!key) key = this.currentItem;
        if (typeof models[key] !== 'undefined') {
            return models[key];
        }
        return false;
    }

}