import {Events, IEvents} from "./Events";
import {IModelCollection} from "./ModelCollection";

export type ModelAttributes = {
    [key:string]:unknown
}


export interface IModelConstructor {
    new (attributes:ModelAttributes, defaultAttributes?:ModelAttributes, collection?:IModelCollection): IModel;
}

export interface IModel extends IEvents {

    editable?:object;

    options?:object;

    attributes:object;

    set(key:string, value:unknown):boolean;

    get(key:string):any;

    has(key:string):boolean;

    unset(key:string):boolean;

    clear():boolean;

    reset():boolean;

    merge(modelAttributes:ModelAttributes):boolean;

    getAttributes():ModelAttributes;

    getDefaultAttributes():ModelAttributes;

    setAttributes(modelAttributes:ModelAttributes):boolean;

    toJSON():JSON;
}

export class Model extends Events implements IModel {

    public attributes:ModelAttributes = {}

    public defaultAttributes:ModelAttributes = {}

    // @ todo collection interface/type
    public collection;

    public constructor(attributes:ModelAttributes, defaultAttributes?:ModelAttributes, collection?:IModelCollection) {
        super();
        if (defaultAttributes) {
            this.defaultAttributes = defaultAttributes;
            this.attributes = { ...defaultAttributes, ...attributes};
        } else {
            this.attributes = attributes;
        }

        if (collection) {
            this.collection = collection;
        }
    }

    set(key:string, value:unknown) : boolean {
        let m=this;
        let oldValue;
        if (this.has(key)) {
            oldValue = this.attributes[key];
        }
        this.attributes[key] = value;
        let evtContext = {
            model: m,
            method: 'set',
            key: key,
            newValue: value,
            oldValue: oldValue
        };
        this.trigger('set', evtContext);
        this.trigger('change', evtContext);
        return true;
    }

    get(key:string) : any {
        let m = this;
        if (this.has(key)) {
            let value = this.attributes[key];
            let evtContext = {
                model: m,
                method: 'get',
                key: key,
                value: value
            }
            this.trigger('get', evtContext);
            return value;
        }
    }

    has(key:string) : boolean {
        return (this.attributes.hasOwnProperty(key));
    }

    unset(key:string) : boolean {
        if (this.has(key)) {
            let m=this;
            let value = this.get(key);
            let evtContext = {
                model: m,
                method: 'unset',
                key: key,
                lastValue: value
            };
            this.trigger('unset', evtContext);
            this.trigger('change', evtContext);
            delete this.attributes[key];
            return true;
        } else {
            return false;
        }
    }

    clear() : boolean {
        let m = this;
        let lastAttributes = { ...m.attributes};
        this.attributes = {};
        let evtContext = {
            model: m,
            method: 'clear',
            lastAttributes: lastAttributes,
        };
        this.trigger('clear', evtContext);
        this.trigger('change', evtContext);
        return true;

    }

    reset() : boolean {
        let m = this;
        let lastAttributes = { ...m.attributes};
        this.attributes = this.defaultAttributes;
        let evtContext = {
            model: m,
            method: 'reset',
            lastAttributes: lastAttributes,
            newAttributes: m.attributes
        };
        this.trigger('reset', evtContext);
        this.trigger('change', evtContext);
        return true;
    }

    getDefaultAttributes() : ModelAttributes {
        return this.defaultAttributes;
    }

    getAttributes() : ModelAttributes {
        return this.attributes;
    }

    setAttributes(attributes: ModelAttributes) : boolean {
        let m=this;
        let lastAttributes = {...this.attributes};
        let evtContext={
            model: m,
            method: 'setAttributes',
            lastAttributes: lastAttributes,
            newAttributes: attributes
        };
        this.attributes = attributes;
        this.trigger('setAttributes', evtContext);
        this.trigger('change', evtContext);
        return true;
    }

    merge(attributes: ModelAttributes) : boolean {
        let m = this;
        let currentAttributes = { ...this.attributes};
        this.attributes = { ...currentAttributes, ...attributes};
        let evtContext = {
            model: m,
            method: 'merge',
            lastAttributes: currentAttributes,
            mergeWithAttributes: attributes,
            mergedAttributes: m.attributes
        }
        this.trigger('merge', evtContext);
        this.trigger('change', evtContext);
        return true;
    }

    toJSON(): JSON {
        return JSON.parse(JSON.stringify(this.attributes));
    }
}