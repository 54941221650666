import * as SDK from "../../../Application/SDK/ViewSDK";
import Template from "bundle-text:./AudioPlayer.html";
import TrackList_TrackTemplate from "bundle-text:./AudioPlayer.TrackList.Track.html";
import {LocalTrackDataProvider} from "./LocalTrackDataProvider";
import {DropFileDataProvider} from "./DropFileDataProvider";

enum NextMode {
    Next = 0,
    RepeatAll = 1,
    RepeatOne = 2
}

export type TrackData = {
    artist: string;
    title: string;
    url: string;
    thumb: string,
    durationTime: string,
    local?:any;
}

export interface PlayerData {
    tracks: TrackData[];
}

declare interface window {
    showDirectoryPicker:Function;
}


type DomElements = {[k:string]: any};

export class AudioPlayer extends SDK.View {

    session: SDK.SessionHandler;

    connector: SDK.LordfoxConnector;

    localTrackDataProvider: LocalTrackDataProvider;

    dropFileDataProvider: DropFileDataProvider;

    events: SDK.EventTable = {

        'click i.track-remove'(e) {
            this.removeTrack(e.target.dataset.index);
        },

        'click .next-modes'(e) {
            this.toggleNextMode();
        },

        'click .ti-thumb'(e) {
            this.setTrack(e.target.dataset.index);
        },

        'click #play'(e) {
            this.pausePlay();
        },

        'click #prev-track'(e) {
            this.prevTrack();
        },

        'click #pause'(e) {
            this.pausePlay();
        },

        'click #next-track'(e) {
            this.nextTrack();
        },

        'ended #track'(e) {
            switch (this.nextMode) {
                case NextMode.Next:
                    this.nextTrack();
                    break;
                case NextMode.RepeatAll:
                    this.nextTrack();
                    break;
                case NextMode.RepeatOne:
                    this.setTrack(this.trackIndex);
                    break;
            }
        },

        'touchstart #progressBar'(e) {
            // stop interval one
            this.stopInterval();
        },

        'touchmove #progressBar'(e) {
            // update time on move
            this.previewTime();
        },

        'touchend #progressBar'(e) {
            // release new time
            this.changeProgressBar();
            this.startInterval();
        },

        'mousedown #progressBar'(e) {
            // stop interval one
            this.stopInterval();
        },

        'mousemove #progressBar'(e) {
            // update time on move
            this.previewTime();
        },

        'mouseup #progressBar'(e) {
            // release new time
            this.changeProgressBar();
            this.startInterval();
        },

        'scroll #track-list'(e) {
            const {scrollHeight, scrollTop, clientHeight} = e.target;

            if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1) {
                console.log('scrolled');
            }
        },

        'click .tracks-clear'(e) {
            this.clearTrackList();
        }

    }

    playing: boolean = true;

    nextMode: NextMode = NextMode.RepeatAll;

    trackIndex:number = 0;
/*
    data: PlayerData = {
        tracks: [
            {artist: "Swat", title: "Energize", thumb: "https://i1.sndcdn.com/artworks-000584881817-3z9oc9-t500x500.jpg", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/Energize.mp3", durationTime: "57:17"},
            {artist: "FG VI", title: "Can you here me (Original Mix)", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/vocal_drum_and_bass_mix_29.png", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/FG_VI_Can_You_Hear_Me__OriginalMix.mp3", durationTime: "6:22"},
            {artist: "H.I.M", title: "Join me in death (Official)", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/HIM_Razorblade_Romance.PNG", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/HIM_Join_Me_In_Death__Official.mp3", durationTime: "3:34"},
            {artist: "Mix", title: "Vocal Liquid Drum And Bass Mix #29", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/vocal_drum_and_bass_mix_29.png", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/Vocal_Liquid_Drum_and_Bass_Mix_29.mp3", durationTime: "166:08"},
            {artist: "Swat", title: "Energize", thumb: "https://i1.sndcdn.com/artworks-000584881817-3z9oc9-t500x500.jpg", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/Energize.mp3", durationTime: "57:17"},
            {artist: "FG VI", title: "Can you here me (Original Mix)", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/vocal_drum_and_bass_mix_29.png", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/FG_VI_Can_You_Hear_Me__OriginalMix.mp3", durationTime: "6:22"},
            {artist: "H.I.M", title: "Join me in death (Official)", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/HIM_Razorblade_Romance.PNG", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/HIM_Join_Me_In_Death__Official.mp3", durationTime: "3:34"},
            {artist: "Mix", title: "Vocal Liquid Drum And Bass Mix #29", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/vocal_drum_and_bass_mix_29.png", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/Vocal_Liquid_Drum_and_Bass_Mix_29.mp3", durationTime: "166:08"},
            {artist: "Swat", title: "Energize", thumb: "https://i1.sndcdn.com/artworks-000584881817-3z9oc9-t500x500.jpg", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/Energize.mp3", durationTime: "57:17"},
            {artist: "FG VI", title: "Can you here me (Original Mix)", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/vocal_drum_and_bass_mix_29.png", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/FG_VI_Can_You_Hear_Me__OriginalMix.mp3", durationTime: "6:22"},
            {artist: "H.I.M", title: "Join me in death (Official)", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/HIM_Razorblade_Romance.PNG", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/HIM_Join_Me_In_Death__Official.mp3", durationTime: "3:34"},
            {artist: "Mix", title: "Vocal Liquid Drum And Bass Mix #29", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/vocal_drum_and_bass_mix_29.png", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/Vocal_Liquid_Drum_and_Bass_Mix_29.mp3", durationTime: "166:08"},
            {artist: "Swat", title: "Energize", thumb: "https://i1.sndcdn.com/artworks-000584881817-3z9oc9-t500x500.jpg", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/Energize.mp3", durationTime: "57:17"},
            {artist: "FG VI", title: "Can you here me (Original Mix)", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/vocal_drum_and_bass_mix_29.png", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/FG_VI_Can_You_Hear_Me__OriginalMix.mp3", durationTime: "6:22"},
            {artist: "H.I.M", title: "Join me in death (Official)", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/HIM_Razorblade_Romance.PNG", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/HIM_Join_Me_In_Death__Official.mp3", durationTime: "3:34"},
            {artist: "Mix", title: "Vocal Liquid Drum And Bass Mix #29", thumb: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/vocal_drum_and_bass_mix_29.png", url: "https://www.lvp.lordfox.de/templates/lvp_lordfox-test/Vocal_Liquid_Drum_and_Bass_Mix_29.mp3", durationTime: "166:08"},

        ]
    }
*/

    data: PlayerData = {
        tracks: []
    }

    interval:any;

    dom:DomElements = {};

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.engine.add('lvp.app.layout', SDK.AppLayoutTemplate);
        this.engine.add('AudioPlayer.Display', Template);
        this.engine.add('AudioPlayer.TrackList.Track', TrackList_TrackTemplate);
        this.localTrackDataProvider = new LocalTrackDataProvider(this);
        this.localTrackDataProvider.initialize().then(r => {
            console.log('Player: Local TrackDataProvider initialized');
        });
        this.dropFileDataProvider = new DropFileDataProvider(this);


    }

    cacheDom() {
        this.dom = {
            repeatAll: this.el.querySelector('.repeat-all'),
            repeatOne: this.el.querySelector('.repeat-one'),
            track: this.el.querySelector('#track'),
            thumbnail: this.el.querySelector('#thumbnail'),
            play: this.el.querySelector('#play'),
            pause: this.el.querySelector('#pause'),
            background: this.el.querySelector('#background'),
            trackArtist: this.el.querySelector('#track-artist'),
            trackTitle: this.el.querySelector('#track-title'),
            currentTime: this.el.querySelector('#currentTime'),
            durationTime: this.el.querySelector('#durationTime'),
            progressBar: this.el.querySelector('#progressBar'),
            trackList: this.el.querySelector('#track-list')
        }
    }

    toggleNextMode() {

        let svgAll:HTMLElement = this.dom.repeatAll;
        let svgOne:HTMLElement = this.dom.repeatOne;

        switch (this.nextMode) {
            case NextMode.Next:
                this.nextMode = NextMode.RepeatAll;
                svgAll.style.display = 'block';
                svgOne.style.display = 'none';
                break;
            case NextMode.RepeatAll:
                this.nextMode = NextMode.RepeatOne;
                svgOne.style.display = 'block';
                svgAll.style.display = 'none';
                break;

            case NextMode.RepeatOne:
                this.nextMode = NextMode.RepeatAll;
                svgOne.style.display = 'none';
                svgAll.style.display = 'block';
                break;
        }
        this.trigger('player.toggleNextMode', {player: this, nextMode: this.nextMode});

    }

    initMediaSessionHandler() {

        const that = this;
        let track:HTMLAudioElement = this.dom.track;

        navigator.mediaSession.setActionHandler("play", () => {
            that.pausePlay();
        });

        navigator.mediaSession.setActionHandler("stop", () => {
            that.pausePlay();
        });

        navigator.mediaSession.setActionHandler("pause", () => {
            that.pausePlay();
        });

        navigator.mediaSession.setActionHandler("seekto", details => {
            track.currentTime = details.seekTime;
        });

        navigator.mediaSession.setActionHandler("previoustrack", () => {
            that.prevTrack();
        });

        navigator.mediaSession.setActionHandler("nexttrack", () => {
            that.nextTrack();
        });

    }

    pausePlay() {

        let track:HTMLAudioElement = this.dom.track;
        let thumbnail:HTMLImageElement = this.dom.thumbnail;
        let play:HTMLElement = this.dom.play;
        let pause:HTMLElement = this.dom.pause;

        if (this.playing) {
            this.trigger('player.play', {player: this, track: this.data.tracks[this.trackIndex]});
            play.style.display = "none";
            pause.style.display = "block";
            thumbnail.style.transform = "scale(1.25)";
            track.play();
            this.playing = false;
        } else {
            this.trigger('player.pause', {player: this, track: this.data.tracks[this.trackIndex]});
            pause.style.display = "none";
            play.style.display = "block";
            thumbnail.style.transform = "scale(1)";
            track.pause();
            this.playing = true;
        }
    }

    nextTrack() {
        this.trackIndex++;
        if (this.trackIndex > this.data.tracks.length - 1) {
            this.trackIndex = 0;
        }
        this.trigger('player.next', {player: this, track: this.data.tracks[this.trackIndex]});
        this.setTrack(this.trackIndex);
    }

    activeTrack(trackIndex) {
        if (Array.from(this.data.tracks).length) {
            this.el.querySelectorAll('.track-item').forEach(function(ele, index) {
                ele.classList.remove('active');
            });

            this.el.querySelector('.track-item[data-index="'+trackIndex+'"]').classList.add('active');
        }
    }

    removeTrack(trackIndex:number) {
        this.data.tracks.splice(trackIndex,1);
        this.updateTracks();
    }

    setTrack(trackIndex) {
        let tracks = this.data.tracks;
        let track:HTMLAudioElement = this.dom.track;
        let thumbnail:HTMLImageElement = this.dom.thumbnail;
        let background:HTMLImageElement = this.dom.background;
        let trackArtist = this.dom.trackArtist;
        let trackTitle = this.dom.trackTitle;
        this.trackIndex = trackIndex;

        track.src = tracks[trackIndex].url;
        thumbnail.src = tracks[trackIndex].thumb;
        background.src = tracks[trackIndex].thumb;

        trackArtist.textContent = tracks[trackIndex].artist;
        trackTitle.textContent = tracks[trackIndex].title;

        /**
         * @see https://pwadvent.dev/day/10/
         */
        navigator.mediaSession.metadata = new MediaMetadata({
            title: tracks[trackIndex].title,
            artist: tracks[trackIndex].artist,
 //           album: 'Album Title',
            artwork: [
                {
                    src: tracks[trackIndex].thumb,
 //                   sizes: '100x100', // HeightxWidth
 //                   type: 'image/png'
                }
            ]
        });

        this.el.querySelectorAll('.track-item').forEach(function(ele, index) {
            ele.classList.remove('active');
        });

        this.el.querySelector('.track-item[data-index="'+trackIndex+'"]').classList.add('active');


        this.updateMarquee();
        this.playing = true;
        this.pausePlay();
    }

    prevTrack() {
        this.trackIndex--;
        if (this.trackIndex < 0) {
            this.trackIndex = this.data.tracks.length - 1;
        }
        this.trigger('player.prev', {player: this, track: this.data.tracks[this.trackIndex]});
        this.setTrack(this.trackIndex);
    }

    updateMarquee() {
        //if (this.el.querySelector("#marquee")) {
            let laufschrift = this.el.querySelector("#marquee");
            let len = laufschrift.innerHTML.length;

            var nodes = laufschrift.getElementsByTagName("span");
            for(var i=0; i<nodes.length; i++) { // weil jeder whitespace als child zählt
                nodes[i].style.animationDuration = len/10 +"s";
            }
       // }
    }

    setTrackDurationFromContext(arrayBuffer, trackData:TrackData) {
        const that = this;
        // Create an instance of AudioContext
        let audioContext = new AudioContext();
        // Asynchronously decode audio file data contained in an ArrayBuffer.
        audioContext.decodeAudioData(arrayBuffer, function(buffer) {
            // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
            trackData.durationTime = new Date(parseInt(String(buffer.duration)) * 1000).toISOString().slice(11, 19);
            that.updateTracks();
        });
    }

    progressValue() {
        let progressBar:HTMLInputElement = this.dom.progressBar;
        let track:HTMLAudioElement = this.dom.track;
        let currentTime = this.dom.currentTime;
        let durationTime = this.dom.durationTime;

        progressBar.max = String(track.duration);
        progressBar.value = String(track.currentTime);

        currentTime.textContent = this.formatTime(track.currentTime);
        durationTime.textContent = this.formatTime(track.duration);
    }

    formatTime(sec) {
        let minutes = Math.floor(sec / 60);
        let seconds:any = Math.floor(sec - minutes * 60);
        if (seconds < 10) {
            seconds = `0${seconds}`;
        }
        return `${minutes}:${seconds}`;
    }

    previewTime() {
        let currentTime = this.dom.currentTime;
        currentTime.textContent = this.formatTime(this.getProgressBarTime());
    }

    changeProgressBar() {
        let progressBar:HTMLInputElement = this.dom.progressBar;
        this.setTime(Number(progressBar.value));
    }

    getProgressBarTime() {
        let progressBar:HTMLInputElement = this.dom.progressBar;
        return Number(progressBar.value);
    }

    setTime(time:Number) {
        let track:HTMLAudioElement = this.dom.track;
        track.currentTime = Number(time);
    }

    stopInterval() {
        clearInterval(this.interval);
    }

    startInterval() {
        const that = this;
        this.interval = setInterval(function(){
            that.progressValue();
        }, 500);
    }

    updateTracks() {

        this.dom.trackList.innerHTML = this.engine.render('AudioPlayer.TrackList.Track', this.data);
        this.bindEvents(this.events);
    }

    clearTrackList() {
        this.data.tracks = [];
        this.updateTracks();
    }

    addTrack(trackData: TrackData, updateTrackList:boolean = false) {
        this.data.tracks.push(trackData);
        if (updateTrackList)
            this.updateTracks();
    }

    render() {

        let vars:any = {
            app: {},
            player: this,
            tracks: this.data.tracks,
            trackIndex: this.trackIndex,
            nextMode: this.nextMode
        };

        vars.app = {
            title: "AudioPlayer",
            subtext: ""
        };

        this.renderEl('AudioPlayer.Display', vars);
        this.cacheDom();
        let svgAll:HTMLElement = this.dom.repeatAll;
        let svgOne:HTMLElement = this.dom.repeatOne;
        svgAll.style.display = 'block';
        svgOne.style.display = 'none';
        this.activeTrack(this.trackIndex);
        this.stopInterval();
        this.startInterval();
    }

}