import {LvpBrand} from "../../../Domain/Entity/LvpBrand";
import {EntityEditor, IEntity} from "../EntityEditor/EntityEditor";
import TplEntityDisplay from "bundle-text:./BrandDisplay.html";

export class Brand extends EntityEditor {

    constructor() {

        super(new LvpBrand(), []);

        this.engine.add('Entity.Display', TplEntityDisplay);

        this.on('collect.success', (ident, context) => {
            console.log('entity collected');
            console.debug(context);
        });

        this.on('persist.success', (ident, context) => {
            console.log('entity persist');
        });

    }
}