import {MuxConnector} from "../../Connector/MuxConnector";
import * as SDK from "../../SDK/ViewSDK";
import * as moment from 'moment';
import 'moment/locale/de';
import {assignTemplates} from "./Templates";
import {UserDatabase, ConversationDatabase, MessageDatabase} from "./db/Database";
import {Users, Conversations, Messages} from "./db/Controller";
import {Message} from "./db/interfaces/Message";
import {Conversation} from "./db/interfaces/Conversation";
import {User} from "./db/interfaces/User";
import {DummyDataFactory} from "./db/DummyDataUtil";

import {scrollParentToChild} from "../../Utils/ScrollUtil";

import {v4 as uuidv4} from 'uuid';
import joypixels from 'emoji-toolkit';

moment.locale('de');


export class AbstractView extends SDK.View {

    session: SDK.SessionHandler;

    connector: SDK.LordfoxConnector;

    socketConnector:MuxConnector;

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
    }

}

export class Messenger extends AbstractView {

    isConnected: boolean = false;

    interval: any = null;

    conversations: Conversations;

    messages: Messages;

    users: Users;

    intervalTimeUpdater: any = null;


    curDisplay: string = 'conversations';
    curConversation: Conversation;
    conversationUserById:any;
    conversationMessagesById: any;
    conversationLastMessages: any = {};

    events: SDK.EventTable = {
        'click .chat-contact'(e) {
            let id = e.target.dataset.id;
            let user = this.socketConnector.states.empic.get('users')[id];
        },

        'click .conversation-list-item'(e) {
            let id = e.target.dataset.id;
            const that = this;
            this.conversations.conversations.get(id).then((conversation)=> {
                that.curConversation = conversation;
                that.curDisplay = 'conversation';
                that.update();
            });
        },

        'click .conversation-back'() {
            this.curDisplay = 'conversations';
            this.update();
        },

        'click .conversation-leave'(e) {
            let conversationContainer = e.target.closest('.conversation-container');
            let conversationId = conversationContainer.dataset.conversation;
        },

        'click .conversation-send'() {
            const that = this;
            let content = this.el.querySelector('.conversation-input-text').innerHTML;
            let author = that.session.getUserId();

            let message: Message = {
                id: uuidv4(),
                authorId: String(author),
                createdAt: Date.now(),
                content: content,
                conversation: this.curConversation.id,
                contentType: "text",
                recieved: [],
                readers: []
            }

            let previewContainer = this.el.querySelector('.input-replay-preview');
            let replayTo = previewContainer.dataset.replayTo;
            if (replayTo) {
                message.replayTo = replayTo;
                previewContainer.dataset.replayTo = "";
                previewContainer.innerHTML = "";
            }

            this.messages.messages.add(message);
            this.conversationMessagesById[message.id] = message;
            this.addMessage(message);
            this.el.querySelector('.conversation-input-text').innerHTML = "";
        },

        'click .message-delete-force'(e) {
            let message = e.target.closest('.message');
            let id = message.dataset.id;
            this.messages.messages.where("id").equals(id).delete();
            message.remove();
        },

        'click .message-delete'(e) {
            let message = e.target.closest('.message');
            let id = message.dataset.id;
            message.querySelector('.content').innerHTML = "Nachricht wurde gelöscht.";
            let userId = window.Application.Messenger.session.getUserId();
            let msg = this.messages.get(id);
            if (Array.isArray(msg.deletedBy)) {
                if(!msg.deletedBy.includes(userId)) {
                    msg.deletedBy.push(userId);
                }
            } else {
                msg.deletedBy = [userId];
            }
            this.messages.messages.where("id").equals(id).modify(msg);
        },

        'click .message-replay'(e) {
            let messageEl = e.target.closest('.message');
            let messageId = messageEl.dataset.id;
            let previewContainer = this.el.querySelector('.input-replay-preview');
            previewContainer.dataset.replayTo = messageId;
            this.messages.get(messageId).then((msg) => {
                previewContainer.innerHTML = this.engine.render('Messenger.Conversation.Input.ReplayTo', {
                    message: msg,
                    messagesById: this.conversationMessagesById,
                    userDataById: this.conversationUserById
                });
                this.bindEvents(this.events);
            });
        },

        'click .replay-cancel'(e) {
            let previewContainer = this.el.querySelector('.input-replay-preview');
            previewContainer.dataset.replayTo = "";
            previewContainer.innerHTML = "";
            console.log('done');
        },

        /**
         * @todo: kleines PopOver an wen weitergeleitet werden soll
         * @param e
         */
        'click .message-forward'(e) {

        },

        /**
         * @todo: funktioniert nicht - er interpretiert immer als wäre das child bereits sichtbar
         * @param e
         */
        'click .replay-display'(e) {
            let id = e.target.dataset.replayTo;
            scrollParentToChild(this.el.querySelector('.conversation-messages'), this.el.querySelector('.msg-'+id));
        },

        'click .message-copy'(e) {
            let messageEl = e.target.closest('.message');
            let messageId = messageEl.dataset.id;
            this.messages.get(messageId).then(function(msg) {
                navigator.clipboard.writeText(msg.content);
            });

        },

        'click .group-create'(e) {
            e.preventDefault();
            this.curDisplay = 'group-create';
            this.update();
        },

        'click .group-create-submit'(e) {
            let nameEl = this.el.querySelector('.group-name');
            let groupName = nameEl.value;
            const that = this;
            const db = new ConversationDatabase();
            db.transaction('rw', db.conversations, async() => {
                if ((await db.conversations.where({title: groupName}).count()) === 0) {
                    await db.conversations.add({
                        id: uuidv4(),
                        title: groupName,
                        icon: "",
                        image: "",
                        description: "",
                        type: "group",
                        community: null,
                        admins: [that.session.getUserId()],
                        users: [that.session.getUserId()],
                        createdAt: Date.now(),
                        lastMessageAt: null,
                        lastMessageId: null,
                        isPinned: 1,
                        readOnly: 0
                    });
                    this.curDisplay='conversations';
                    this.update();
                } else {
                    this.el.querySelector('.error-message').innerHTML = 'Name bereits vergeben';
                }
            });
        },

        'click .group-create-cancel'(e) {
            this.curDisplay = 'conversations';
            this.update();
        }
    }



    constructor() {
        super();
        const that = this;

        this.el.classList.add('messenger-wrap');

        // session
        this.session = window.Application.connector.Session;

        // api connector
        this.connector = window.Application.connector;

        assignTemplates(this);


        this.engine.addHelper('timeCreated', function(ut) {
            return that.timeFormat(ut);
        });

        this.engine.addHelper('contentFilter', function(contentOpt) {
            return that.messageContentFilter(contentOpt);
        });


        // db data controller
        this.conversations = new Conversations();
        this.messages = new Messages();
        this.users = new Users();

        this.startTimeUpdater();

        window.Application.on('lvp.user.logout.success', function(evt, context) {
            that.disconnect();
        });

        window.Application.on('lvp.user.login.success', function(evt, context) {
            that.connect();
        });

        if (this.session.isUserLoggedIn())
            that.connect();
    }
    public addMessage(message: Message) {

        let messageHtml = this.engine.render('Messenger.Conversation.Message', {
            message: message,
            messagesById: this.conversationMessagesById,
            userDataById: this.conversationUserById
        });
        let anchor = this.el.querySelector('#msg-anchor');
        let parentNode = anchor.parentNode;

        const parser = new DOMParser();
        const doc = parser.parseFromString(messageHtml, "text/html").body.firstChild;
        parentNode.insertBefore(doc, anchor);
        this.bindEvents(this.events);

    }

    /**
     * @todo: message content filter - to protect the user and give some special extras
     * @param contentOpt
     */

    messageContentFilter(contentOpt) {

        const that = this;

        if (contentOpt) {

            let message = contentOpt.message;
            /**
             * @see https://joypixels.com/emoji
             * @see https://github.com/joypixels/emoji-toolkit/blob/master/examples/JAVASCRIPT.md
             * @see https://unicode.org/emoji/charts/full-emoji-list.html#1f649
             */
            let content = joypixels.toImage(message.content);

            let displayType = contentOpt.displayType;

            if (displayType) {

                switch (displayType) {

                    case 'message.replay.content':
                        return content;

                    case 'message.content':
                        if (content.match(/(.*)\<img(.*)/g)) {
                            let parser = new DOMParser();
                            const doc = parser.parseFromString(message.content, 'text/html').body;
                            let images = doc.querySelectorAll('img');
                            message.content = joypixels.toImage(message.content);
                            return that.engine.render('Messenger.Conversation.Message.ImageFormat', {
                                images: Array.from(images),
                                message: message,
                            });
                        }
                        return joypixels.toImage(message.content);

                    case 'conversation.preview':
                        if (content.match(/(.*)\<img(.*)/g)) {
                            return content.replace(/<img .*?>/g,"[Bild]");
                        } else {
                            return content;
                        }

                    default:
                        return content;
                }
            } else {
                return content;
            }

        }
    }

    startTimeUpdater() {
        const that = this;
        this.intervalTimeUpdater = setInterval(()=> {
            that.timeUpdater();
        }, 60000);
    }

    stopTimeUpdater() {
        clearInterval(this.intervalTimeUpdater);
        this.intervalTimeUpdater = null;
    }

    timeFormat(unixTime) {
        let x = moment(unixTime/1000, 'X');
        let now = new Date()
        if (x.isSame(now, "hour")) {
            return moment(unixTime/1000, 'X').startOf('minute').fromNow();
        } else if (x.isSame(now, "day")) {
            return moment(unixTime/1000, 'X').format('LT');
        } else {
            return moment(unixTime/1000, 'X').format('LLL');
        }
    }

    timeUpdater() {
        let timer = this.el.querySelectorAll('.time-update');
        const that = this;
        Array.from(timer).forEach((e:HTMLElement, i) => {
            let ut:number = parseInt(e.dataset.time);
            if (moment(ut/1000, 'X').isSame(new Date(), 'hour')) {
                e.innerHTML = that.timeFormat(ut);
            }

        });
    }


    /**
     * Gehe die Conversations durch und prüfe nach letzter Message
     */
    lastMessageUpdater() {

        let conversations = this.el.querySelectorAll('.conversation-last-message');
        const that = this;
        Array.from(conversations).forEach((e:HTMLElement) => {
            let conversationId = e.dataset.conversation;
            this.conversations.get(conversationId).then((conversation) => {
                that.messages.getLastConversationMessage(conversation).then((messages) => {
                    if (messages[0]) {
                        this.users.getUserDataByConversation(conversation).then((users) => {
                            e.innerHTML = this.engine.render('Messenger.Conversations.List.Item.LastMessage', {
                                message: messages[0],
                                userDataById: users
                            });
                            that.bindEvents(that.events);
                        });
                    }
                });
            });
        });

    }

    fillDummyData() {

        const fac = new DummyDataFactory();
        fac.create();

    }

    disconnect() {
        this.socketConnector.empic.disconnect();
        this.isConnected = false;
        this.update();
    }

    connect() {
        const that = this;
        if (!this.isConnected) {
            this.socketConnector = new MuxConnector("https://chat.lordfox.de/empIC");
            this.socketConnector.on('lvp.empic.connect', function () {
                that.updateUsers();
            });
            window.Mux = this.socketConnector;

            this.socketConnector.on('lvp.empic.debug', function(ident, context) {
                //console.log(context);
            });
            this.socketConnector.on('lvp.empic.any', function(ident, context) {
                //console.log(context);
            });

            this.socketConnector.on('lvp.empic.users', function(ident, eventContext) {
                that.update();
            });

            this.socketConnector.on('lvp.empic.rooms', function(ident, eventContext) {
                that.update();
            });

            if (this.interval !== null)
                clearInterval(this.interval);

            this.interval = setInterval(function(){
                that.updateUsers();
            }, 100000000000);

            this.isConnected = true;
            this.update();
        }

    }


    async render() {

        switch (this.curDisplay) {

            case 'group-create':

                this.renderEl('Messenger.Group.Create', {});

                break;

            case 'conversations':

                this.conversations.getPinnedConversations().then((c) => {

                    for (const conversation of c) {
                        this.messages.getLastConversationMessage(conversation).then((messages) => {
                            this.conversationLastMessages[conversation.id] = messages[0];
                        });
                    }

                    if (this.isConnected) {
                        this.renderEl('Messenger.Layout', {
                            lastMessages: this.conversationLastMessages,
                            conversations: c,
                            users: this.socketConnector.states.empic.get('users'),
                            isConnected: this.isConnected,
                        });
                        this.bindEvents(this.events);
                    } else {
                        this.renderEl('Messenger.Layout', {
                            lastMessages: this.conversationLastMessages,
                            conversations: c,
                            users: {},
                            isConnected: this.isConnected,
                        });
                        this.bindEvents(this.events);
                    }

                    this.lastMessageUpdater();
                });
                break;

            case 'conversation':
                this.conversationUserById = await this.users.getUserDataByConversation(this.curConversation);
                this.conversationMessagesById = await this.messages.getMessageDataByConversation(this.curConversation);
                this.messages.getByConversation(this.curConversation.id).then((m) => {
                    this.curConversation.messages = m;
                    this.renderEl('Messenger.Conversation', {
                        conversation: this.curConversation,
                        userDataById: this.conversationUserById,
                        messagesById: this.conversationMessagesById
                    });
                    this.bindEvents(this.events);
                    this.el.querySelector('.messages').scrollIntoView({ block: "end", inline: "nearest" });
                });

                setTimeout(() => {
                    this.createObserver();
                },600);


                break;

        }
    }

    obs: any;

    createObserver() {
        var options = {
            root: document.querySelector('.conversation-messages'),
            rootMargin: '50px',
            threshold: 0
        }
        this.obs = new IntersectionObserver(function (entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target.classList.contains('message-top')) {
                        console.log('on top');
                        /**
                         * @todo: load older messages
                         */
                    }

                    if (entry.target.classList.contains('message-bottom')) {
                        console.log('on bottom');
                    }
                }
            });
            console.log(observer);
        }, options);

        let messages = this.el.querySelectorAll('.message');

        Array.from(messages).forEach((message) => {
            this.obs.observe(message);
        });
    }

    updateUsers(cb?) {
        this.socketConnector.getUsers(cb);
    }

    updateRooms(cb?) {
        this.socketConnector.getRooms(cb);
    }


}

