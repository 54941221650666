import {ActionManager as B_ActionManager} from "babylonjs";
import {ExecuteCodeAction} from "babylonjs";

import {BaseManager} from "./BaseManager";
import {IManager} from "./IManager";

/**
 * https://doc.babylonjs.com/divingDeeper/events/actions
 */
export class ActionManager extends BaseManager implements IManager {

    InputMap = {};

    PickingHistory = [];

    async init() {
        const that = this;
        let Scene = this.viewer.SceneManager.scene;

        // Keyboard events
        let inputMap = this.InputMap;
        Scene.actionManager = new B_ActionManager(Scene);
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnKeyDownTrigger, function (evt) {
            inputMap[String(evt.sourceEvent.key).toLowerCase()] = evt.sourceEvent.type == "keydown";
            console.log('keyDown', evt.sourceEvent.key);
        }));
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnKeyUpTrigger, function (evt) {
            inputMap[String(evt.sourceEvent.key).toLowerCase()] = evt.sourceEvent.type == "keydown";
            console.log('keyUp', evt.sourceEvent.key);
        }));

        // Mouse events

        // left click
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnLeftPickTrigger, function (evt) {
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
            console.log('left click', evt.sourceEvent);
        }));

        // right click
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnRightPickTrigger, function (evt) {
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
            console.log('right click', evt.sourceEvent);
        }));

        // center click
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnCenterPickTrigger, function (evt) {
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
            console.log('center click', evt.sourceEvent);
        }));

        // pick up click
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnPickUpTrigger, function (evt) {
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
            console.log('pick up click', evt.sourceEvent);
        }));

        // pick down click
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnPickDownTrigger, function (evt) {
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
            console.log('pick down click', evt.sourceEvent);
        }));

        // pick
        Scene.actionManager.registerAction(new ExecuteCodeAction(B_ActionManager.OnPickTrigger, function (evt) {
            inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
            console.log('pick', evt.sourceEvent);
        }));


        Scene.onPointerDown = function(e, pickInfo) {

            let data =  {
                PickingInfo: pickInfo,
                OriginEvent: e,
                Viewer: that.viewer,
                PickingHistory: that.PickingHistory
            };
            that.PickingHistory.push(data);
            window.Application.trigger('scene.onPointerDown.pick', data);
            console.log(that.PickingHistory);
        }

        Scene.onPointerUp = function(e, pickInfo) {
            window.Application.trigger('scene.onPointerUp.pick', {
                PickingInfo: pickInfo,
                OriginEvent: e,
                Viewer: that.viewer,
                PickingHistory: that.PickingHistory
            });
        }
    }



}