import * as lodash from "lodash"

export class TemplateEngine {

    templates:any;

    helpers:any;

    view?:any;

    constructor() {
        this.templates = {};
        this.helpers = {};

        const that = this;

        this.addHelper('include', (tplName, data) => {
            return that.render(tplName, data);
        });



    }

    render(tplName, data) {
        return this.templates[tplName].compile(data);
    }

    add(name, text, _templates?) {

        let compile;
        let parent;
        let alias;
        let blocks = {};
        let overrides = {};
        let prepends = {};
        let appends = {};
        let markedText;
        let compileText;

        markedText = text.replace(/<%\s*@alias\(['"]([^'"]+)['"]\)\s*%>/g, function(all, name){
            alias = name;
            return '';
        });
        let that=this;

        markedText = markedText.replace(/<%\s*@extends\(['"]([^'"]+)['"]\)\s*%>/g, function(all, name){
            parent = that.templates[name];

            if(!parent){
                that.add(name, _templates[name], _templates);
                parent = that.templates[name];
            }

            return '';
        });

        //parse block
        markedText = markedText.replace(/<block\s+name=['"]?([^'">\s]+)['"]?\s*\/>/g, function(all, name){
            return '<block '+ name +'>';
        });

        //parse block
        markedText = markedText.replace(/<block\s+name=['"]?([^'">\s]+)['"]?\s*>([\s\S]*?)<\/block>/g, function(all, name, block){
            blocks[name] = block;
            return '<block '+ name +'>';
        });

        //parse override
        markedText = markedText.replace(/<override\s+name=['"]?([^'">\s]+)['"]?\s*>([\s\S]*?)<\/override>/g, function(all, name, override){
            overrides[name] = override;
            return '';
        });

        //parse prepend
        markedText = markedText.replace(/<prepend\s+name=['"]?([^'">\s]+)['"]?\s*>([\s\S]*?)<\/prepend>/g, function(all, name, prepend){
            prepends[name] = prepend;
            return '';
        });

        //parse prepend
        markedText = markedText.replace(/<append\s+name=['"]?([^'">\s]+)['"]?\s*>([\s\S]*?)<\/append>/g, function(all, name, append){
            appends[name] = append;
            return '';
        });

        if(parent) {
            markedText = parent.markedText.replace(/<block\s([^>]+)>/g, function(all, name){
                return (prepends[name]||'')
                    + (overrides[name] != null ? overrides[name] :
                        parent&&parent.blocks[name] != null ? parent.blocks[name] : all)
                    + (appends[name]||'');
            });
        }

        compileText = markedText.replace(/<block\s([^>]+)>/g, function(all, name){
            return blocks[name]||'';
        });


        compileText = compileText.replace(/(<%|${)\s*@([^\(]+)\(/g, function(all, tag, name){
            //console.log('# TAG'+ tag);
            //console.debug((tag == '${'? tag : (tag + '=')) + 'this.engine.helper(\'' + name + '\',');
            //console.debug(all);

            return (tag == '${'? tag : (tag + '=')) + 'this.engine.helper(\'' + name + '\',';
        });

        //compile = _.template(compileText, {sourceURL: 'templates/'+name+'.html'});
        compile = lodash.template(compileText);



        this.templates[name] = {
            engine: that,
            text: text,
            markedText: markedText,
            blocks: blocks,
            compile: compile,
            name: name
        };

        if (alias) {
            this.templates[name].alias = alias;
            this.templates[alias] = this.templates[name];
        }

        return this.templates[name];
    }

    addHelper(name, fn) {
        this.helpers[name] = fn;
    }

    helper(name, ...args: any[]) {
        //console.debug(args);
        let helper = this.helpers[name];
        //var rest = [].slice.call(arguments, 0, arguments.length);

        //args.shift();
        //console.debug(args);
        if(!helper){
            helper = this.templates[name].compile;
            if(!args.length) args.push({});
        }
        return helper ? helper.apply(lodash, args) : '';

    }


}