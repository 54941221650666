import * as LVP from "../../Application/SDK/ViewSDK";




export class LvpAddress extends LVP.Entity implements LVP.IEntity {

    cache: any = {};

    entityName: string = 'LvpAddress';

    entityIdField: string = 'addressId';

    defaults: LVP.ModelAttributes = {
        "addressId": "",
        "fullName": "",
        "fullAddress": "",
        "street": "",
        "streetNum": "",
        "additional": "",
        "zipCode": "",
        "city": "",
        "region": "",
        "countryCode": "",
        "lat": "",
        "lng": ""
    }

    attributes: LVP.ModelAttributes = {
        "addressId": "",
        "fullName": "",
        "fullAddress": "",
        "street": "",
        "streetNum": "",
        "additional": "",
        "zipCode": "",
        "city": "",
        "region": "",
        "countryCode": "",
        "lat": "",
        "lng": ""
    }

    editable:any = {

        'fullName': {
            'label': 'Vollständiger Name',
            'type': 'string',
            'prependText': ''
        },

        'street': {
            'label': 'Straße',
            'type': 'string',
            'prependText': ''
        },

        'streetNum': {
            'label': 'Haus-Nr.',
            'type': 'string',
            'prependText': ''
        },

        'additional': {
            'label': 'Zusatz',
            'type': 'string',
            'prependText': ''
        },

        'zipCode': {
            'label': 'PLZ',
            'type': 'string',
            'prependText': ''
        },

        'city': {
            'label': 'Stadt',
            'type': 'string',
            'prependText': ''
        },

        'region': {
            'label': 'Region',
            'type': 'string',
            'prependText': ''
        },

        'countryCode': {
            'label': 'Land',
            'type': 'relation',
            'prependText': '',
            'options': {
                'of': '',
                'valueColumn': 'iso3166Alpha2',
                'displayColumn': 'name'
            }
        }

    }

    constructor(attributes?:LVP.ModelAttributes, defaultAttributes?:LVP.ModelAttributes, collection?:LVP.IModelCollection) {
        super(attributes, defaultAttributes, collection);

        this.cache = new LVP.CacheHandler();
        this.editable.countryCode.options.of = this.cache.get('Countries');
    }

}