import * as LVP from "../../SDK/ViewSDK";
import TplChangePassFinish from "bundle-text:./ChangePassword.finish.html";
import TplChangePassLoginRequired from "bundle-text:./ChangePassword.login.html";
import TplChangePass from "bundle-text:./ChangePassword.html";
import {getCookie} from "../../Connector/Cookie";
import {serializeForm} from '../../../Framework/Forms';


export class ChangePassword extends LVP.View {

    session: LVP.SessionHandler;

    connector: LVP.LordfoxConnector;

    isSuccess:boolean = false;

    events: LVP.EventTable = {

        "submit #lvp-change-pass-user-form"(e) {
            e.preventDefault();

            let formData = new FormData(e.target);
            const s = this;
            let msg = s.el.querySelector('.v-reg-form-msg');

            let data:any = serializeForm(formData)

            this.checkUserPass(data.userPassword, function(isValid) {
                if (isValid) {
                    s.sendNewPassword(data, function(isSuccess) {
                        if (isSuccess) {
                            s.isSuccess = isSuccess;
                            s.update();
                        } else {
                            msg.innerHTML="Ooops, es ist ein interner Fehler aufgetreten";
                            msg.classList.add('alert-danger');
                        }
                    });
                } else {
                    msg.innerHTML="Ooops, es ist ein interner Fehler aufgetreten";
                    msg.classList.add('alert-danger');
                }
            });
        },

        "click button.pass-vis"(e) {
            e.preventDefault();
            const inputEl = this.el.querySelector('.v-pass');
            if (inputEl.getAttribute("type") == "password") {
                inputEl.setAttribute("type", "text");
            } else {
                inputEl.setAttribute("type", "password");
                //inputEl.attributes.type = "password";
            }
            let iconEl;
            if (e.target.nodeName == "I") {
                iconEl = e.target;
            } else {
                iconEl = e.target.querySelector('i');
            }

            iconEl.classList.toggle('fa-eye');
            iconEl.classList.toggle('fa-eye-slash');
        },

        'keyup input.v-pass'(e) {
            const el = e.target;
            let l = el.value.length;

            //DomHelper.find(this.el, '.v-pass-count').text = el.value.length;
            this.el.querySelector('.v-pass-count').text = el.value.length;
            //this.$el.find('.v-pass-count').text(el.val().length)

            const s=this;
            if (l < 8 || l > 20) {
                //let elMsg = s.$el.find('.v-pass-check-msg');
                //let elMsg = DomHelper.find(this.el, '.v-pass-check-msg');
                let elMsg = this.el.querySelector('.v-pass-check-msg');
                elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Mindestens 8, maximal 20 Zeichen.';
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else {
                //DomHelper.find(this.el,'input.v-pass').dispatchEvent(new Event('change'));
                Array.from(this.el.querySelectorAll('input.v-pass')).forEach( (ele:Element) => ele.dispatchEvent(new Event('change')));
            }
        },

        'change input.v-pass'(e) {
            let s = this;

            const el = e.target;
            let pass = el.value;
            let elMsg = s.el.querySelector('.v-pass-check-msg');


            if (pass==="") {
                elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Bitte geben Sie ein sicheres Passwort ein.';
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else if (pass.length < 8 || pass.length > 20) {

                elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Mindestens 8, maximal 20 Zeichen.';
                elMsg.classList.add('alert-danger');
                elMsg.classList.remove('alert-success');
                elMsg.classList.remove('alert-warning');
            } else {
                this.checkUserPass(pass, function(valid) {
                    if (!valid) {
                        elMsg.innerHTML='<i class="fa fa-triangle-exclamation"></i> Passwort ist zu schwach...';
                        elMsg.classList.add('alert-danger');
                        elMsg.classList.remove('alert-success');
                        elMsg.classList.remove('alert-warning');
                    } else {
                        elMsg.innerHTML='<i class="fa fa-check"></i> Sicheres Passwort';
                        elMsg.classList.add('alert-success');
                        elMsg.classList.remove('alert-warning');
                        elMsg.classList.remove('alert-danger');
                    }
                });
            }
        },


    }


    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.engine.add('lvp.app.layout', LVP.AppLayoutTemplate);
        this.engine.add('Account.ChangePassword.Form', TplChangePass);
        this.engine.add('Account.ChangePassword.Index', TplChangePassLoginRequired);
        this.engine.add('Account.ChangePassword.Success', TplChangePassFinish);

    }

    validateFormData(data, cb)
    {
        if (data.userIdent != "" && data.mandatorId !="") {
            data.userIdent.trim();
            data.mandatorId.trim();
            cb(true, data);
        } else {
            cb(false, data);
        }
    }

    checkUserPass(userPass:string, cb) {
        this.connector.request('/User/CheckPasswordFormat/', {
            "userPassword": userPass
        },'POST').then(response => {
            if (response.Ack == 'success') {
                if (response.status == 1) {
                    cb(true);
                } else {
                    cb(false);
                }
            } else {
                cb(false);
            }
        });
    }

    sendNewPassword(data, cb) {
        const that = this;
        this.connector.request('/User/ChangePassword/', {
            "userPassword": data.userPassword,
            "userToken": that.session.getUserToken()
        },'POST').then(response => {
            if (response.Ack == 'success') {
                cb(true);
            } else {
                cb(false);
            }
        });
    }


    render() {
        let vars:any = {};
        var token = getCookie('LVP-userToken');
        if (this.isSuccess) {
            vars.user = this.session.getUser();
            vars.app = {
                title: "Passwort ändern",
                subtext:""
            };
            this.renderEl('Account.ChangePassword.Success',vars);
        } else if (token) {
            //vars.data = this.inputData;
            vars.app = {
                title: "Passwort ändern",
                subtext:""
            };
            this.renderEl('Account.ChangePassword.Form',vars);
        } else {
            vars.app = {
                title: "Passwort ändern",
                subtext:""
            };
            this.renderEl('Account.ChangePassword.Index',vars);
        }


    }

}