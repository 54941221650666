
export function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ')
            c = c.substring(1);
        if (c.indexOf(name) == 0)
            return c.substring(name.length, c.length);
    }
    return "";
}

export function expireAllCookies(name, paths) {
    let expires = new Date(0).toUTCString();

    // expire null-path cookies as well
    document.cookie = name + '=; expires=' + expires;

    for (let i = 0, l = paths.length; i < l; i++) {
        document.cookie = name + '=; path=' + paths[i] + '; expires=' + expires;
    }
}

export function expireActiveCookies(name) {
    let pathname = location.pathname.replace(/\/$/, ''),
        segments = pathname.split('/'),
        paths = [];

    for (let i = 0, l = segments.length, path; i < l; i++) {
        path = segments.slice(0, i + 1).join('/');

        paths.push(path);       // as file
        paths.push(path + '/'); // as directory
    }

    expireAllCookies(name, paths);
}