import {DirectionalLight, ShadowGenerator} from "babylonjs";
import {Vector3} from "babylonjs";

export function create(Viewer) {

    let Scene = Viewer.SceneManager.Scene;

    let sun = new DirectionalLight("sunLight", new Vector3(0, -0.5, -1.0), Scene);
    //let sun = new DirectionalLight("sunLight", new Vector3(0.33, -1, 0.33), Scene);
        sun.position = new Vector3(0, 40, 0);
        sun.intensity = 0.6;


    return sun;

}