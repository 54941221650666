
import {BaseManager} from "./BaseManager";
import {IManager} from "./IManager";

import {LordfoxCharacter} from "../Characters/Lordfox_Character";

import {SceneLoader, Vector3, MeshBuilder} from "babylonjs";
import "babylonjs-loaders";



export class CharacterManager extends BaseManager implements IManager {
    
    characterSettings = {
        'speedForward': 0.2,
        'speedBackward': 0.15,
        'speedRotation': 0.04,
        'animating': true
    };

    character;

    //defaultCharacterName:string = "Miguel";
    defaultCharacterName:string = "Lordfox_Character";

    
    async init() {
        this.character = new LordfoxCharacter(this.viewer);
        //let character = await this.importByName(this.defaultCharacterName, 'Characters');
    }
}