import Dexie, {Table} from "dexie";
import {Conversation} from "./interfaces/Conversation";
import {Message} from "./interfaces/Message";
import {User} from "./interfaces/User";

/**
 * @see https://www.npmjs.com/package/dexie
 */

export class UserDatabase extends Dexie {
    public users!: Table<User, string>;

    public constructor() {
        super("UserDatabase");
        this.version(1).stores({
            users: "++id,userName,userImage,status,statusText"
        });
    }
}

export class MessageDatabase extends Dexie {
    public messages!: Table<Message, string>;

    public constructor() {
        super("MessageDatabase");
        this.version(1).stores({
            messages: "++id,conversation,replayTo,authorId,content,contentType,createdAt,mediaAttachments"
        });

    }

}

export class ConversationDatabase extends Dexie {
    public conversations!: Table<Conversation, string>;

    public constructor() {
        super("ConversationDatabase");
        this.version(1).stores({
            conversations: "++id,title,description,topic,type,community,admins,users,createdAt,lastMessageAt,lastMessageId,isPinned,readOnly"
        })

    }
}