import {View} from '../../Framework/View';
import Tpl2 from "bundle-text:../templates/Test2.html";
import {Model} from "../../Framework/Model";

export class TestView2 extends View {

    constructor() {
        super({
            auto: true,
            template: Tpl2,
            model: new Model({
                "name": "Christopher"
            }),
            events: {
                "click button.test-event": function (event:Event) {
                    console.log('test-event22');
                    console.debug(event);
                    console.debug(this);
                }
            }
        });
    }



}
