export function readAsArrayBuffer(file) {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onload = function (e) {
            resolve(e.target.result);
        };
        reader.onerror = function (e) {
            reject(e);
        };
        reader.readAsArrayBuffer(file);
    });
}

export function loadFile(file: File, type?: string, encoding?: string) {

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = e => resolve(e.target.result);
        switch (type) {
            case "binary":
                reader.readAsBinaryString(file);
                break;
            case "url":
                reader.readAsDataURL(file);
                break;
            case "arrayBuffer":
                reader.readAsArrayBuffer(file);
                break;
            case "text":
            default:
                reader.readAsText(file, encoding || "utf8");
                break;
        }
    });
}

export function getString(dataView, offset, length) {
    let str = "";
    for (let i = 0; i < length; i++) {
        const code = dataView.getUint8(offset + i);
        str += String.fromCharCode(code);
    }
    return str;
}