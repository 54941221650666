import {TemplateEngine} from "./TemplateEngine"
import FormTemplate from "bundle-text:./ModelFormTemplate.html"
import {IModel} from "./Model"

export class ModelFormEditable {

    engine: TemplateEngine;

    constructor() {
        this.engine = new TemplateEngine();
        this.engine.add('form.tpl', FormTemplate);
    }

    render(values, editable, tplName:string = 'form.tpl') {
        return this.engine.render(tplName, {
            model: {
                "values": values,
                "editable": editable
            }
        });
    }

    renderModel(model:IModel, keys, tplName:string = 'form.tpl')
    {
        let values = [];
        if (toString.call(keys) == '[object Array]') {
            keys.forEach((key, idx) => {
                values[idx] = {};
                values[idx][key] = model.get(key);
            });
        } else {
            for (const [k, v] of Object.entries(model.attributes)) {
                let o = {};
                o[v] = k;
                values.push(o);
            }
        }

        return this.engine.render(tplName, {
            model: {
                "values": values,
                "editable": model.editable,
                "options": model.options
            }
        });

    }
}

export function serializeForm(formData:FormData) {
    var obj = {};
    for (var key of formData.keys()) {
        obj[key] = formData.get(key);
    }
    return obj;
}

