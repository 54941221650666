import {LocationBar} from './LocationBar';
import {DomHelper} from './DomHelper'

interface LocationBarConfig {
    pushState: boolean;
    hashChange: boolean;
    root:string;
    silent: boolean;
    location: Location,
    history: History
}

interface RouterConfig {
    LocationBar:LocationBarConfig;
}

interface DebuggingConfig {
    state:boolean;
    style:object
}

interface AjaxConfig {
    ignoreKeyElements:string;
}

interface Config {
    Router:RouterConfig;
    Debugging:DebuggingConfig;
    Ajax:AjaxConfig;
}


export class Router {

    public config:Config;

    public locationBar:LocationBar = null

    constructor(config:Config) {

        this.config = config;
        this.locationBar = new LocationBar();
        this.locationBar.start(config.Router.LocationBar)

    }

    initRoute(uril, container) {
        let s = this;

        let elements = DomHelper.filter(DomHelper.find(document.querySelector('body'), ':scope > *'), ':not(' + s.config.Ajax.ignoreKeyElements + ')');
        //console.debug(elements);
        //DomHelper.empty(elements);
        //DomHelper.remove(elements);

        console.log('initRoute: ' + uril);

        if (uril !== "/") {
            // fix to leading slash
            if (!uril.startsWith('/')) {
                uril = "/" + uril;
            }

            if (uril.startsWith('/~')) {
                // is an internal url or history.back or history.prev
                s.locationBar.update(uril);
                //let loadUri = uril.replace('~', '');
                //s.loadUrl(loadUri, container);
            } else if (uril.startsWith('/#~')) {
                // is a re-directed url from server
                // direct incoming url or e.g. refresh
                // rebuild the state
                setTimeout(function () {
                    let loadUri = uril.replace('#', '');
                    s.locationBar.update(loadUri);
                    console.log('update lb: ' + loadUri);
                    loadUri = uril.replace('~', '');
                    console.log('loadUrl: ' + loadUri);
                    //s.loadUrl(loadUri, container);
                }, 1000);
            } else if (uril.startsWith('/#')) {
                // do nothing
            } else {
                console.log('general route init ...');
                //s.loadUrl(uril, container);
                s.locationBar.update(uril);

            }
        } else {
            s.locationBar.update(uril);
        }
    }

    route(prefix, callback) {
        this.locationBar.route(prefix, callback);
    }

    async loadUrl(url, container) {
        let s = this;
        let debug = s.config.Debugging;

        // debugState
        if (debug.state) {
            console.log("Loading URL: %c" + url, debug.style['default']);
        }

        if (container == document.querySelector('#content')) {
            let elements = DomHelper.filter(DomHelper.find(document.querySelector('body'), ':scope > *'), ':not(' + s.config.Ajax.ignoreKeyElements + ')');
            DomHelper.empty(elements);
            DomHelper.remove(elements);
        }

        await fetch(url, {
            method: 'GET',
        }).then(response => {
            return response.text();
        }).then(html => {
            container.innerHTML = html;
            html=null;
            container=null;
        });
    }

    getParam(name:string)
    {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        let regexS = "[\\?&]" + name + "=([^&#]*)";
        let regex = new RegExp(regexS);
        let results = regex.exec(this.locationBar.location.href);
        if (results == null)
            return "";
        else
            return results[1];
    }


}