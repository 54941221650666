
export class CacheHandler {

    cache: any;

    constructor() {
        this.cache = window.Application.cache;
    }

    set(name, data) {
        this.cache[name] = data;
    }

    get(name) {
        return this.cache[name];
    }
}