import { io, Socket } from "../../socket.io.esm.min";
import { Events } from "../../Framework/Events";
import { Model } from "../../Framework/Model";
import * as Utils  from "../../Framework/Utils";

interface ServerToClientEvents {
    noArg: () => void;
    basicEmit: (a: number, b: string, c: Buffer) => void;
    withAck: (d: string, callback: (e: number) => void) => void;
}

interface ClientToServerEvents {
    hello: () => void;
}

interface InterServerEvents {
    ping: () => void;
}

interface SocketData {
    name: string;
    age: number;
}

export class MuxConnector extends Events {

    hostUrl:string;

    empic: Socket<ServerToClientEvents, ClientToServerEvents>;

    states: any = {
        empic: Model
    }

    constructor(hostUrl: string) {
        super();
        this.hostUrl = hostUrl;
        this.states.empic = new Model({
            'target': {
                'type': '',
                'id': ''
            },

            'rooms': {},

            'users': {},

            'messages': {
                'all': [],
                'users': {

                },
                'rooms': {

                },
                'global': []
            }
        });

        if (window.Application.connector.Session.isUserLoggedIn()) {
            let user = window.Application.connector.Session.getUser();

            // fix UserMgr on socket server
            user.assetID = user.userId;
            user.connectTime = new Date().toLocaleString('de-DE', {timeZone: 'Europe/Berlin'});


            this.initEmpIC(user);
        } else {
            /**
             * @todo: initEmpIC on Application login event
             */
        }

    }

    getRooms(cb?) {
        const that = this;
        if (this.empic.connected) {
            // @ts-ignore
            this.empic.emit('rooms', null, function(data) {
                that.states.empic.set('rooms', data);
                that.trigger('lvp.empic.debug', {
                    data: data,
                    empicType: "rooms"
                });
                that.trigger('lvp.empic.rooms', {
                    data: data
                });

                if (Utils.isFunction(cb)) {
                    cb(data);
                }

            });

        }
    }

    getUsers(cb?) {
        const that = this;
        if (this.empic.connected) {
            // @ts-ignore
            this.empic.emit('users', null, function(data) {
                that.states.empic.set('users', data);
                that.trigger('lvp.empic.debug', {
                    data: data,
                    empicType: "users"
                });
                that.trigger('lvp.empic.users', {
                    data: data
                });
                if (Utils.isFunction(cb)) {
                    cb(data);
                }
            });
        }
    }

    initEmpIC(user: any) {

        const that = this;

        this.empic = io(this.hostUrl, { withCredentials: true});

        this.empic.on('connect', function() {

            that.empic.onAny((eventName, ...args) => {
                that.trigger('lvp.empic.any', {
                    args: args,
                    empicName: eventName
                });
            });


            that.trigger('lvp.empic.debug', {
                data: null,
                empicType: "connect"
            });

            that.trigger('lvp.empic.connect', {});


            // @ts-ignore
            that.empic.emit('auth', user, function (data) {

                that.trigger('lvp.empic.debug', {
                    data: data,
                    empicType: "auth"
                });
                that.trigger('lvp.empic.auth.response', {
                    data: data
                });

            });

            // @ts-ignore
            that.empic.on('ping', function (data) {
                that.trigger('lvp.empic.debug', {
                    data: data,
                    empicType: "ping"
                });
                that.trigger('lvp.empic.ping', {
                    data: data
                });

                // send pongb feq. - track time of the ping and stop on pongb response - if no response follows disconnect the instance
                // @ts-ignore
                that.empic.emit('pongb',{
                    time: new Date()
                });

            });

            // @ts-ignore
            that.empic.on('ponging', function (data){
                that.trigger('lvp.empic.debug', {
                    data: data,
                    empicType: "ponging"
                });
                // console.log('pongb response');
                // console.log(data);
            });

            // @ts-ignore
            that.empic.on('notification', function(data, cb) {

                that.trigger('lvp.empic.debug', {
                    data: data,
                    empicType: "notification"
                });
                that.trigger('lvp.empic.notification', {
                    data: data
                });

                // update users
                // @ts-ignore
                that.empic.emit('users', null, function(data) {
                    that.states.empic.set('users', data);
                });

                // update rooms
                // @ts-ignore
                that.empic.emit('rooms', null, function (data) {
                    that.states.empic.set('rooms', data);
                });

                switch(data.type) {

                    case 'user_connected':
                        that.trigger('lvp.empic.notification.user.connected', {
                            data: data
                        });
                       // __.feedback('success', '<h4>'+data.name+' ist online</h4>', 3600);
                        console.log(data.name+' ist online!');
                        break;

                    case 'user_disconnected':
                        that.trigger('lvp.empic.notification.user.disconnected', {
                            data: data
                        });
                       // __.feedback('success', '<h4>'+data.name+' ist offline</h4>', 3600);
                        // @ts-ignore
                        that.empic.emit('users',{});
                        // @ts-ignore
                        that.empic.emit('rooms',{});
                        break;

                    case 'user_joined_room':
                      //  __.feedback('success', '<h4>'+data.name+' folgt jetzt: '+data.room+'</h4>', 3600);
                        that.trigger('lvp.empic.notification.user.room.join', {
                            data: data
                        });
                        break;

                    case 'user_leaved_room':
                        that.trigger('lvp.empic.notification.user.room.leave', {
                            data: data
                        });
                      //  __.feedback('success', '<h4>'+data.name+' folgt nicht mehr: '+data.room+'</h4>', 3600);
                        break;
                }
                if (Utils.isFunction(cb)) {
                    cb(true);
                }


            });

            // @ts-ignore
            that.empic.on('private', function(data, cb) {
                that.trigger('lvp.empic.debug', {
                    data: null,
                    empicType: "private"
                });
                if (Utils.isFunction(cb)) {
                    cb(true);
                }
            });

            // @ts-ignore
            that.empic.on('videoOutputStream', function(data, cb) {
               // s.view.videoStream(data);
            });

            // @ts-ignore
            that.empic.on('message', function(data, cb) {
                that.trigger('lvp.empic.debug', {
                    data: data,
                    empicType: "message"
                });

                that.trigger('lvp.empic.message', {
                    data: data
                });

                let messages = JSON.parse(window.localStorage.getItem('messages'));

                if (!messages) {
                    messages = that.states.empic.get('messages');
                }

                if (typeof data.toUserId !== "undefined") {

                    let strA = [data.toUserId, data.user.assetID];
                    let convId = strA.sort().join("_");

                    if (!Utils.isArray(messages.users[convId])) {
                        messages.users[convId] = [];
                    }

                    let userMessages = messages.users[convId];
                    userMessages.push(data);

                } else if (typeof data.toRoomId !== "undefined"){
                    if (!Utils.isArray(messages.rooms[data.toRoomId])) {
                        messages.rooms[data.toRoomId] = [];
                    }
                    let roomMessages = messages.rooms[data.toRoomId];
                    roomMessages.push(data);
                } else {
                    messages.all.push(data);
                }
/*
                var tpl = _.template(s.tplMessageDefault);
                var x = tpl({
                    data: data,
                });

                s.view.message(x);
*/
                //console.debug(window.localStorage.getItem('messages'));

                //__.feedback('well well-sm', x, 7200);

                if (Utils.isFunction(cb)) {
                    cb(true);
                }
                // console.debug(messages);

                window.localStorage.setItem('messages', JSON.stringify(messages));
            });

            that.empic.on('disconnect', () => {
                that.trigger('lvp.empic.debug', {
                    data: null,
                    empicType: "disconnect"
                });
                that.trigger('lvp.empic.disconnect', {});
                console.log('empic disconnect');
            });

        });


    }


}