
export class Helper {

    extend(obj, source) {
        for (let prop in source) {
            obj[prop] = source[prop];
        }
        return obj;
    }

    any(arr, fn) {
        for (let i = 0, l = arr.length; i < l; i++) {
            if (fn(arr[i])) {
                return true;
            }
        }
        return false;
    }

    on(obj, type, fn) {
        if (obj.attachEvent) {
            obj['e'+type+fn] = fn;
            obj[type+fn] = function(){ obj['e'+type+fn]( window.event ); };
            obj.attachEvent( 'on'+type, obj[type+fn] );
        } else {
            obj.addEventListener( type, fn, false );
        }
    }

    off(obj, type, fn) {
        if (obj.detachEvent) {
            obj.detachEvent('on'+type, obj[type+fn]);
            obj[type+fn] = null;
        } else {
            obj.removeEventListener(type, fn, false);
        }
    }

}


export class LocationBar {

    public handlers: object[] = [];

    public location:Location;

    public history:History;

    public routeStripper:RegExp = /^[#\/]|\s+$/g;

    public rootStripper:RegExp = /^\/+|\/+$/g;

    public trailingSlash:RegExp = /\/$/;

    public pathStripper:RegExp = /#.*$/;

    public started:boolean = false;

    public root;

    public _wantsHashChange;

    public _wantsPushState;

    public _hasPushState;

    public options;

    public fragment;

    constructor() {
        this.location = window.location;
        this.history = window.history;
    }

    route(route, callback) {
        this.handlers.unshift({route: route, callback: callback});
    }

    loadUrl(fragment?) {
        let helper = new Helper();
        fragment = this.fragment = this.getFragment(fragment);
        //console.log('lb: check route handlers');
        return helper.any(this.handlers, function(handler) {
            if (handler.route.test(fragment)) {
                //console.debug(handler.route);
                //console.log('lb: match handler: '+fragment);
                //console.debug(handler);
                handler.callback(fragment);
                return true;
            }
        });
    }

    checkUrl(event) {
        const that = this;
        //console.log('lb: checkUrl call');
        let current = that.getFragment(null);
        //console.log('lb: checkUrl: '+current);
        if (current === that.fragment) return false;
        that.loadUrl();
    }

    stop() {
        let helper = new Helper();
        const that = this;
        helper.off(window, 'popstate', (event:Event) => {
            that.checkUrl.call(that, event);
        });
        helper.off(window, 'hashchange', (event:Event) => {
            that.checkUrl.call(that, event);
        });
        this.started = false;
    }

    start(options) {
        // MODIFICATION OF ORIGINAL BACKBONE.HISTORY
        // if (History.started) throw new Error("LocationBar has already been started");
        // History.started = true;
        this.started = true;
        //console.log('lb: start');

        let helper = new Helper();

        // Figure out the initial configuration.
        // Is pushState desired ... is it available?
        this.options          = helper.extend({root: '/'}, options);
        this.location         = this.options.location || this.location;
        this.history          = this.options.history || this.history;
        this.root             = this.options.root;
        this._wantsHashChange = this.options.hashChange !== false;
        this._wantsPushState  = !!this.options.pushState;
        this._hasPushState    = !!(this.options.pushState && this.history && this.history.pushState);
        let fragment          = this.getFragment();

        // Normalize root to always include a leading and trailing slash.
        this.root = ('/' + this.root + '/').replace(this.rootStripper, '/');

        // Depending on whether we're using pushState or hashes, and whether
        // 'onhashchange' is supported, determine how we check the URL state.
        const that =this;
        if (this._hasPushState) {
            //console.log('lb: pushstate supported');
            helper.on(window, 'popstate', (event:Event) => {
                that.checkUrl.call(that, event);
            });
        } else {
            helper.on(window, 'hashchange', (event:Event) => {
                that.checkUrl.call(that, event);
            });
        }

        // Determine if we need to change the base url, for a pushState link
        // opened by a non-pushState browser.
        this.fragment = fragment;
        let loc = this.location;

        // Transition from hashChange to pushState or vice versa if both are
        // requested.
        if (this._wantsHashChange && this._wantsPushState) {

            // If we've started off with a route from a `pushState`-enabled
            // browser, but we're currently in a browser that doesn't support it...
            if (!this._hasPushState && !this.atRoot()) {
                this.fragment = this.getFragment(null, true);
                loc.replace(this.root + '#' + this.fragment);
                // Return immediately as browser will do redirect to new url
                return true;

                // Or if we've started out with a hash-based route, but we're currently
                // in a browser where it could be `pushState`-based instead...
            } else if (this._hasPushState && this.atRoot() && loc.hash) {
                this.fragment = this.getHash().replace(this.routeStripper, '');
                this.history.replaceState({}, document.title, this.root + this.fragment);
            }

        }

        if (!this.options.silent) return this.loadUrl();

    }



    atRoot() {
        return this.location.pathname.replace(/[^\/]$/, '$&/') === this.root;
    }

    getHash(window?) {
        let match = (window || this).location.href.match(/#(.*)$/);
        return match ? match[1] : '';
    }

    getFragment(fragment?, forcePushState?) {
        if (fragment == null) {
            if (this._hasPushState || !this._wantsHashChange || forcePushState) {
                fragment = decodeURI(this.location.pathname + this.location.search);
                let root = this.root.replace(this.trailingSlash, '');
                if (!fragment.indexOf(root)) fragment = fragment.slice(root.length);
            } else {
                fragment = this.getHash();
            }
            //console.log('lb: getFragment on null');
            //console.debug(fragment);
        }
        return fragment.replace(this.routeStripper, '');

    }

    navigate(fragment, options?) {
        //console.log('lb: navigate init');
        if (!this.started) return false;
        //console.log('lb: seems to be started');

        // ohne options - auto trigger enabled
        if (!options || options === true) options = {trigger: !!options};

        let url = this.root + (fragment = this.getFragment(fragment || ''));

        // Strip the hash for matching.
        fragment = fragment.replace(this.pathStripper, '');

        // wenn auf gleicher seite
        if (this.fragment === fragment) {
            //console.log('lb: same fragment - without exit');
            //return;
        }
        this.fragment = fragment;

        // Don't include a trailing slash on the root.
        if (fragment === '' && url !== '/') url = url.slice(0, -1);


        // If pushState is available, we use it to set the fragment as a real URL.
        if (this._hasPushState) {
            //console.log('navigate - hasPushState');
            this.history[options.replace ? 'replaceState' : 'pushState']({}, document.title, url);

            // If hash changes haven't been explicitly disabled, update the hash
            // fragment to store history.
        } else if (this._wantsHashChange) {
            //console.log('navigate - wantsHashChange');
            this._updateHash(this.location, fragment, options.replace);
            // If you've told us that you explicitly don't want fallback hashchange-
            // based history, then `navigate` becomes a page refresh.
        } else {
            //console.log('navigate - assign url');
            return this.location.assign(url);
        }
        console.log(fragment);
        if (options.trigger) return this.loadUrl(fragment);
    }

    hasPushState() {
        if (!this.started) {
            throw new Error("only available after LocationBar.start()");
        }
        return this._hasPushState;
    }

    _updateHash(location, fragment, replace) {
        if (replace) {
            let href = location.href.replace(/(javascript:|#).*$/, '');
            location.replace(href + '#' + fragment);
        } else {
            // Some browsers require that `hash` contains a leading #.
            location.hash = '#' + fragment;
        }
    }

    onChange(callback) {
        this.route(/^(.*?)$/, callback);
    }

    update(url) {
        //console.log('lb: update url: '+url+' - with trigger')
        this.navigate(url, {trigger:true});
    }

}