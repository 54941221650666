import {View} from '../../Framework/View';
import Tpl2 from "bundle-text:../templates/Test.html";
import {Model} from "../../Framework/Model";
import {TestView2} from './TestView2';

export class TestView extends View {

    constructor() {
        super({
            auto: true,
            template: Tpl2,
            model: new Model({
                "name": "Christopher"
            })
        });

        let s=this;

        this.viewProperties.events = {

            "click button.test-event": function (event:Event) {
                console.log('test-event22');
                console.debug(event);
                console.debug(this);

                let v2=new TestView2();
                s.el.append(v2.el);

            }
        }

    }



}
