import * as GUI from "babylonjs-gui"
import * as BABYLON from "babylonjs";


export class ChatBubble {

    delay:number = 20000;

    constructor(text:string, targetMesh) {

       this.test2(text, targetMesh);


    }

    test1(text:string, targetMesh) {

        let len:number = String(text).length;
        console.log(text);
        console.debug(len);

        /**
         let at = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
         let at = GUI.AdvancedDynamicTexture.CreateForMesh(targetMesh);

         let textBlock = new GUI.TextBlock();
         textBlock.text = text;
         at.addControl(textBlock);
         textBlock.linkWithMesh(targetMesh);


         setInterval(()=> {
            at.removeControl(textBlock);
        }, this.delay)
         */


            // GUI
        var advancedTexture = GUI.AdvancedDynamicTexture.CreateForMesh(targetMesh, len*50, len/100*25, true);
        console.debug(advancedTexture);
        advancedTexture

        var rect1 = new GUI.Rectangle();
        rect1.width = 0.2;
        rect1.height = "10px";
        rect1.cornerRadius = 2;
        //rect1.color = new Color4();
        rect1.color = ""
        rect1.thickness = 1;
        rect1.background = "white";
        rect1.alpha = 0.5;
        advancedTexture.addControl(rect1);
        rect1.linkWithMesh(targetMesh);
        //rect1.linkOffsetYInPixels = -200;
        //rect1.transformCenterY = 350;

        var label = new GUI.TextBlock();
        label.text = text;
        label.alpha = 1;
        rect1.addControl(label);

        setTimeout(()=> {
            advancedTexture.removeControl(rect1);
            rect1.dispose();
            advancedTexture.dispose();
            label.dispose();
        },10000);

    }

    test2(text:string, targetMesh) {

        let len:number = String(text).length;
        console.log(text);
        console.debug(len);

        /**
         let at = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
         let at = GUI.AdvancedDynamicTexture.CreateForMesh(targetMesh);

         let textBlock = new GUI.TextBlock();
         textBlock.text = text;
         at.addControl(textBlock);
         textBlock.linkWithMesh(targetMesh);


         setInterval(()=> {
            at.removeControl(textBlock);
        }, this.delay)
         */


            // GUI
        var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");

        advancedTexture.idealWidth = len*50;

        var rect1 = new GUI.Rectangle();
        rect1.width = 0.2;
        rect1.height = "10px";
        rect1.cornerRadius = 2;
        //rect1.color = new Color4();
        rect1.color = ""
        rect1.thickness = 1;
        rect1.background = "white";
        rect1.alpha = 0.5;
        advancedTexture.addControl(rect1);
        rect1.linkWithMesh(targetMesh);
        //rect1.linkOffsetYInPixels = -200;
        //rect1.transformCenterY = 350;

        var label = new GUI.TextBlock();
        label.text = text;
        label.alpha = 1;
        rect1.addControl(label);

        setTimeout(()=> {
            advancedTexture.removeControl(rect1);
            rect1.dispose();
            advancedTexture.dispose();
            label.dispose();
        },10000);




        /*
                var target = new GUI.Ellipse();
                target.width = "0px";
                target.height = "0px";
                target.color = "Orange";
                target.thickness = 4;
                target.background = "green";
                advancedTexture.addControl(target);
                target.linkWithMesh(targetMesh);

                var line = new GUI.Line();
                line.lineWidth = 0;
                line.color = "Orange";
                line.y2 = 20;
                line.linkOffsetY = -200;
                advancedTexture.addControl(line);
                line.linkWithMesh(targetMesh);
                line.connectedControl = rect1;
        */
    }

    holoButton(text:string, targetMesh) {
        const scene = window.Application.viewer.Scene;

        const manager = new GUI.GUI3DManager(scene);
        //const anchor = new BABYLON.AbstractMesh("anchor", scene);

        // Let's add a button
        var button = new GUI.HolographicButton("down");
        manager.addControl(button);
        button.linkToTransformNode(targetMesh);
        //button.position.z = -1.5;
        button.position.y = 1.5;


        button.text = text;

        setTimeout(()=> {
            manager.removeControl(button);
            manager.dispose();
            button.dispose();

        }, 10000);
    }

    test3(text:string, targetMesh) {

        const scene = window.Application.viewer.Scene;

        //Set width an height for plane
        var planeWidth = 10;
        var planeHeight = 10;

        //Create plane
        var writingPlane = BABYLON.MeshBuilder.CreatePlane("plane", {width:planeWidth, height:planeHeight}, scene);

        //Set text
        var text = "0";

        //Create dynamic texture
        var size = 256;
        var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", size, scene);
        var font_size = 96;
        var font_type = "Arial";
        var font = font_size + "px " + font_type;

        //Draw text
        dynamicTexture.drawText(text, null, null, font, "#000000", "transparent", true);

        //create material
        var mat = new BABYLON.StandardMaterial("mat", scene);
        mat.diffuseTexture = dynamicTexture;
        mat.diffuseTexture.hasAlpha = true;

        //apply material
        writingPlane.material = mat;

        var i = 1;
        var counter = 1;
        var ctx = dynamicTexture.getContext();
        scene.registerBeforeRender(function() {
            if(i === 0) {
                ctx.clearRect(0, 0, size, size)
                dynamicTexture.drawText(String(counter), null, null, font, "#000000", "transparent", true);
                counter++;
            }
            i += 1;
            i %= 100;
        })

    }

}