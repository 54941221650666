import * as SDK from "../../../Application/SDK/ViewSDK";
import FrontPageTemplate from "bundle-text:./FrontPage.html";

export class FrontPage extends SDK.View {

    session: SDK.SessionHandler;

    connector: SDK.LordfoxConnector;

    events: SDK.EventTable = {

    }

    constructor() {
        super();
        this.session = window.Application.connector.Session;
        this.connector = window.Application.connector;
        this.engine.add('lvp.app.layout', SDK.AppLayoutTemplate);
        this.engine.add('FrontPage.index', FrontPageTemplate);
    }

    isUserLoggedIn() {
        return this.connector.Session.isUserLoggedIn();
    }

    render() {
        let vars:any = {
            app: {},
            view: this
        };

        vars.app = {
            title: "Willkommen",
            subtext: "auf LORDFOX.DE"
        };

        this.renderEl('FrontPage.index', vars);
    }

}