import {LvpAddress} from './Entity/LvpAddress';
import {LvpBrand} from './Entity/LvpBrand';

let entities: any = {
    'LvpAddress': LvpAddress,
    'LvpBrand': LvpBrand
};

export function getEntityClass(entityName: string)
{
    return entities[entityName];
}

export function getEntities() {
    return entities;
}