
export class DomHelper {

    constructor() {
    }

    static remove(elements) {
        elements.forEach(node => {
            node.remove();
        });
    }

    static empty(elements) {
        //@todo: elements ist ein array !
        if (elements)
        while (elements.lastChild) {
            elements.removeChild(elements.lastChild);
        }
    }

    static find(parent, selector) {
        // Find all the outer matched elements
        return parent.querySelectorAll(selector);
    }

    static filter(outers, selector) {
        // Final found elements
        var found_elements = [];

        for(var i=0; i<outers.length; i++) {
            var elements_in_outer = outers[i].querySelectorAll(selector);

            // document.querySelectorAll() returns an "array-like" collection of elements
            // convert this "array-like" collection to an array
            elements_in_outer = Array.prototype.slice.call(elements_in_outer);

            found_elements = found_elements.concat(elements_in_outer);
        }

        return found_elements;
    }

    static get(selector) {
        const els = document.querySelectorAll(selector);

        return {
            selector,
            length: els.length,
            get: i => els[i],
            [Symbol.iterator]: () => els[Symbol.iterator](),
            filter: f => DomHelper.get(selector + ", " + f)
        };
    }

    static html(parentElement, selector, html) {
        DomHelper.empty(parentElement.querySelector(selector));
        parentElement.querySelector(selector).append(html);
    }

}