import {Vector3, Ray, Axis, AxesViewer} from 'babylonjs';

export function pointIsInsideMesh(point, mesh) {

    var boundInfo = mesh.getBoundingInfo();
    var max = boundInfo.maximum;
    var min = boundInfo.minimum;
    var diameter = 2 * boundInfo.boundingSphere.radius;
    if(point.x < min.x || point.x > max.x) {
        return false;
    }
    if(point.y < min.y || point.y > max.y) {
        return false;
    }
    if(point.z < min.z || point.z > max.z) {
        return false;
    }

    var pointFound = false;
    var d = 0;
    var hitCount = 0;
    var gap = 0;
    var distance = 0;
    var ray = new Ray(Vector3.Zero(), Axis.X, diameter);
    var pickInfo;
    var direction = point.clone();
    var refPoint = point.clone();


    hitCount = 0;
    ray.origin = refPoint;
    ray.direction = direction;
    ray.length = diameter;
    pickInfo = ray.intersectsMesh(mesh);
    while (pickInfo.hit) {
        hitCount++;
        pickInfo.pickedPoint.addToRef(direction.scale(0.00000001), refPoint);
        ray.origin  = refPoint;
        pickInfo = ray.intersectsMesh(mesh);
    }
    if((hitCount % 2) === 1) {
        pointFound = true;
    }

    return pointFound;

}

export function displayAxis(scene, mesh) {

    /**
     * @see https://doc.babylonjs.com/toolsAndResources/utilities/World_Axes
     */
    const axes = new AxesViewer(scene, 2);
    axes.xAxis.parent = mesh;
    axes.yAxis.parent = mesh;
    axes.zAxis.parent = mesh;
    return axes;
}

