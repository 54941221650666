
import {RestConnector} from './RestConnector'
import {SessionHandler} from './SessionHandler'
import {getCookie} from './Cookie'

export class LordfoxConnector {

    endpoint:string = "https://www.connect.lordfox.de/api";

    apiDefaultCredentials:object = {
        "X-LordfoxAPI-Key": "",
        "X-LordfoxAPI-Host": "",
        "X-LordfoxAPI-AuthToken": ""
    }

    apiCredentials:object = {
        "X-LordfoxAPI-Key": "",
        "X-LordfoxAPI-Host": "",
        "X-LordfoxAPI-AuthToken": ""
    }

    headers: {}

    rest: RestConnector;

    Session: SessionHandler;

    constructor() {
        this.rest = new RestConnector();
        this.Session = new SessionHandler();

        this.apiDefaultCredentials["X-LordfoxAPI-Host"] = ""+location.protocol + '//' + location.host+"";
        this.apiDefaultCredentials["X-LordfoxAPI-Key"] = "02193d90-c8f7-4478-9b45-44b878ed0b9b";
        this.apiDefaultCredentials["X-LordfoxAPI-AuthToken"] = "";
    }

    request( path, params = {}, method = 'GET' ) {

        let url = this.endpoint+''+path;

        // this.headers = this.apiDefaultCredentials;

        var headers = new Headers();
        //headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');
        
        if (method === 'PUT' || method === 'POST')
            headers.append('content-type', 'application/json');

        let options = {
            method: method,
            //credentials: 'same-origin'
            headers: headers,
            body: null
        };


        if (getCookie('LVP-userToken')) {
            if (typeof options.headers === 'undefined') {
                //options.headers = {};
            }
            //options.withCredentials =  true;
            //options.credentials = 'include';

            //options.headers.append('Authorization', "Bearer "+getCookie('LVP-userToken'));
            options.headers.append('X-LordfoxAPI-AuthToken', this.Session.getUserToken());
            options.headers.append('X-LordfoxAPI-Key', "02193d90-c8f7-4478-9b45-44b878ed0b9b");
            options.headers.append('X-LordfoxAPI-Host', ""+location.protocol + '//' + location.host+"");
        }

        if ( 'GET' === method ) {
            url += '?' + ( new URLSearchParams( params ) ).toString();
        } else {
            options.body = JSON.stringify( params );
        }

        return fetch( url, options ).then( response => response.json() );
    }

    setApiCredentials(credentials) {
        this.apiCredentials = credentials;
    }

    getApiCredentials() {
        return this.apiCredentials;
    }

    setEndpoint(endpoint) {
        this.endpoint = endpoint;
    }

    getEndpoint() {
        return this.endpoint;
    }

    fileUpload(params = {}, blob: Blob = null, progressBar = null)
    {
        if (blob !== null)
        {

            const req = new XMLHttpRequest();
            req.open("POST", this.endpoint+"/FileTransfer/Upload/", true);
            req.setRequestHeader('X-LordfoxAPI-Host', this.apiDefaultCredentials["X-LordfoxAPI-Host"]);
            req.setRequestHeader('X-LordfoxAPI-Key', this.apiDefaultCredentials["X-LordfoxAPI-Key"]);
            req.setRequestHeader('X-LordfoxAPI-AuthToken', this.Session.getUserToken());
            req.onload = (event) => {
                console.log(event); // Uploaded
            };
            if (progressBar!=null) {
                req.upload.onprogress = function(e) {
                    if (e.lengthComputable) {
                        progressBar.value = (e.loaded / e.total) * 100;
                        progressBar.textContent = progressBar.value.toString(); // Fallback for unsupported browsers.
                    }
                };
            }
            req.send(blob);

        }
    }

}